<template>
  <div>
    <Modal :show-modal="showModal"
           title=""
           :label-button="'Imprimir PDF'"
           @update:showModal="$emit('update:showModal', $event)"
           @update="convertToPdf">
      <div id="modal-content" class="container-fluid">
        <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
            <div class="col-auto">
              <img :src="logo" alt="Imagem carregada" style="width: 100px">
            </div>
          </div>

          <div class="row justify-content-center align-items-center mt-5">
            <div class="col-auto">
              <h4>{{ `${pedidoDto.id}` }}</h4>
            </div>
          </div>

          <div class="row col-12">
            <div class="col text-start">
              <h5>Criado: {{ longToDateFormatted(pedidoDto.dataCriacao) }}</h5>
            </div>
            <div class="col text-end">
              <h5>Finalizado: {{ longToDateFormatted(pedidoDto.dataFinalizado) }}</h5>
            </div>
          </div>


          <div class="form-group">
            <div class="card">
              <div class="card-body">
                <div class="form-group row col-12">
                  <div class="col-4">
                    <label class="text-uppercase">Vendedor:</label>
                    <span class="form-control text-uppercase badge rounded-pill bg-info text-dark">
                    {{ pedidoDto.vendedor.name }}
                  </span>
                  </div>
                  <div class="col-4">
                    <label class="text-uppercase">Status:</label>
                    <span class="form-control text-uppercase rounded-pill"
                          :class="pedidoDto.status === 'FINALIZADO' ? 'badge badge-success' : 'badge badge-primary'">
                    {{ pedidoDto.status }}
                  </span>
                  </div>
                  <div class="col-4">
                    <label class="text-uppercase">Origem da venda:</label>
                    <select v-model="pedidoDto.origem" disabled class="form-control">
                      <option value="INDICACAO">Indicação</option>
                      <option value="ANUNCIO_WHATSAPP">Anuncios / WhatsApp</option>
                      <option value="ANUNCIO_INSTAGRAM">Anuncios / Instagram</option>
                      <option value="ORGANICO">Orgânico</option>
                      <option value="GOOGLE">Google</option>
                      <option value="YOUTUBE">Youtube</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4 container-fluid">
          <div class="row col-12">
            <div class="col-4 text-start">
              <h5>Valor: {{ formattedAmount(pedidoDto.financeiro.valorTotal) }}</h5>
              <h6>Frete: {{ formattedAmount(pedidoDto.frete) }}</h6>
              <h6>Desconto: {{ formattedAmount(pedidoDto.desconto) }}</h6>
            </div>
            <div v-if="pedidoDto.cliente" class="col-8 text-end">
              <h6>{{ pedidoDto.cliente.nome }} {{ pedidoDto.cliente.sobrenome }}</h6>
              <h6>{{ formatCPF(pedidoDto.cliente.cpf) }}</h6>
              <h6>{{ pedidoDto.cliente.endereco }} {{ pedidoDto.cliente.numero }}</h6>
              <h6>{{ pedidoDto.cliente.cellphone }}</h6>
            </div>
            <div v-else class="col-8 text-end">
              <h6>Sem cliente</h6>
            </div>
          </div>
          <div class="row mt-5">
            <label>Produtos:</label>
            <ul class="list-group">
              <li v-for="(produto, index) in pedidoDto.itemPedido" :key="index"
                  class="list-group-item">
                <div class="row">
                  <div class="col-12">
                    {{ getContentProduto(produto) }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="row mt-3">
            <label>Pagamentos:</label>
            <ul class="list-group">
              <li v-for="(transacao, index) in pedidoDto.financeiro.transacoes" :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center">
                {{ formattedAmount(transacao.valor) }} - {{ transacao.metodoPagamento }} -
                {{ formatDateP(transacao.dataPagamento) }}
              </li>
            </ul>
          </div>
          <div class="row mt-3">
            <label>Troca:</label>
            <ul class="list-group">
              <li
                  class="list-group-item">
                <div v-if="pedidoDto.itemTroca" class="row">
                  <div v-for="(item, index) in pedidoDto.itemTroca" :key="index">
                    <div class="col-12">
                      {{ getContentTroca(item) }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  Sem troca
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import Modal from "@/views/Modal.vue";
import {DateTime} from "luxon";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import logo from '@/assets/logo.png';
import {currencyMixin} from "@/mixins/currencyMixin";


export default {
  name: "ModalOverviewProduto",
  components: {Modal},
  props: {
    showModal: Boolean,
    pedido: [Object, () => {
    }]
  },
  emits: ['update:showModal'],
  data() {
    return {
      pedidoDto: {origem: null},
      logo: logo
    }
  },
  created() {
    this.pedidoDto = this.pedido
  },
  methods: {
    convertToPdf() {
      const element = document.getElementById('modal-content');
      html2canvas(element, {scale: 2}).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const width = 190; // Largura dentro do PDF
        const height = canvas.height * width / canvas.width; // Mantém a proporção
        pdf.addImage(imgData, 'PNG', 10, 10, width, height);
        pdf.save(`${this.pedidoDto.id}.pdf`);
      });
    },
    formattedAmount(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    getContentTroca(item) {
      let contentParts = [];

      if (item.produto.modelo) contentParts.push(item.produto.modelo);
      if (item.produto.cor) contentParts.push(item.produto.cor);
      if (item.produto.armazenamento) contentParts.push(`${item.produto.armazenamento}GB`);

      contentParts.push(", Custo: " + this.formattedAmount(item.precoCusto || 0));

      return contentParts.join(' ').trim();
    },
    formatCPF(cpf) {
      if (cpf == null) {
        return null;
      }
      const cpfArray = cpf.split('');
      for (let i = 2; i < cpfArray.length - 3; i++) {
        cpfArray[i] = '*';
      }
      return cpfArray.slice(0, 3).join('') + '.' + cpfArray.slice(3, 6).join('') + '.' + cpfArray.slice(6, 9).join('') + '-' + cpfArray.slice(9).join('');
    },
    getContentProduto(item) {
      let contentParts = [];

      if (item.produto.tipoProduto === 'ACESSORIO') {
        contentParts.push(item.produto.modelo || '');
        contentParts.push(`R$ ${item.produto.precoVenda}`);
      } else {
        if (item.produto.modelo) contentParts.push(item.produto.modelo);
        if (item.produto.cor) contentParts.push(item.produto.cor);
        if (item.produto.armazenamento) contentParts.push(`${item.produto.armazenamento}GB`);
        if (item.produto.imei) contentParts.push(`IMEI: ${item.produto.imei}`);

        contentParts.push(this.formattedAmount(item.precoVenda || 0));

        if (item.produto.garantia) contentParts.push(`garantia: ${item.produto.garantia}`);
      }

      return contentParts.join(', ').trim();
    },
    formatDateP(longValue) {
      if (longValue == null) {
        return null;
      }
      const dt = DateTime.fromMillis(longValue, {zone: 'America/Sao_Paulo'});
      return dt.toFormat('dd/MM/yyyy HH:mm');
    },
    longToDateFormatted(timestamp) {
      if (!timestamp) {
        return '-'
      }
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // adicione +1 porque getMonth() começa de 0
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
  }
}
</script>

<style scoped>
.col-4 > label, .col-4 > span, .col-4 > select {
  display: block;
  width: 100%;
}
</style>