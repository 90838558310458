import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: true,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    role: localStorage.getItem('role') || '',
    funcionarioId: localStorage.getItem('funcionarioId') || '',
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    iphones: [
      'iPhone 7',
      'iPhone 7 Plus',
      'iPhone 8',
      'iPhone 8 Plus',
      'iPhone X',
      'iPhone XR',
      'iPhone XS',
      'iPhone XS Max',
      'iPhone 11',
      'iPhone 11 Pro',
      'iPhone 11 Pro Max',
      'iPhone SE (2ª geração)',
      'iPhone 12 Mini',
      'iPhone 12',
      'iPhone 12 Pro',
      'iPhone 12 Pro Max',
      'iPhone 13',
      'iPhone 13 Mini',
      'iPhone 13 Pro',
      'iPhone 13 Pro Max',
      'iPhone 14',
      'iPhone 14 Pro',
      'iPhone 14 Plus',
      'iPhone 14 Pro Max',
      'iPhone 15',
      'iPhone 15 Pro',
      'iPhone 15 Plus',
      'iPhone 15 Pro Max',
      'MacBook 2006',
      'MacBook 2007',
      'MacBook 2008',
      'MacBook 2009',
      'MacBook 2010',
      'MacBook 2015',
      'MacBook 2016',
      'MacBook 2017',
      'MacBook 2018',
      'MacBook 2019',
      'MacBook Air',
      'MacBook Air M1 ',
      'MacBook Air M2 ',
      'MacBook Air M3 ',
      'MacBook Pro 13 ',
      'MacBook Pro 15 ',
      'MacBook Pro 16 ',
      'MacBook Pro 14" M1 Pro/Max (2021) ',
      'MacBook Pro 16" M1 Pro/Max (2021) ',
      'MacBook Pro 16" M1 Pro/Max (2021) ',
      'iPad Pro 12.9 polegadas (1ª geração)',
      'iPad Pro 9.7 polegadas',
      'iPad Pro 12.9 polegadas (2ª geração)',
      'iPad Pro 10.5 polegadas',
      'iPad Pro 11 polegadas (1ª geração)',
      'iPad Pro 12.9 polegadas (3ª geração)',
      'iPad Pro 11 polegadas (2ª geração)',
      'iPad Pro 12.9 polegadas (4ª geração)',
      'iPad Pro 11 polegadas (3ª geração)',
      'iPad Pro 12.9 polegadas (5ª geração)',
      'iPad Pro 11 polegadas (4ª geração)',
      'iPad Pro 12.9 polegadas (6ª geração)',
      'iPad (7ª geração)',
      'iPad (8ª geração)',
      'iPad (9ª geração)',
      'iPad (10ª geração)',
      'iPad Mini (1ª geração)',
      'iPad Mini 2',
      'iPad Mini 3',
      'iPad Mini 4',
      'iPad Mini (5ª geração)',
      'iPad Mini (6ª geração)',
      'Apple Watch Series 1',
      'Apple Watch Series 2',
      'Apple Watch Series 3',
      'Apple Watch Series 4',
      'Apple Watch Series 5',
      'Apple Watch SE (1ª geração)',
      'Apple Watch Series 6',
      'Apple Watch Series 7',
      'Apple Watch SE (2ª geração)',
      'Apple Watch Series 8',
      'Apple Watch Series 9',
      'Apple Watch Ultra',
      'Apple Watch Ultra 2'
    ],
    armazenamentos: [
      '32GB',
      '64GB',
      '128GB',
      '256GB',
      '512GB',
      '1TB'],
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    updateStoredValue(state, newValue) {
      state.role = newValue;
      localStorage.setItem('role', newValue);
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {
    iphones: (state) => state.iphones,
    armazenamentos: (state) => state.armazenamentos,
  }
});
