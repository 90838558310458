<template>
  <Modal :show-modal="showModal"
         title="Confirme os dados"
         :label-button="'Enviar PDF'"
         @update:showModal="$emit('update:showModal', $event)"
         @update="confirm">
    <div class="row justify-content-center col-12">

      <div class="col-6">
        <label class="form-label">Confirme o número de celular</label>
        <div class="input-group ">
          <span class="input-group-text">+55</span>
          <input v-model="cellphoneFinal" class="form-control" type="text" placeholder="(51) 99999-9999" @input="format">
        </div>
      </div>

      <div class="form-group col-6">
        <label class="form-label">Confirme o texto enviado junto com o arquivo</label>
        <input v-model="description" class="form-control" type="text">
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/views/Modal.vue";
import {currencyMixin} from "@/mixins/currencyMixin";

export default {
  name: "CelularModal",
  components: {Modal},
  props: {
    showModal: Boolean,
    title: String,
    cellphone: String,
    clientName: String
  },
  emits: ['update', 'update:showModal'],
  data() {
    return {
      cellphoneFinal: null,
      description: null
    }
  },
  created() {
    this.cellphoneFinal = this.cellphone;

    if (this.clientName) {
      this.description = `Olá ${this.clientName}, segue seu termo de garantia!`
    }
  },
  methods: {
    confirm() {
      const dto = {
        cellphone: this.cellphoneFinal,
        description: this.description
      }
      this.$emit('update', dto);
    },
    format() {
      this.cellphoneFinal = currencyMixin.methods.formatarCelular(this.cellphoneFinal)
    },
  }
}
</script>