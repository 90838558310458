<template>
  <div class="container mt-5">
    <form>
      <div class="row mt-3">
        <div class="col-md-6 form-group custom-select-container">
          <label for="inputNome"><i class="bi bi-person-fill me-2"></i>Nome ou Razão Social:</label>
          <input
              v-model="cliente.nome"
              class="multisteps-form__input form-control"
              type="text"
          />
        </div>
        <div class="col-md-6 form-group">
          <label for="inputSobrenome">Sobrenome ou Fantasia:</label>
          <input id="inputSobrenome" v-model="cliente.sobrenome" class="multisteps-form__input form-control"
                 type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 form-group">
          <label for="inputCPF"><i class="bi bi-card-text me-2"></i>CPF ou CNPJ:</label>
          <input id="inputCPF" v-model="cliente.cpf" class="form-control" type="text">
        </div>
        <div class="col-md-6 form-group">
          <label for="inputRG">RG ou IE:</label>
          <input id="inputRG" v-model="cliente.rg" class="form-control" type="text"/>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label for="inputCEP"><i class="bi bi-house-door-fill me-2"></i>CEP:</label>
          <input id="inputCEP" v-model="cliente.cep" class="form-control" placeholder="Digite o CEP"
                 @input="fetchAddress"/>
        </div>
        <div class="col-md-9 form-group">
          <label for="inputEndereco">Endereço:</label>
          <input id="inputEndereco" v-model="cliente.endereco" class="form-control" type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group">
          <label><i class="bi bi-house-door-fill me-2"></i>Bairro:</label>
          <input v-model="cliente.bairro" type="text" class="form-control" placeholder="Bairro"/>
        </div>
        <div class="col-md-4 form-group">
          <label for="inputEndereco">Cidade:</label>
          <input id="inputEndereco" v-model="cliente.cidade" class="form-control" type="text"/>
        </div>
        <div class="col-md-3 form-group">
          <label>UF:</label>
          <input v-model="cliente.uf" class="form-control" type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 form-group">
          <label for="inputNumero">Numero:</label>
          <input id="inputNumero" v-model="cliente.numero" class="form-control" type="text"/>
        </div>
        <div class="col-md-6 form-group">
          <label for="inputCelular"><i class="bi bi-telephone-fill me-2"></i>Numero do celular:</label>
          <input id="inputCelular" v-model="cliente.cellphone" class="form-control" type="text"
                 @input="formatarCelular"/>
        </div>
      </div>
      <div class="form-group">
        <label for="inputEmail"><i class="bi bi-envelope-fill me-2"></i>EMAIL:</label>
        <input id="inputEmail" class="form-control" type="email" v-model="cliente.email" @input="sugerirDominio"
               list="emailSuggestions" placeholder="seuemail@example.com"/>
        <datalist id="emailSuggestions">
          <option v-for="(dominio, index) in dominiosPopulares" :key="index"
                  :value="cliente.email + dominio"></option>
        </datalist>
      </div>

      <div class="form-group">
        <div class="col-md-12 form-group">
          <quill-editor
              :content="observacoes"
              @change="handleContentChange">
          </quill-editor>
        </div>
      </div>

      <button type="submit" class="btn btn-primary" @click.prevent="create">Salvar</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import QuillEditor from "vue-quill-editor/src/editor.vue";
import {toast} from "vue3-toastify";


export default {
  name: "ClienteId",
  components: {QuillEditor},
  data() {
    return {
      cliente: {
        nome: null,
        sobrenome: null,
        cep: null,
        endereco: null,
        cidade: null,
        bairro: null,
        numero: null,
        cellphone: null,
        rg: null,
        cpf: null,
        email: null,
        uf: null
      },
      dominiosPopulares: ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'],
      cpfValid: true,
      observacoes: null,
      categoria: null,
      id: null
    }
  },
  computed: {
    check() {
      return !Object.values(this.cliente).some(value => value === null || !value);
    },
  },
  mounted() {
    this.id = this.$route.params._id;
    this.categoria = this.$route.params._categoria;
    this.getCliente()
  },
  methods: {
    checkCPF() {
      this.cpfValid = this.validateCPF(this.cliente.cpf);
    },
    async getCliente() {
      const response = await axios.get(`api/cliente/${this.id}`);
      this.cliente = response.data
    },
    async create() {
      try {
        this.cliente.observacoes = this.observacoes
        await axios.post(`api/cliente`, this.cliente);
        await this.$router.push(`/cadastro/${this.categoria}/list`);
        toast(`${this.categoria} atualizado ou criado`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro a salvar ${this.categoria}: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    handleContentChange(editor) {
      this.observacoes = editor.html;
    },
    sugerirDominio() {
      // Sugere domínios apenas se "@" foi digitado e não há outro ponto após o "@"
      if (this.cliente.email.includes('@') && !this.cliente.email.split('@')[1].includes('.')) {
        this.dominiosPopulares = ['gmail.com', 'outlook.com', 'yahoo.com.br'];
      } else {
        this.dominiosPopulares = [];
      }
    },
    validateCPF(cpf) {
      cpf = cpf.replace(/[^\d]+/g, '');
      if (!cpf) return false;

      if (cpf.length !== 11 ||
          cpf === "00000000000" ||
          cpf === "11111111111" ||
          cpf === "22222222222" ||
          cpf === "33333333333" ||
          cpf === "44444444444" ||
          cpf === "55555555555" ||
          cpf === "66666666666" ||
          cpf === "77777777777" ||
          cpf === "88888888888" ||
          cpf === "99999999999")
        return false;

      let add = 0;
      for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      if (rev !== parseInt(cpf.charAt(9)))
        return false;

      add = 0;
      for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      return rev === parseInt(cpf.charAt(10));
    },
    formatarCelular() {
      this.cliente.cellphone = this.cliente.cellphone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      this.cliente.cellphone = this.cliente.cellphone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    },
    async fetchAddress() {
      if (this.cliente.cep.length >= 8) {
        try {
          this.cliente.cep = this.cliente.cep.replace(/\D/g, '');
          const response = await fetch(`https://viacep.com.br/ws/${this.cliente.cep}/json/`);
          const data = await response.json();
          this.cliente.endereco = data.logradouro
          this.cliente.bairro = data.bairro
          this.cliente.cidade = data.localidade
          this.cliente.uf = data.uf
        } catch (error) {
          console.error("Erro ao buscar o endereço:", error);
        }
      }
    },
    formattedAmount(valor) {
      if (valor == null) {
        return null
      }
      let currencyValue = parseFloat(valor);
      return currencyValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    },
  },
};
</script>

<style>
.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-select li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-select li:hover {
  background-color: #f5f5f5;
}
</style>
