<template>
  <Modal :show-modal="showModal" title="Crie uma conta"
         :label-button="'Salvar'"
         @update:showModal="updateModal($event)"
         @update="save()">
    <div class="container">
      <form>
        <div class="col m-2">
          <label>Recebedor:</label>
          <Multiselect v-model="recebedor"
                       :options="recebedores"
                       label="name"
                       :multiple="false">
          </Multiselect>
        </div>

        <div class="form-group row d-flex justify-content-between">
          <div class="col-md-6">
            <div class="mb-3">
              <label>Data de vencimento</label>
              <input v-model="dataVencimento" type="date" class="form-control">
            </div>
          </div>

          <div class="col form-check form-switch d-flex align-items-end">
            <div class="mb-3">
              <label class="form-check-label" for="flexSwitchCheckDefault">Notificar vencimento
                <i v-tippy="'Você será notificado 1 dia antes do vencimento dessa fatura, no seu WhatsApp'"
                   class="fa fa-info-circle">
                </i>
              </label>
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <button class="btn btn-sm btn-outline-info" @click.prevent="toggleRecorrencia">
              É recorrente ?
              <i v-if="mostrarRecorrencia" class="fa fa-chevron-up" aria-hidden="true"></i>
              <i v-else class="fa fa-chevron-down" aria-hidden="true"></i>
            </button>
            <div v-if="mostrarRecorrencia" class="form-group row">
              <div class="col-md-6">
                <div class="mb-3 form-floating">
                  <input id="quantidadeRecorrencia" v-model="quantidadeRecorrencia" type="number" class="form-control"
                         placeholder="Quantidade de parcelas">
                  <label for="quantidadeRecorrencia">Quantidade de parcelas</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3 form-floating">
                  <input id="vencimentoDasRecorrencias" v-model="vencimentoDasRecorrencias" type="number"
                         class="form-control" placeholder="Dia do vencimento">
                  <label for="vencimentoDasRecorrencias">Dia do vencimento</label>
                  <p class="text-muted text-sm">A próxima cobrança sera criada neste dia, no mês que vem</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label>Valor: *</label>
            <CurrencyInput
                v-model="valor"
                class="form-control"
                :class="!valor ? 'is-invalid' : ''"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
          <div class="col-md-4">
            <label>Desconto:</label>
            <CurrencyInput
                v-model="desconto"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
          <div class="col-md-4">
            <label>Juros:</label>
            <CurrencyInput
                v-model="juros"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-12 mb-3">
            <label class="form-label">Tipo de despesa *</label>
            <Multiselect v-model="tipoDespesaId"
                         :options="tipoDespesa"
                         label="label"
                         track-by="id"
                         deselect-label="Você precisa selecionar 1 valor"
                         :allow-empty="false"
                         :multiple="false">
            </Multiselect>
          </div>
        </div>

        <div class="mb-3">
          <label for="observacao" class="form-label">Observação</label>
          <textarea id="observacao" v-model="observacao" class="form-control"
                    rows="3"></textarea>
        </div>
      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

import axios from "axios";
import CurrencyInput from "@/CurrencyInput.vue";
import {toast} from "vue3-toastify";
import Multiselect from 'vue-multiselect'
import {currencyMixin} from "@/mixins/currencyMixin";

export default {
  name: "ModalContasAPagar",
  components: {CurrencyInput, Modal, Multiselect},
  props: {
    showModal: Boolean,
    title: String,
  },
  emits: ['update:showModal', 'update'],
  data() {
    return {
      observacao: null,
      tipoDespesa: {},
      juros: null,
      valor: null,
      desconto: null,
      dataVencimento: null,
      recebedor: null,
      recebedores: [],

      quantidadeRecorrencia: null,
      vencimentoDasRecorrencias: null,
      disabled: false,
      value: '',
      tipoDespesaId: null,
      mostrarRecorrencia: false
    }
  },
  computed: {
    isDisabled() {
      return !this.tipoDespesaId || !this.valor
    }
  },
  created() {
    this.get()
    this.getRecebedores()
  },
  methods: {
    toggleRecorrencia() {
      this.mostrarRecorrencia = !this.mostrarRecorrencia;
    },
    updateModal(event) {
      this.$emit('update:showModal', event)
    },
    async get() {
      const response = await axios.get(`api/contasAPagar/despesa`);
      this.tipoDespesa = response.data
    },
    async getRecebedores() {
      const response = await axios.get(`api/contasAPagar/recebedores`);
      this.recebedores = response.data
    },
    async save() {
      if (this.isDisabled) {
        toast("Preencha os campos de valor e Tipo de Despesa", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        return;
      }
      const data = {
        dataVencimento: currencyMixin.methods.converterDataParaLong(this.dataVencimento),
        observacao: this.observacao,
        tipoDespesa: this.tipoDespesaId,
        quantidadeRecorrencia: this.quantidadeRecorrencia,
        vencimentoDasRecorrencias: this.vencimentoDasRecorrencias,
        juros: this.juros,
        valor: this.valor,
        desconto: this.desconto,
        recebedorId: this.recebedor?.id,
        nomeRecebedor: this.recebedor?.name
      }

      try {
        await axios.post(`api/contasAPagar`, data);
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro salvar: ${e}!`,
        });
      }
      toast("Conta salva!", {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
        transition: "flip",
        theme: "dark"
      })
      this.updateModal(false)
    },
  }
}
</script>