<template>
  <Modal :show-modal="showModal" :title="title"
         :label-button="'Salvar'"
         @update:showModal="updateModal($event)"
         @update="save()">
    <div class="container">
      <form>
        <div class="form-group row">
          <div class="col-md-12">
            <label>Banco</label>
            <Multiselect v-model="bancoSelected"
                         :options="bancos"
                         :custom-label="customLabel"
                         track-by="id"
                         :multiple="false">
            </Multiselect>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-3">
            <div class="mb-3">
              <label>Nome da maquininha</label>
              <input v-model="nome" type="text" class="form-control" placeholder="Pag Bank">
            </div>
          </div>
          <div class="col-md-9">
            <label>Bandeiras</label>
            <Multiselect v-model="bandeiasSelecionadas"
                         :options="bandeiras"
                         :close-on-select="false"
                         :multiple="true">
            </Multiselect>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <div>
              <div v-for="(parcela, index) in parcelas" :key="index" class="parcela">
                <div class="row col-12">
                  <div class="col">
                    <input v-model="parcela.parcela" class="form-control" type="number" placeholder="Número da parcela">
                  </div>
                  <div class="col">
                    <div class="input-group">
                      <input v-model="parcela.taxa" class="form-control"
                             type="number"
                             placeholder="Taxa da parcela (%)">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="col">
                    <button class="btn btn-danger" @click.prevent="removeParcela(index)">Remover</button>
                  </div>
                </div>
              </div>
              <button class="btn btn-success mt-2" @click.prevent="addParcela()">Adicionar Parcela</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

import axios from "axios";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";

export default {
  name: "ModalTaxas",
  components: {Multiselect, Modal},
  props: {
    showModal: Boolean,
    title: String,
    bancos: Object,
  },
  emits: ['update:showModal', 'update'],
  data() {
    return {
      bancoSelected: null,
      nome: null,

      disabled: false,
      bandeiras: ['Visa', 'Mastercard', 'Banri', 'Elo', 'Hiper', 'Amex'],
      bandeiasSelecionadas: [],
      parcelas: [{parcela: 1, taxa: ''}],
    }
  },
  methods: {
    addParcela() {
      this.parcelas.push({parcela: this.parcelas.length + 1, taxa: ''});
    },
    removeParcela(index) {
      this.parcelas.splice(index, 1);
    },
    customLabel({agencia, conta, nome, razaoSocialOrName}) {
      return `${nome}: ${agencia} - ${conta} - ${razaoSocialOrName}`
    },
    updateModal(event) {
      this.$emit('update:showModal', event)
    },
    async save() {
      const data = {
        nome: this.nome,
        parcelasTaxas: this.parcelas,
        bandeiras: this.bandeiasSelecionadas,
        bancoId: this.bancoSelected?.id,
      }
      try {
        await axios.post(`api/financeiro/maquinas/cartao`, data);
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro salvar: ${e}!`,
        });
      }
      toast("Banco salvo!", {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
        transition: "flip",
        theme: "dark"
      })
      this.updateModal(false)
      this.$emit('update')
    },
  }
}
</script>