<template>
  <nav
      v-if="!isEcommerceListWithHash"
      id="navbarBlur"
      class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
      :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']"
      v-bind="$attrs"
      data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
          :current-directory="currentDirectory"
          :current-page="currentRouteName"
          text-white=""
      />
      <div
          class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
          :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>

      <div
          id="navbar"
          class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
          :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
            class="pe-md-3 d-flex align-items-center"
            :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!--          <div class="input-group">-->
          <!--            <span class="input-group-text text-body"-->
          <!--            ><i class="fas fa-search" aria-hidden="true"></i-->
          <!--            ></span>-->
          <!--&lt;!&ndash;            <input&ndash;&gt;-->
          <!--&lt;!&ndash;                type="text"&ndash;&gt;-->
          <!--&lt;!&ndash;                class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;                :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"&ndash;&gt;-->
          <!--&lt;!&ndash;            />&ndash;&gt;-->
          <!--          </div>-->
        </div>
        <div class="px-3 d-flex justify-content-end">
          <span class="form-control badge rounded-pill text-dark"> {{ getLoggedUser }}</span>
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item align-items-center mt-2">
            <span class="btn badge btn-dark" @click.prevent="singOut">Deslogar</span>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
                id="iconNavbarSidenav"
                href="#"
                class="p-0 nav-link text-body"
                @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          <ModalMetricas
              v-if="isModalVisible" title="Suas comissoes"
              :show-modal="isModalVisible"
              @update:show-modal="isModalVisible = $event;">

          </ModalMetricas>
          <li class="px-3 nav-item d-flex align-items-center">
            <a
                class="p-0 nav-link text-body"
                @click.prevent="toSettigns"
            >
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li
              class="nav-item dropdown d-flex align-items-center"
              :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
                id="dropdownMenuButton"
                href="#"
                class="p-0 nav-link"
                :class="[
                textWhite ? textWhite : 'text-body',
                showMenu ? 'show' : '',
              ]"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                :class="showMenu ? 'show' : ''"
                aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                          src="../../assets/img/team-2.jpg"
                          class="avatar avatar-sm me-3"
                          alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                          src="../../assets/img/small-logos/logo-spotify.svg"
                          class="avatar avatar-sm bg-gradient-dark me-3"
                          alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                        class="my-auto avatar avatar-sm bg-gradient-secondary me-3"
                    >
                      <svg
                          width="12px"
                          height="12px"
                          viewBox="0 0 43 36"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <g
                              transform="translate(-2169.000000, -745.000000)"
                              fill="#FFFFFF"
                              fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                    class="color-background"
                                    d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                    opacity="0.593633743"
                                ></path>
                                <path
                                    class="color-background"
                                    d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import {mapMutations, mapActions, mapState} from "vuex";
import ModalMetricas from "@/views/ModalMetricas.vue";

export default {
  name: "Navbar",
  components: {
    ModalMetricas,
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {
      },
    },
    textWhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      isModalVisible: false
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    isEcommerceListWithHash() {
      return this.$route.path === '/ecommerce/list/hash=f911a465-fdbf-4975-8527-d54a35b7b547'
          || this.$route.path === '/ecommerce/list/hash=92a7500b-9eb1-4f45-8744-46c226754b12';
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    getLoggedUser() {
      return localStorage.getItem('nome')
    }
  },
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    async toSettigns() {
      await this.$router.push(`/pages/account/settings`);
    },
    async singOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('nome');
      localStorage.removeItem('funcionarioId');
      await this.$router.push(`/authentication/signin/basic`);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
};
</script>
