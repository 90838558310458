<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Trocas: </h5>
    <div class="mt-3 multisteps-form__content">
      <div class="container mt-5">
        <div class="card container">
          <div class="card-body">
            <ul v-if="produtosTroca.length" class="list-group">
              <li v-for="(itemTroca, index) in produtosTroca" :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center">

                <div class="d-flex align-items-center">
                  <div v-if="itemTroca" class="m-lg-2">
                    {{ itemTroca.produto?.modelo }} {{ itemTroca.produto?.cor }} {{ itemTroca.produto?.armazenamento }}, Custo:
                    {{ currencyMixin.methods.formattedAmount(itemTroca.precoCusto) }}, Venda: {{ currencyMixin.methods.formattedAmount(itemTroca.produto?.precoVenda) }}
                  </div>
                </div>

                <!-------MODAL--------->
                <ModalProduto v-if="isModalVisible" title="Altere o produto"
                              :produto-dto="selectedProduto"
                              :show-modal="isModalVisible"
                              @update:showModal="isModalVisible = $event"
                              @update="update(itemTroca.produto, $event)">
                </ModalProduto>

                <div>
                  <button v-tippy="'Editar esse produto'"
                          class="btn btn-primary m-auto"
                          @click.prevent="showModal(itemTroca.produto)">
                    <i class="fa fa-edit" aria-hidden="true"></i> <!-- Outro ícone comumente usado para editar -->
                  </button>
                  <button v-tippy="'Apagar esse produto'" type="button"
                          class="btn btn-danger m-auto m-sm-1"
                          @click.prevent="removeProdutoTroca(index, itemTroca.id)">
                    <i class="fa fa-trash" aria-hidden="true"></i> <!-- Ícone de lixeira -->
                  </button>
                </div>
              </li>
            </ul>
            <div v-else>
              Sem trocas
            </div>
            <div>
              <button type="button"
                      class="btn btn-primary col-3 mt-3"
                      @click="addProdutoTroca()">Adicionar troca
              </button>
            </div>
          </div>
        </div>

        <div class="button-row d-flex mt-4">
          <soft-button
              type="button"
              color="light"
              variant="gradient"
              class="js-btn-prev"
              @click.prevent="this.$parent.prevStep"
          >Anterior
          </soft-button
          >
          <soft-button
              type="button"
              color="dark"
              variant="gradient"
              class="ms-auto js-btn-next"
              @click.prevent="checkBeforeNextStep"
          >Próximo
          </soft-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {currencyMixin} from '@/mixins/currencyMixin.js';
import SoftButton from "@/components/SoftButton.vue";

import {toast} from "vue3-toastify";
import ModalProduto from "@/views/ModalProduto.vue";


export default {
  components: {ModalProduto, SoftButton},
  mixins: [currencyMixin],
  props: {
    pedido: null,
  },
  emits: ['fetch'],
  data() {
    return {
      isModalVisible: false,
      selectedProduto: null,
      produtosTroca: [],
      dataEntrada: this.getCurrentDate(),
    };
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    }
  },
  created() {
    if (this.pedido.itemTroca) {
      this.produtosTroca = this.pedido.itemTroca
    }
  },
  methods: {
    checkBeforeNextStep() {
      if (this.produtosTroca?.length) {
        const allProductsValid = this.produtosTroca.every(troca =>
            troca?.produto.modelo && troca.precoCusto != null
        );
        console.log(this.produtosTroca)
        console.log(allProductsValid)
        if (!allProductsValid) {
          toast(`Adicione um modelo antes de salvar`, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark",
            type: "error"
          })
          return
        }
      }
      this.$parent.nextStep()
    },
    showModal(produto) {
      this.selectedProduto = produto;
      this.isModalVisible = true;
    },
    async salvar(newProduto) {
      try {
        const dto = newProduto
        dto.garantiaApple = currencyMixin.methods.converterDataParaLong(dto?.garantiaApple)
        await axios.post(`api/produto/${this.pedido.id}`, newProduto);
        this.$emit("fetch")

        toast(`${newProduto?.id ?'Troca alterada' : 'Troca adicionada'}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e)
      }
    },
    update(oldProduto, newProduto) {
      this.salvar(newProduto)
    },
    async removeProdutoTroca(index, produtoId) {
      if (isNaN(produtoId)) {
        this.produtosTroca.splice(index, 1);
        return
      }
      try {
        await axios.delete(`api/produto/troca/${produtoId}`);
        this.produtosTroca.splice(index, 1);

        toast("Troca removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar pedido: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    addProdutoTroca() {
      const item = {
        produto: {precoCusto: 0}
      }
      this.produtosTroca.push({...item})
    },
    getCurrentDate() {
      return currencyMixin.methods.longToDateSimples(new Date())
    }
  }
}
</script>
