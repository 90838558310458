<template>
  <div v-if="showModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <form v-if="!isLoading">
              <div class="form-group">
                <label>Comissao total do mes: </label>
                <input
                    v-model="comissaoTotal"
                    class="form-control"
                    disabled
                />

              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label>Comissão por produto:</label>
                  <ul class="list-group">
                    <li v-for="(comissao, produto) in comissaoPorProduto" :key="produto" class="list-group-item">
                      {{ produto }}: {{ comissao }}
                    </li>
                  </ul>
                </div>
              </div>
            </form>
            <form v-else>
              Carregando...
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: "ModalMetricas",
  props: {
    showModal: Boolean,
    title: String,
  },
  emits: ['update:showModal'],
  data() {
    return {
      comissaoPorProduto: null,
      comissaoTotal: {},

      isLoading: false
    }
  },
  created() {
    this.get()
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
    },
    async get() {
      this.isLoading = true
      try {
        const funcionarioId = localStorage.getItem('funcionarioId');
        const response = await axios.get(`api/funcionario/metricas/${funcionarioId}`);
        const data = response.data;
        this.comissaoPorProduto = data.comissaoPorProduto
        this.comissaoTotal = data.comissaoTotal
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao buscar: ${e.data}!`,
        });
      }
      this.isLoading = false
    },
  }
}
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%; /* Aumenta a largura máxima do modal */
  max-height: 80%; /* Aumenta a altura máxima do modal */
}
</style>