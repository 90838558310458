<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Vendas por vendedor</h6>
        <button
            type="button"
            class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="See traffic channels"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row">
        <div class="col-12 text-start">
          <pie-chart
              :chart="{
              labels: funcionarios,
              datasets: {
                label: 'Vendas',
                data: vendas,
              },
            }"
          />
        </div>
      </div>
      <div v-for="(item, index) in vendas" :key="index">
        <span class="align-content-center">{{ item.name }} : {{ formattedAmount(item.value) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/examples/Charts/PieChart.vue";

export default {
  name: "ChannelsChartCard",
  components: {
    PieChart,
  },
  props: {
    vendas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      funcionarios: [],
      valores: []
    }
  },
  created() {
    this.funcionarios = this.vendas.map(v => v.name)
    this.valores = this.vendas.map(v => v.value)
  },
  methods: {
    formattedAmount(valor) {
      if (valor == null) {
        return null
      }
      let currencyValue = parseFloat(valor);
      return currencyValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    },
  }
};
</script>
