<template>
  <div class="container mt-4">
    <VCalendar :attributes='attributes' />
  </div>

</template>

<script>

import {Calendar as VCalendar} from "v-calendar";

export default {
  components: {VCalendar},
  data() {
    return {
      attributes: [
        // This is a single attribute
        {
          // An optional key can be used for retrieving this attribute later,
          // and will most likely be derived from your data object
          // Attribute type definitions
          content: 'red',   // Boolean, String, Object
          highlight: true,  // Boolean, String, Object
          dot: true,        // Boolean, String, Object
          bar: true,        // Boolean, String, Object
          // Your custom data object for later access, if needed
          // We also need some dates to know where to display the attribute
          // We use a single date here, but it could also be an array of dates,
          //  a date range or a complex date pattern.
          dates: new Date(),
          // Think of `order` like `z-index`
          order: 0
        }
      ]
    };
  },
  computed: {
  },
  methods: {

  }
}

</script>
