<template>
  <div class="container mt-5">
    <div class="col-md-12">
      <div class="form-group col-md-4">
        <label>Status:</label>
        <select v-model="produto.status" :disabled="produto.status === 'VENDIDO'" class="form-control" @change="salvar">
          <option value="DISPONIVEL">DISPONIVEL</option>
          <option value="ENCOMENDA">ENCOMENDA</option>
          <option value="DEVOLVIDO">DEVOLVIDO</option>
          <option value="CONSERTO">CONSERTO</option>
          <option value="DEFEITO">COM DEFEITO</option>
          <option value="EM_USO_LOJA">EM USO PELA LOJA</option>
          <option v-if="produto.status === 'VENDIDO'" value="VENDIDO">VENDIDO</option>
        </select>
      </div>
      <div class="row col-12">
        <div class="form-group col-4">
          <label>Fornecedor:</label>
          <div class="input-group mb-3">
            <Multiselect v-if="fornecedoresOptions.length > 0"
                         v-model="fornecedor"
                         :options="fornecedoresOptions"
                         label="completedName"
                         :multiple="false">
            </Multiselect>
          </div>
        </div>


        <!-- Modal de Visualização -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span class="close" @click="showModal = false">&times;</span>
            <div class="upload-area">
              <!-- Área de visualização para os arquivos carregados do S3 -->
              <div v-for="(file, index) in filesFromS3" :key="index" class="preview">
                <!-- Botão ou ícone de remoção -->
                <span class="remove-icon" @click="removeFile(file.name, index)">&#10006;</span>
                <template v-if="isImage(file.type)">
                  <img :src="file.url" :alt="file.name" class="preview-image">
                </template>
                <template v-else>
                  <video :src="file.url" controls class="preview-video"></video>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="row col-12">
            <div class="col-8">
              <div class="upload-area">
                <input type="file" multiple @change="handleFileUpload">
                <span class="text-muted small">Máximo de 100MB</span>
              </div>
            </div>
            <div class="col-4 align-self-center">
              <button class="btn btn-info" @click="showModal = true">Ver os Conteúdos</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form>
      <div v-if="produto.tipoProduto === 'IPHONE'">
        <div class="form-group row">
          <div class="col-md-6">
            <label>Modelo:</label>
            <input
                v-model="produto.modelo"
                class="form-control"
            />
          </div>
          <div class="col-md-2">
            <div class="mb-3">
              <label for="bateria" class="form-label">Bateria</label>
              <div class="input-group">
                <input v-model="produto.bateria"
                       type="text" class="form-control"
                       @input.prevent="formatBateria">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label for="cor">Cor:</label>
            <input
                v-model="produto.cor"
                type="text"
                class="form-control"
                placeholder="Digite a cor"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <div class="mb-3">
              <div class="form-check form-switch p-0">
                <div class="d-flex flex-column-reverse gap-1">
                  <input v-model="produto.isVisibleInPriceList" class="form-check-input ms-0" type="checkbox"
                         role="switch" checked/>
                  <label class="form-check-label" for="switchCheckLabelTop">Visível no Ecommerce?</label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="mb-3">
              <div class="form-check form-switch p-0">
                <div class="d-flex flex-column-reverse gap-1">
                  <input id="switchCheckLabelTop" v-model="produto.isNovo" class="form-check-input ms-0" type="checkbox"
                         role="switch"/>
                  <label class="form-check-label" for="switchCheckLabelTop">É lacrado (novo) ?</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <!-- Campo Armazenamento -->
          <div class="col-md-3">
            <div class="form-group">
              <label for="armazenamento">Armazenamento:</label>
              <input v-model="produto.armazenamento" list="armazenamentos" class="form-control"
                     @input="checkArmazenamento"/>
              <datalist id="armazenamentos">
                <option v-for="opcao in $store.state.armazenamentos" :key="opcao" :value="opcao">
                  {{ opcao }}
                </option>
              </datalist>
              <span v-if="!validArmazenamento" class="text-danger">Selecione um armazenamento válido da lista!</span>
            </div>
          </div>

          <div class="col-md-3">
            <div class="mb-3">
              <label class="form-label">Número de serie</label>
              <input v-model="produto.serie" class="form-control" type="text"/>
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3">
              <label for="imei" class="form-label">IMEI</label>
              <input id="imei" v-model="produto.imei" type="text" class="form-control" @input.prevent="clearInput()">
            </div>
          </div>

          <div class="col-md-2">
            <div class="mb-3">
              <label>Grade:</label>
              <input v-model="produto.grade" type="text" class="form-control">
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-5 mb-3">
            <label for="dataEntrada" class="form-label">Data de Entrada</label>
            <input id="dataEntrada" v-model="produto.dataEntrada" type="date" class="form-control">
          </div>
          <div class="col-3 mb-3">
            <label for="garantiaApple" class="form-label">Garantia apple</label>
            <input id="garantiaApple" v-model="produto.garantiaApple" type="date" class="form-control">
          </div>
          <div class="col-3 mb-3">
            <label>Garantia pos venda em meses:</label>
            <select v-model="produto.garantia" class="form-control">
              <option value="1">1</option>
              <option value="3">3</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="12">12</option>
            </select>
          </div>
        </div>

      </div>

      <!-- Se o tipo do produto for ACESSORIO -->
      <div v-if="produto.tipoProduto === 'ACESSORIO'">


        <div class="col-12 row">
          <div class="col-6">
            <label>Modelo:</label>
            <input
                v-model="produto.modelo"
                class="form-control"
            />
          </div>

          <div class="col-6">
            <label for="modeloAplicado">Modelos aplicados</label>
            <multiselect v-model="produto.modelosAplicadosTransient" :options="$store.state.iphones" :multiple="true"
                         :close-on-select="false"
                         :clear-on-select="false"
                         :preserve-search="true" placeholder="Selecione os modelos" :preselect-first="true">
            </multiselect>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-6 mb-3">
            <label for="cor">Cor:</label>
            <input
                v-model="produto.cor"
                type="text"
                class="form-control"
                placeholder="Digite a cor"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="quantidade">Quantidade:</label>
            <input v-model="produto.quantidade" type="number" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div v-if="role === 'ADMIN' || role === 'GERENTE'" class="col-md-4 mb-3">
          <label for="precoCusto" class="form-label">Preço de Custo</label>
          <CurrencyInput
              v-model="produto.precoCusto"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>

        <div class="col-md-4 mb-3">
          <label for="precoVenda" class="form-label">Preço Mínimo de Venda</label>
          <CurrencyInput
              v-model="produto.precoMinimoVenda"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>

        <div class="col-md-4 mb-3">
          <label for="precoVenda" class="form-label">Preço de Venda</label>
          <CurrencyInput
              v-model="produto.precoVenda"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
      </div>

      <div>
        <div v-for="(gasto, index) in outrosGastos" :key="index" class="col-12 row align-items-end">
          <div class="col-2 mb-3">
            <label>Nome</label>
            <input v-model="gasto.nome" :disabled="gasto.id" class="form-control">
          </div>
          <div class="col-2 mb-3">
            <label>Valor</label>
            <CurrencyInput
                v-model="gasto.valor"
                :disabled="gasto.id"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
          <div class="col-2">
            <button v-if="!gasto.id" type="submit" class="btn btn-success" @click.prevent="salvarManutencao(gasto.nome, gasto.valor)"><i
                class="fa fa-plus" aria-hidden="true"></i></button>
            <button type="submit" class="btn btn-danger ms-2" @click.prevent="deleteManutencao(gasto.id, index)"><i
                class="fa fa-trash" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4 mb-3">
          <button type="submit" class="btn btn-info" @click.prevent="outrosGastos.push({})">Adicionar outros gastos
          </button>
        </div>
      </div>
      <div class="mb-3">
        <label for="observacao" class="form-label">Observação</label>
        <textarea v-model="produto.observacao" class="form-control" rows="3"></textarea>
      </div>

      <button type="submit" class="btn btn-primary" @click.prevent="salvar">Salvar</button>

    </form>
  </div>
</template>

<script>
import axios from 'axios';
import CurrencyInput from "@/CurrencyInput.vue";
import {toast} from "vue3-toastify";
import {currencyMixin} from "@/mixins/currencyMixin";
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect, CurrencyInput},
  data() {
    return {
      showSnackbar: false,
      produto: {},
      fornecedoresOptions: [],
      nomeFornecedor: null,
      fornecedor: {},
      outrosGastos: [],
      validArmazenamento: true,
      sugestaoPrecoVenda: 0,
      filesFromS3: {},
      files: [],
      showModal: false,
      id: null,
      isDisabled: false,
      role: null
    };
  },
  async created() {
    await this.getFornecedores()
    await this.getProduto()
    await this.getLoggedUser()
  },
  methods: {
    async getLoggedUser() {
      const response = await axios.get("/api/users");
      this.role = response.data.role;
    },
    async salvarManutencao(nome, valor) {
      try {
        const dto = {
          nome: nome,
          valor: valor,
          produtoId: this.id
        }
        await axios.post(`api/produto/manutencao`, dto);
        await this.getProduto()
        toast("Manutenção salva", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast("Erro ao salvar manutenção", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    async deleteManutencao(id, index) {
      try {
        this.outrosGastos.splice(index, 1)
        if (id) {
          await axios.delete(`api/produto/manutencao/${id}`);
          await this.getProduto()
        }
        toast("Manutenção removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast("Erro ao remover manutenção", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    async removeFile(filename, index) {
      try {
        await axios.delete(`api/produto/${this.id}/contents`, {
          params: {
            key: filename
          }
        });
      } catch (e) {
        toast("Erro ao remover conteudo!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      this.filesFromS3.splice(index, 1);
    },
    async handleFileUpload(event) {
      const selectedFiles = event.target.files;
      if (!selectedFiles.length) return;

      this.isDisabled = true

      // Preparar os arquivos para upload (FormData)
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('contents', selectedFiles[i]);
      }

      // Exemplo de como os arquivos seriam preparados para visualização
      this.files = Array.from(selectedFiles).map(file => ({
        name: file.name,
        type: file.type,
        preview: URL.createObjectURL(file)
      }));

      const idToast = toast.loading(
          'Processando envio...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );

      // Fazer a requisição POST
      try {
        await axios.post(`api/produto/${this.$route.params._id}/contents`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast.update(idToast, {
          render: `Conteúdo enviado!`,
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success",
          isLoading: false,
        })
        this.isDisabled = false

        await this.getContents()

      } catch (error) {
        toast("Erro ao enviar conteudo!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        console.error('Error during file upload', error);
      }
    },
    isImage(type) {
      return type.startsWith('.png') || type.startsWith('.jpeg') || type.startsWith('.jpg');
    },
    async getContents() {
      const response = await axios.get(`api/produto/${this.id}/contents`);
      this.filesFromS3 = response.data
    },
    async getProduto() {
      try {
        this.id = this.$route.params._id;
        const response = await axios.get(`api/produto/${this.id}`);
        this.produto = response.data;
        this.produto.dataEntrada = currencyMixin.methods.longToDateSimples(this.produto.dataEntrada)
        this.produto.garantiaApple = currencyMixin.methods.longToDateSimples(this.produto.garantiaApple)
        this.fornecedor = this.produto.fornecedor == null ? {} : this.produto.fornecedor

        this.outrosGastos = this.produto.manutencoes
        if (response.data.contents) {
          await this.getContents()
        }
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },
    clearInput() {
      this.produto.imei = this.produto.imei.replace(/\D/g, '');
    },
    formatBateria() {
      this.produto.bateria = this.produto.bateria.replace(/\D/g, '');
    },
    async getFornecedores() {
      let res = await axios.get(`api/fornecedor`);
      this.fornecedoresOptions = res.data
    },
    async salvar() {
      const produto = {
        modelosAplicadosTransient: this.produto.modelosAplicadosTransient,
        imei: this.produto.imei,
        serie: this.produto.serie,
        modelo: this.produto.modelo,
        cor: this.produto.cor,
        armazenamento: this.produto.armazenamento,
        grade: this.produto.grade,
        isNovo: this.produto.isNovo,
        isVisibleInPriceList: this.produto.isVisibleInPriceList,
        precoVenda: this.produto.precoVenda,
        precoCusto: this.produto.precoCusto,
        precoMinimoVenda: this.produto.precoMinimoVenda,
        observacao: this.produto.observacao,
        garantiaApple: currencyMixin.methods.converterDataParaLong(this.produto.garantiaApple),
        bateria: this.produto.bateria,
        status: this.produto.status,
        garantia: this.produto.garantia,
        quantidade: this.produto.quantidade,
        fornecedorId: this.fornecedor?.id
      }

      const _id = this.$route.params._id;
      try {
        await axios.put(`api/produto/${_id}`, produto);
        toast("Produto atualizado!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar produto ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      }
    },
    checkArmazenamento() {
      this.validArmazenamento = this.armazenamentos.includes(this.produto.armazenamento);
    },
  }
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.preview-image, .preview-video {
  max-width: 100%; /* Adaptação para o modal */
  height: auto;
  margin-bottom: 10px;
}

.upload-area {
  border: 2px dashed #ccc; /* Borda tracejada para a área de upload */
  border-radius: 10px; /* Bordas arredondadas */
  padding: 20px; /* Espaçamento interno */
  margin-bottom: 20px; /* Margem inferior */
  background-color: #f9f9f9; /* Cor de fundo */
  display: flex; /* Usando Flexbox para alinhar conteúdo */
  flex-wrap: wrap; /* Permite que os itens se ajustem na próxima linha */
  justify-content: start; /* Alinha itens para o início */
  gap: 10px; /* Espaçamento entre os itens de visualização */
}

.remove-icon {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  cursor: pointer;
  padding: 5px;
  color: black;
}

.preview-image, .preview-video {
  max-width: 200px; /* Largura máxima para imagem/vídeo */
  max-height: 200px; /* Altura máxima para imagem/vídeo */
  object-fit: cover; /* Garante que o conteúdo seja coberto de forma adequada */
  border-radius: 5px; /* Bordas arredondadas para imagens/vídeos */
}

.preview {
  position: relative;
  display: inline-block;
  margin-right: 10px; /* Ajuste conforme necessário */
}

input[type="file"] {
  margin-bottom: 15px; /* Margem inferior para o campo de arquivo */
}
</style>

