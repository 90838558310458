<template>
  <div
      v-if="!isEcommerceListWithHash"
      id="sidenav-collapse-main"
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">

      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="ecommerceExamples"
            nav-text="Vendas"
            :class="getRoute() === 'ecommerce' ? 'active' : ''"
        >
          <template #icon>
            <Basket/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Overview' }"
                  mini-icon="O"
                  text="Overview"
              />

              <sidenav-collapse-item
                  refer="ordersExample"
                  mini-icon="O"
                  text="Vendas"
              >
                <template #nav-child-item>
                  <sidenav-item
                      :to="{ name: 'Lista de pedidos' }"
                      mini-icon="O"
                      text="Lista"
                  />
                  <sidenav-item
                      :to="{ name: 'NewPedido' }"
                      mini-icon="O"
                      text="Criar venda"
                  />
                </template>
              </sidenav-collapse-item>
              <sidenav-item
                  :to="{ name: 'Parcelamento' }"
                  mini-icon="O"
                  text="Simulação de vendas"
              />

<!--              <sidenav-item-->
<!--                  :to="{ name: 'Visitas' }"-->
<!--                  mini-icon="O"-->
<!--                  text="Agendamento de visitas"-->
<!--              />-->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="Produtos"
            nav-text="Produtos"
            :class="getRoute() === 'products' ? 'active' : ''"
        >
          <template #icon>
            <CreditCard/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Criar produto' }"
                  mini-icon="N"
                  text="Criar produto"
              />
              <sidenav-item
                  :to="{ name: 'Lista de produtos' }"
                  mini-icon="P"
                  text="Lista de produtos"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="Fiscal"
            nav-text="Fiscal"
            :class="getRoute() === 'fiscal' ? 'active' : ''"
        >
          <template #icon>
            <CreditCard/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Criar nota' }"
                  mini-icon="N"
                  text="Criar nota"
              />
              <sidenav-item
                  :to="{ name: 'Listar notas' }"
                  mini-icon="P"
                  text="Listar notas"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="pagesExamples"
            nav-text="Cadastros"
            :class="getRoute() === 'cadastro' ? 'active' : ''"
        >
          <template #icon>
            <Office/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-collapse-item
                  refer="Cliente"
                  mini-icon="O"
                  text="Cliente"
              >
                <template #nav-child-item>
                  <sidenav-item
                      to='/cadastro/create/cliente'
                      mini-icon="O"
                      text="Criar cliente"
                  />
                  <sidenav-item
                      to='/cadastro/cliente/list'
                      mini-icon="O"
                      text="Listar clientes"
                  />
                </template>
              </sidenav-collapse-item>

              <sidenav-collapse-item
                  refer="Fornecedor"
                  mini-icon="O"
                  text="Fornecedor"
              >
                <template #nav-child-item>
                  <sidenav-item
                      to='/cadastro/create/fornecedor'
                      mini-icon="O"
                      text="Criar fornecedor"
                  />
                  <sidenav-item
                      to='/cadastro/fornecedor/list'
                      mini-icon="O"
                      text="Listar fornecedores"
                  />
                </template>
              </sidenav-collapse-item>

              <!--              <sidenav-collapse-item-->
              <!--                  refer="Funcionario"-->
              <!--                  mini-icon="O"-->
              <!--                  text="Funcionario"-->
              <!--              >-->
              <!--                <template #nav-child-item>-->
              <!--                  <sidenav-item-->
              <!--                      to='/cadastro/create/funcionario'-->
              <!--                      mini-icon="O"-->
              <!--                      text="Criar funcionario"-->
              <!--                  />-->
              <!--                  <sidenav-item-->
              <!--                      to='/cadastro/fornecedor/list'-->
              <!--                      mini-icon="O"-->
              <!--                      text="Listar funcionarios"-->
              <!--                  />-->
              <!--                  <sidenav-item-->
              <!--                      to='/cadastro/fornecedor/list'-->
              <!--                      mini-icon="O"-->
              <!--                      text="Controle de metas"-->
              <!--                  />-->
              <!--                </template>-->
              <!--              </sidenav-collapse-item>-->

              <!--              <sidenav-collapse-item-->
              <!--                  refer="Outros"-->
              <!--                  mini-icon="O"-->
              <!--                  text="Outras entidades"-->
              <!--              >-->
              <!--                <template #nav-child-item>-->
              <!--                  <sidenav-item-->
              <!--                      to='/cadastro/create/outros'-->
              <!--                      mini-icon="O"-->
              <!--                      text="Criar outros entidades"-->
              <!--                  />-->
              <!--                  <sidenav-item-->
              <!--                      to='/cadastro/outros/list'-->
              <!--                      mini-icon="O"-->
              <!--                      text="Listar outros entidades"-->
              <!--                  />-->
              <!--                </template>-->
              <!--              </sidenav-collapse-item>-->
            </ul>
          </template>

        </sidenav-collapse>
      </li>
      <li v-if="role === 'admin'" class="nav-item">
        <sidenav-collapse

            collapse-ref="Financeiro"
            nav-text="Financeiro"
            :class="getRoute() === 'financeiro' ? 'active' : ''"
        >
          <template #icon>
            <CreditCard/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <!--              <sidenav-item-->
              <!--                  :to="{ name: 'Contas a receber' }"-->
              <!--                  mini-icon="O"-->
              <!--                  text="Contas a receber"-->
              <!--              />-->
              <sidenav-item
                  :to="{ name: 'Contas a pagar' }"
                  mini-icon="O"
                  text="Contas a pagar"
              />
              <sidenav-item
                  :to="{ name: 'Dashbord Financeiro' }"
                  mini-icon="O"
                  text="Dashbord"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="Compras"
            nav-text="Compras"
            :class="getRoute() === 'compras' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-truck"></i>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Lista de Cotações' }"
                  mini-icon="O"
                  text="Cotações"
              />
              <!--              <sidenav-item-->
              <!--                  :to="{ name: 'Lista de Cotações' }"-->
              <!--                  mini-icon="O"-->
              <!--                  text="Compra Inteligênte"-->
              <!--              />-->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="inteligencial"
            nav-text="Inteligência artificial"
            :class="getRoute() === 'chat' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-brain"></i>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                  :to="{ name: 'chat' }"
                  mini-icon="O"
                  text="Chat"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Basket from "../../components/Icon/Basket.vue";

import {mapState} from "vuex";
import Office from "@/components/Icon/Office.vue";
import CreditCard from "@/components/Icon/CreditCard.vue";

export default {
  name: "SidenavList",
  components: {
    CreditCard,
    Office,
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    Basket,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["role"]),
    isEcommerceListWithHash() {
      return this.$route.path === '/ecommerce/list/f911a465-fdbf-4975-8527-d54a35b7b547'
          || this.$route.path === '/ecommerce/list/92a7500b-9eb1-4f45-8744-46c226754b12';
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>

<style scoped>
/* Estilização da barra de rolagem para navegadores WebKit */
#sidenav-collapse-main::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

#sidenav-collapse-main::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do trilho da barra de rolagem */
}

#sidenav-collapse-main::-webkit-scrollbar-thumb {
  background: #888; /* Cor da barra de rolagem */
}

#sidenav-collapse-main::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra de rolagem ao passar o mouse */
}

/* Para outros navegadores como Firefox, você pode usar as propriedades scrollbar-color e scrollbar-width */
#sidenav-collapse-main {
  scrollbar-color: #888 #f1f1f1; /* Cor da barra de rolagem e do trilho */
  scrollbar-width: thin; /* Largura da barra de rolagem */
}
</style>
