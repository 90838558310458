<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Quantidade de vendas agrupadas por dia</h6>
        <button
            type="button"
            class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title=""
            data-bs-original-title="See which ads perform better"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-success"></i>
          <span class="text-xs text-dark">Vendas mês passado</span>
        </span>
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-dark"></i>
          <span class="text-xs text-dark">Vendas esse mês</span>
        </span>
      </div>
    </div>
    <div class="p-3 card-body">
      <default-line-chart
          id="chart-line3"
          title="Traffic channels"
          :chart="{
          labels: labels,
          datasets: [
            {
              label: 'Este mês',
              data: valores,
            },
            // {
            //   label: 'Referral',
            //   data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            // },
          ],
        }"
      />
    </div>
  </div>
</template>

<script>
import DefaultLineChart from "../../../../examples/Charts/DefaultLineChart.vue";

export default {
  name: "RevenueChartCard",
  components: {
    DefaultLineChart,
  },
  props: {
    vendas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      labels: [],
      valores: []
    }
  },
  created() {
    console.log(this.vendas);
    this.labels = Object.keys(this.vendas).map(data => {
      let partes = data.split('/');
      return partes[0]; // O dia é a primeira parte da data
    });
    this.valores = Object.values(this.vendas)

    console.log(this.labels);
  },
};
</script>
