<template>
  <div class="py-4 container-fluid">
    <div v-if="metricas && role === 'admin'" class="card-body">
      <div class="row">
        <!-- Card para cada status -->
        <div v-for="(value, statuses) in metricas.produtosPorStatus" :key="statuses" class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 v-tippy="`Clique para filtrar os produtos por ${statuses}`"
                  class="card-title badge btn"
                  :class="getClassStatus(statuses)"
                  @click.prevent="status = statuses; loadProdutos()">
                <i :class="getIconClass(statuses)"></i>
                {{
                  statuses === 'EM_USO_LOJA'
                      ? 'USADO NA LOJA'
                      : (statuses === 'NO_CAMINHO' ? 'NO CAMINHO' : statuses)
                }}
              </h6>
              <p>
                <strong>Quantidade:</strong> {{ value.quantidade }}
                <br/>
                <strong v-if="role === 'admin'">Total:</strong> {{ formattedAmount(value.precoCustoTotal) }}
              </p>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <!-- Card para Total em Estoque -->
        <div v-if="role === 'admin'" class="col-sm-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title">Total em estoque aparelhos: {{ formattedAmount(metricas.totalPrecoCusto) }}</h6>
              <strong>Quantidade: {{ metricas.totalEstoqueDisponivel }}</strong>
              <h6 class="card-title">Total em estoque de acessórios: {{
                  formattedAmount(metricas.totalPrecoCustoAc)
                }}</h6>
              <strong>Quantidade: {{ metricas.totalEstoqueDisponivelAc }}</strong>
              <h6 class="card-title">Total valor de venda: {{ formattedAmount(metricas.totalPrecoVenda) }} </h6>
              <h6 class="card-title">Total valor de venda mínimo: {{
                  formattedAmount(metricas.totalPrecoVendaMinimo)
                }}</h6>
              <h6 class="card-title">lucro: {{ formattedAmount(metricas.totalPrevisaoLucro) }} -
                {{ calcularMargemDeLucro }}%</h6>
              <h6 class="card-title">Lucro mínimo: {{ formattedAmount(metricas.totalPrevisaoLucroMinimo) }} -
                {{ calcularMargemDeLucroMinima }}%</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div class="col-md-4 mt-2 form-group">
                <div>
                  <h5 class="mb-0">Produtos</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <a
                        href="./new-product"
                        class="mb-0 btn bg-gradient-success btn-sm"
                        target="_blank"
                    >+&nbsp; Criar Produto</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group custom-select-container">
                <label><i class="bi bi-person-fill me-2"></i>Modelo:</label>
                <input v-model="modelo"
                       class="form-control"
                       list="iphone-models"
                       type="text"
                       placeholder="Pesquise por modelo"
                       @input="searchModelo">
              </div>
              <div class="mt-4 ms-auto ">
                <div class="dropdown d-inline">
                  <soft-button
                      id="navbarDropdownMenuLink2"
                      color="dark"
                      variant="outline"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >Filtros
                  </soft-button
                  >
                  <ul
                      class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                      aria-labelledby="navbarDropdownMenuLink2"
                      style
                  >
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'DISPONIVEL' }"
                         @click.stop="status = 'DISPONIVEL'"
                      >Status: DISPONIVEL</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'ENCOMENDA' }"
                         @click.stop="status = 'ENCOMENDA'"
                      >Status: ENCOMENDA</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'RESERVADO' }"
                         @click.stop="status = 'RESERVADO'"
                      >Status: RESERVADO</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'VENDIDO' }"
                         @click.stop="status = 'VENDIDO'"
                      >Status: VENDIDO</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'DEVOLVIDO' }"
                         @click.stop="status = 'DEVOLVIDO'"
                      >Status: DEVOLVIDO</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'CONSERTO' }"
                         @click.stop="status = 'CONSERTO'"
                      >Status: EM CONSERTO</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'DEFEITO' }"
                         @click.stop="status = 'DEFEITO'"
                      >Status: COM DEFEITO</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: status === 'EM_USO_LOJA' }"
                         @click.stop="status = 'EM_USO_LOJA'"
                      >Status: EM USO NA LOJA</a
                      >
                    </li>
                    <li>
                      <hr class="horizontal dark my-2"/>
                    </li>
                    <li class="px-4 mt-2">
                      <button class="btn btn-sm btn-primary" @click.prevent="loadProdutos()">Filtrar</button>
                    </li>
                    <li>
                      <hr class="horizontal dark my-2"/>
                    </li>
                    <li>
                      <a
                          class="dropdown-item px-4 mt-2 border-radius-md text-danger"
                          @click.prevent="removeFilters()"
                      >Remove Filter</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mt-4 ms-auto ">
                <div class="dropdown d-inline">
                  <soft-button
                      id="navbarDropdownMenuLink2"
                      color="dark"
                      variant="outline"
                      class="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                    Grupo
                  </soft-button>
                  <ul
                      class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                      aria-labelledby="navbarDropdownMenuLink2"
                      style>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: tipo === 'IPHONE' }"
                         @click.stop="tipo = 'IPHONE'; loadProdutos()"
                      >IPHONE</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item border-radius-md"
                         :class="{ selected: tipo === 'ACESSORIO' }"
                         @click.stop="tipo = 'ACESSORIO'; loadProdutos()"
                      >ACESSORIO</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Modelo</th>
                  <th v-if="tipo === 'ACESSORIO'">Modelos aplicado</th>
                  <th class="text-center" v-if="tipo === 'IPHONE'">Dias</th>
                  <th v-else>Quantidade</th>
                  <th v-if="tipo === 'IPHONE'">IMEI</th>
                  <th v-if="role === 'admin'" class="text-center">Markup</th>
                  <th class="text-center">Venda Varejo/atacado</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="produto in filteredModelos" :key="produto.id">
                  <td class="text-sm col-2 text-start text-bold" @click.prevent="toUrl(produto.id)">

                    <i v-if="verificarAtributos(produto).length"
                       v-tippy="`O(s) campo(s): ${verificarAtributos(produto)} estão incompletos ou vazios!`"
                       class="fa fa-exclamation-circle blink"
                       style="color: red;">
                    </i>

                    {{ produto.modelo }}
                    {{ produto.armazenamento }} {{ produto.cor }}
                    {{ produto.bateria === null ? '' : ' - ' + produto.bateria + '%' }}
                    {{ produto.isNovo ? ' NOVO' : '' }}

                    <span v-if="produto.observacao?.includes('Produto adquirido')"
                          class="badge text-center bg-secondary">
                      Troca
                    </span>
                  </td>

                  <td v-if="tipo === 'ACESSORIO'" class="text-center col-1 text-bold">
                    {{ produto?.modelosAplicadosTransient?.join(', ').trim() }}
                  </td>

                  <td v-if="tipo === 'IPHONE'" class="text-center col-1 text-bold"
                      :style="getDays(produto) >= 10 ? 'color: red; font-style: oblique' : ''">{{ getDays(produto) }}
                  </td>
                  <td v-else class="text-sm col-1 text-center">{{ produto.quantidade }}</td>

                  <td v-if="tipo === 'IPHONE'" class="text-sm col-1">{{ formatImei(produto.imei) }}</td>

                  <td v-if="role === 'admin'" v-tippy="getTooltipContent(produto)" class="text-sm text-center col-2">
                    {{ lucroLiquido(produto.precoCusto, produto.precoVenda) }} -
                    {{ calculeMargem(produto.precoCusto, produto.precoVenda) }}
                    <br> {{ lucroLiquido(produto.precoCusto, produto.precoMinimoVenda) }} -
                    {{ calculeMargem(produto.precoCusto, produto.precoMinimoVenda) }}
                  </td>
                  <td class="text-sm text-center col-3">{{ formattedAmount(produto.precoVenda) }}
                    <br> {{ isNaN(produto.precoMinimoVenda) ? '' : formattedAmount(produto.precoMinimoVenda) }}
                  </td>
                  <td>
                      <span
                          class="badge text-center"
                          :class='getClassStatus(produto.status)'
                      >{{
                          produto.status === 'EM_USO_LOJA'
                              ? 'USADO NA LOJA'
                              : (produto.status === 'NO_CAMINHO' ? 'NO CAMINHO' : produto.status)
                        }}</span
                      >
                  </td>
                  <td>
                    <i v-if="produto.status === 'DISPONIVEL'" v-tippy="'Deletar produto'"
                       class="fas fa-trash-alt" @click.prevent="deleteProduct(produto.id)"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from "@/components/SoftButton.vue";
import {mapState} from "vuex";

export default {
  name: "ProductsList",
  components: {SoftButton},
  data() {
    return {
      produtos: [],
      filteredModelos: [],
      status: 'DISPONIVEL',
      modelo: null,
      tipo: 'IPHONE',
      metricas: null,
    }
  },
  computed: {
    ...mapState(['role']),
    calcularMargemDeLucro() {
      if (this.metricas.totalPrevisaoLucro === 0) {
        return 0;
      }
      const margem = (this.metricas.totalPrevisaoLucro / this.metricas.totalPrecoVenda) * 100;
      return margem.toFixed(2);
    },
    calcularMargemDeLucroMinima() {
      if (this.metricas.totalPrevisaoLucroMinimo === 0) {
        return 0;
      }
      const margem = (this.metricas.totalPrevisaoLucroMinimo / this.metricas.totalPrecoVendaMinimo) * 100;
      return margem.toFixed(2);
    }
  },
  mounted() {
    this.fetchMetricas()
    this.loadProdutos();
  },
  methods: {
    getIconClass(key) {
      const icons = {
        DISPONIVEL: 'fa fa-check',
        EM_USO_LOJA: 'fa fa-shopping-bag',
        CONSERTO: 'fa fa-tools',
        DEFEITO: 'fa fa-exclamation-triangle',
        NO_CAMINHO: 'fa fa-tag'
      };
      return icons[key] || 'fa fa-question-circle'; // Ícone padrão se não houver correspondência
    },
    getClassStatus(status) {
      if (status === 'DISPONIVEL') {
        return 'bg-success'
      } else if (status === 'CONSERTO') {
        return 'bg-danger'
      } else if (status === 'DEFEITO') {
        return 'bg-warning text-dark'
      } else if (status === 'EM_USO_LOJA') {
        return 'bg-secondary'
      } else if (status === 'DEVOLVIDO') {
        return 'bg-secondary'
      } else if (status === 'NO_CAMINHO') {
        return 'bg-info text-dark'
      }
    },
    verificarAtributos(objeto) {
      if (objeto.tipoProduto == null || objeto.tipoProduto !== "IPHONE") {
        return []
      }
      const atributosParaVerificar = ['armazenamento', 'bateria', 'cor', 'imei', 'modelo', 'precoCusto', 'precoVenda'];
      let atributosVazios = [];

      atributosParaVerificar.forEach(atributo => {
        if (objeto[atributo] === null || objeto[atributo] === '' || objeto[atributo] === 0) {
          if (atributo === 'precoCusto') {
            atributosVazios.push('Preco de Custo');
          } else if (atributo === 'precoVenda') {
            atributosVazios.push('Preco de Venda');
          } else {
            atributosVazios.push(atributo);
          }
        }
      });
      return atributosVazios
    },
    searchModelo() {
      if (this.modelo == null) {
        this.filteredModelos = this.produtos
        return
      }
      this.filteredModelos = this.produtos.filter(produto =>
          (produto.tipoProduto === this.tipo &&
              produto.modelo &&
              produto.modelo.toLowerCase().includes(this.modelo.toLowerCase())
              || (produto.modelo.toLowerCase() + " " + produto.armazenamento.toLowerCase()).includes(this.modelo.toLowerCase())
              || (produto.modelo.toLowerCase() + " " + produto.armazenamento.toLowerCase() + " " + produto.cor.toLowerCase())
                  .includes(this.modelo.toLowerCase()))
      );
    },
    async toUrl(productId) {
      await this.$router.push(`/products/${productId}`);
    },
    async fetchMetricas() {
      try {
        const response = await axios.get(`api/overview/produtos`);
        this.metricas = response.data
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },
    formatImei(imei) {
      if (imei > 3) {
        return '...' + imei.slice(-3);
      }
    },
    async loadProdutos() {
      try {
        const response = await axios.get(`api/produto`, {
          params: {status: this.status, tipoProduto: this.tipo}
        });
        this.produtos = response.data;
        this.filteredModelos = this.produtos
      } catch (error) {
        console.error("Erro ao carregar Produtos:", error);
      }
    },
    getDays(produto) {
      if (!produto.dataEntrada || produto.dataEntrada === 0) {
        return null
      }

      let diferencaEmMilissegundos;
      if (produto.status === 'VENDIDO') {
        diferencaEmMilissegundos = produto.dataSaida - produto.dataEntrada
      }
      diferencaEmMilissegundos = new Date().getTime() - produto.dataEntrada
      return Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
    },
    removeFilters() {
      this.status = null
      this.loadProdutos()
    },
    formattedAmount(valor) {
      if (valor == null) {
        valor = 0.00
      }
      let currencyValue = parseFloat(valor);
      return currencyValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    },
    calculeMargem(custo, venda) {
      if (venda == null || venda <= 0 || custo == null || custo <= 0) {
        return null
      }
      return (((parseFloat(venda) - parseFloat(custo)) / parseFloat(venda)) * 100).toFixed(2) + " %";
    },
    lucroLiquido(custo, venda) {
      if (venda == null || venda <= 0 || custo == null || custo <= 0) {
        return null
      }
      return this.formattedAmount(parseFloat(venda) - parseFloat(custo));
    },
    getTooltipContent(produto) {
      if (produto.precoVenda == null || produto.precoVenda <= 0 || produto.precoCusto == null || produto.precoCusto <= 0) {
        return null
      }
      const lucroLiquido = produto.precoVenda - produto.precoCusto;
      const margem = ((produto.precoVenda - produto.precoCusto) / produto.precoVenda) * 100;

      return `Lucro Líquido: ${produto.precoVenda} - ${produto.precoCusto} = ${lucroLiquido.toFixed(2)}
              Margem: ((${produto.precoVenda} - ${produto.precoCusto}) / ${produto.precoVenda}) * 100 = ${margem.toFixed(2)} %`;
    },
    async deleteProduct(id) {
      this.$swal({
        title: "Voce tem certeza?",
        text: "Voce so pode apagar um produto que esteja disponivel",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cencelar",
        confirmButtonText: "Sim, deletar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.delete(`api/produto/${id}`);
            await this.loadProdutos();
          } catch (error) {
            console.error("Erro ao apagar produto:", error);
          }
          this.$swal({
            title: "Deleted!",
            text: "Produto deletado.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        } else if (
            result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    }
  }
};
</script>

<style scoped>

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s linear infinite;
}
</style>
