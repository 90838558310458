<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group mb-3">
          <label for="acessorio">Tipo produto:</label>
          <select v-model="tipoProduto" class="form-control">
            <option value="IPHONE">APARELHO</option>
            <option value="ACESSORIO">ACESSÓRIO</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="acessorio">Fornecedor:</label>
          <Multiselect v-if="fornecedoresOptions.length > 0"
                       v-model="fornecedor"
                       :options="fornecedoresOptions"
                       label="completedName"
                       :multiple="false">
          </Multiselect>
          <span id="icon-addon" class="input-group-text" data-bs-toggle="modal" data-bs-target="#modalExemplo">
              <i class="fa fa-plus"></i>
          </span>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div id="modalExemplo" class="modal fade" tabindex="-1" aria-labelledby="modalTitulo" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="modalTitulo" class="modal-title">Digite o nome do fornecedor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
          </div>
          <div class="modal-body">
            <input v-model="nomeFornecedor" type="text" class="form-control" placeholder="Nome">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-primary" @click.prevent="salvarFornecedor()">Salvar</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Formulário de acessórios -->
    <!-- Se o tipo do produto for ACESSORIO -->
    <div v-if="tipoProduto === 'ACESSORIO'">

      <div class="col-12 row">
        <div class="col-6">
          <label>Modelo:</label>
          <input
              v-model="modelo"
              class="form-control"
          />
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="col-md-12">
          <div>
            <div v-for="(pdt, index) in produtoAcessorioDtos" :key="index" class="parcela">
              <div class="row col-12 align-items-end">
                <div class="col-6">
                  <label>Modelos aplicados</label>
                  <multiselect v-model="pdt.modelosAplicados" :options="$store.state.iphones" :multiple="true"
                               :close-on-select="false"
                               :clear-on-select="false"
                               :preserve-search="true" placeholder="Selecione os modelos" :preselect-first="true">
                  </multiselect>
                </div>
                <div class="col-2">
                  <label>Cor</label>
                  <input v-model="pdt.cor" class="form-control" type="text" placeholder="Cor">
                </div>
                <div class="col-2">
                  <label>Quantidade</label>
                  <input v-model="pdt.quantidade" class="form-control"
                         type="number"
                         placeholder="Quantidade">
                </div>
                <div class="col-2">
                  <button class="btn btn-danger mb-0" @click.prevent="removeMap(index)">Remover</button>
                </div>
              </div>
            </div>
            <button class="btn btn-success mt-2" @click.prevent="addMap()">Adicionar</button>
          </div>
        </div>
      </div>
    </div>


    <form v-if="tipoProduto === 'IPHONE'">
      <div class="form-group">
        <label for="modelo">Modelo:</label>
        <input
            v-model="modelo"
            list="iphone-models"
            class="form-control"
            placeholder="Digite o modelo do iPhone"
        />
        <datalist id="iphone-models">
          <option v-for="iphone in $store.state.iphones" :key="iphone">{{ iphone }}</option>
        </datalist>
      </div>

      <div class="form-group row">
        <div class="col-md-4">
          <div class="mb-3">
            <div class="form-check form-switch p-0">
              <div class="d-flex flex-column-reverse gap-1">
                <input v-model="isVisibleInPriceList" class="form-check-input ms-0" type="checkbox" role="switch"
                       checked/>
                <label class="form-check-label" for="switchCheckLabelTop">Visível no Ecommerce?</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="mb-3">
            <div class="form-check form-switch p-0">
              <div class="d-flex flex-column-reverse gap-1">
                <input id="switchCheckLabelTop" v-model="isNovo" class="form-check-input ms-0" type="checkbox"
                       role="switch"/>
                <label class="form-check-label" for="switchCheckLabelTop">É lacrado (novo) ?</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="imei" class="form-label">IMEI</label>
            <input v-model="imei" type="text" class="form-control" @input.prevent="clearInput()">
          </div>
        </div>

        <div class="col-md-2">
          <div class="mb-3">
            <label class="form-label">Bateria</label>
            <div class="input-group">
              <input v-model="bateria"
                     type="text" class="form-control"
                     @input.prevent="formatBateria">
              <span class="input-group-text">%</span>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="mb-3">
            <label>Grade:</label>
            <input v-model="grade" type="text" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4">
          <label for="cor">Cor:</label>
          <input
              id="cor"
              v-model="cor"
              type="text"
              class="form-control"
              placeholder="Digite a cor"
          />
        </div>

        <!-- Campo Armazenamento -->
        <div class="col-md-4">
          <div class="form-group">
            <label for="armazenamento">Armazenamento:</label>
            <input v-model="armazenamento" list="armazenamentos" class="form-control" @input="checkArmazenamento"/>
            <datalist id="armazenamentos">
              <option v-for="opcao in $store.state.armazenamentos" :key="opcao" :value="opcao">
                {{ opcao }}
              </option>
            </datalist>
            <span v-if="!validArmazenamento" class="text-danger">Selecione um armazenamento válido da lista!</span>
          </div>
        </div>

        <div class="col-md-3">
          <div class="mb-3">
            <label class="form-label">Número de serie</label>
            <input v-model="serie" class="form-control" type="text"/>
          </div>
        </div>

      </div>

      <div class="form-group row">
        <div class="col-6 mb-3">
          <label for="dataEntrada" class="form-label">Data de Entrada</label>
          <input id="dataEntrada" v-model="dataEntrada" type="date" class="form-control">
        </div>
        <div class="col-3 mb-3">
          <label for="garantiaApple" class="form-label">Garantia apple</label>
          <input id="garantiaApple" v-model="garantiaApple" type="date" class="form-control">
        </div>
        <div class="col-3 mb-3">
          <label>Garantia pos venda em meses:</label>
          <select v-model="garantia" class="form-control">
            <option value="1">1</option>
            <option value="3" selected>3</option>
            <option value="6">6</option>
            <option value="8">8</option>
            <option value="12">12</option>
          </select>
        </div>
      </div>

    </form>

    <div class="form-group row">
      <div class="col-md-4 mb-3">
        <label for="precoCusto" class="form-label">Preço de Custo</label>
        <CurrencyInput
            v-model="precoCusto"
            class="form-control"
            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
        />
      </div>

      <div class="col-md-4 mb-3">
        <label for="precoVenda" class="form-label">Preço Mínimo de Venda</label>
        <CurrencyInput
            v-model="precoMinimoVenda"
            class="form-control"
            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
        />
      </div>

      <div class="col-md-4 mb-3">
        <label for="precoVenda" class="form-label">Preço de Venda</label>
        <CurrencyInput
            v-model="precoVenda"
            class="form-control"
            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
        />
      </div>
    </div>

    <div class="mb-3">
      <label for="observacao" class="form-label">Observação</label>
      <textarea id="observacao" v-model="observacao" class="form-control" rows="3"></textarea>
    </div>


    <button :disabled="!checkFields" type="submit" class="btn btn-primary" @click.prevent="salvar">Salvar</button>

  </div>
</template>

<script>
import axios from "axios";
import {currencyMixin} from '@/mixins/currencyMixin.js';

import CurrencyInput from '@/CurrencyInput'
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";


export default {
  components: {Multiselect, CurrencyInput},
  mixins: [currencyMixin],
  data() {
    return {
      fornecedor: {},
      modelo: null,
      modelosAplicadosTransient: null,
      serie: null,
      imei: null,
      cor: null,
      precoCusto: null,
      precoVenda: null,
      observacao: null,
      armazenamento: null,
      bateria: null,
      garantiaApple: null,
      garantia: null,
      precoMinimoVenda: null,
      grade: null,
      isNovo: false,
      isVisibleInPriceList: true,
      produtoAcessorioDtos: [],
      dataEntrada: this.getCurrentDate(),
      isModelValid: true,
      validArmazenamento: true,
      tipoProduto: 'IPHONE',
      modeloAcessorio: null,
      quantidade: 1,
      fornecedoresOptions: [],
      nomeFornecedor: null,
    };
  },
  computed: {
    checkFields() {
      return [
        this.modelo,
        this.precoCusto,
      ].every(field => field !== null);
    },
  },
  created() {
    this.getFornecedores()
  },
  methods: {
    removeMap(index) {
      this.produtoAcessorioDtos.splice(index, 1);
    },
    addMap() {
      this.produtoAcessorioDtos.push({})
    },
    formatBateria() {
      this.bateria = this.bateria.replace(/\D/g, '');
    },
    async getFornecedores() {
      let res = await axios.get(`api/fornecedor`);
      this.fornecedoresOptions = res.data
    },
    async salvarFornecedor() {
      const fornecedorDto = {
        razaoSocial: this.nomeFornecedor
      }
      try {
        await axios.post(`api/fornecedor`, fornecedorDto);
        toast("Fornecedor criado!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao criar fornecedor ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.getFornecedores()
    },
    checkArmazenamento() {
      this.validArmazenamento = this.armazenamentos.includes(this.armazenamento);
    },
    clearInput() {
      this.imei = this.imei.replace(/\D/g, '');
    },
    async salvar() {
      if (!this.checkFields || this.fornecedor.id == null) {
        toast(`Preencha os campos de modelo, custo e fornecedor`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        return
      }

      const produto = {
        modelosAplicadosTransient: this.modelosAplicadosTransient,
        imei: this.imei,
        cor: this.cor,
        serie: this.serie,
        armazenamento: this.armazenamento,
        grade: this.grade,
        isNovo: this.isNovo,
        isVisibleInPriceList: this.isVisibleInPriceList,
        modelo: this.modelo,
        precoCusto: this.precoCusto,
        precoVenda: this.precoVenda,
        precoMinimoVenda: this.precoMinimoVenda,
        observacao: this.observacao,
        garantiaApple: currencyMixin.methods.converterDataParaLong(this.garantiaApple),
        bateria: this.bateria,
        quantidade: this.quantidade,
        dataEntrada: currencyMixin.methods.converterDataParaLong(this.dataEntrada),
        tipoProduto: this.tipoProduto,
        fornecedorId: this.fornecedor.id,
        garantia: this.garantia,
        produtoAcessorioDtos: this.produtoAcessorioDtos
      }

      try {
        let response = await axios.post(`api/produto`, produto);
        let productId = response.data.id;
        await this.$router.push(`/products/${productId}`);

        toast("Produto criado com sucesso!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao criar produto ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }

    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // adicione +1 porque getMonth() começa de 0
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style>
#icon-addon {
  cursor: pointer;
}
</style>
