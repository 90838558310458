<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">{{ title }}</h6>
        <button
            class="mb-0 btn btn-rounded btn-outline-secondary btn-sm d-flex align-items-center justify-content-center ms-auto"
            @click.prevent="showModalTrans = true">Criar transferência entre contas
        </button>
      </div>
      <span class="text-sm text-center align-middle">
                <span class="text-xs font-weight-bold"> Total: {{ formatValue(sumSaldoTotalBancos) }} </span>
              </span>

    </div>
    <div class="p-3 card-body">
      <div class="row">
        <!--        <div class="text-center col-lg-5 col-12">-->
        <!--          <div class="mt-5 chart">-->
        <!--            <canvas :id="id" class="chart-canvas" :height="height"></canvas>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-12">

          <ModalTransacoes
              v-if="showModal"
              :banco="bancoSelected"
              :title="`Transações do banco: ${bancoSelected.nome}`"
              :show-modal="showModal"
              @update:show-modal="showModal = $event"
          >
          </ModalTransacoes>

          <ModalTransferencia
              v-if="showModalTrans"
              :title="`Crie uma transferência entre suas contas`"
              :show-modal="showModalTrans"
              @update="update"
              @update:show-modal="showModalTrans = $event">
          </ModalTransferencia>

          <div class="table-responsive">
            <table class="table mb-0 align-items-center">
              <tbody>
              <tr v-for="banco in bancos" :key="banco.id">

                <td class="no-padding-icon">
                  <font-awesome-icon :icon="faEye" @click.prevent="showModal = true; bancoSelected = banco"/>
                </td>
                <td class="no-padding-next">
                  <div class="d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ banco.nome }}</h6>
                      <p class="mb-0 text-sm">Ag: {{ banco.agencia != 'null' ? banco.agencia : '' }} - CC:
                        {{ banco.conta != 'null' ? banco.conta : '' }} </p>
                      <p class="mb-0 text-sm">{{ banco.razaoSocialOrName != 'null' ? banco.razaoSocialOrName : '' }} -
                        {{ format(banco.cpfOrCnpj) }}</p>
                    </div>
                  </div>
                </td>
                <td class="text-sm text-center align-middle" @click.prevent="bancoEmEdicao = banco.id">
                  <span v-if="bancoEmEdicao !== banco.id"
                        class="text-xs font-weight-bold"> {{ formatValue(banco.valor) }}
                  </span>
                  <div v-else class="row col-12">
                    <div class="col-6">
                      <currency-input
                          v-model="banco.valor"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                      />
                    </div>
                    <div class="col-3">
                      <button class="btn btn-success" @click.prevent="atualizarSaldo(banco)">Atualizar</button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Chart from "chart.js/auto";
import {currencyMixin} from "@/mixins/currencyMixin";
import CurrencyInput from "@/CurrencyInput.vue";

import axios from "axios";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ModalTransacoes from "@/views/ModalTransacoes.vue";
import ModalTransferencia from "@/views/ModalTransferencia.vue";

export default {
  name: "DefaultDoughnutChart",
  components: {ModalTransferencia, ModalTransacoes, FontAwesomeIcon, CurrencyInput},
  props: {
    id: {
      type: String,
      default: "default-doughnut-chart",
    },
    height: {
      type: [String, Number],
      default: "200",
    },
    title: {
      type: String,
      default: "Default Doughnut Chart",
    },
    chart: {
      type: Object,
      required: true,
      labels: String,
      datasets: {
        type: Array,
        label: String,
        data: Array,
      },
    },
    bancos: {
      type: Object,
      required: true,
    },
    actions: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => ({
        color: "secondary",
        label: "See all refferals",
      }),
    },
  },
emits: ['update'],
  data() {
    return {
      show: false,

      bancoEmEdicao: null,
      bancoSelected: null,
      showModal: false,
      showModalTrans: false,
      faEye
    }
  },
  computed: {
    sumSaldoTotalBancos() {
      return this.bancos.reduce((acumulador, objetoAtual) => acumulador + objetoAtual.valor, 0);
    },
  },
  methods: {
    update() {
      this.$emit("update");
    },
    format(cpfOrCnpj) {
      return currencyMixin.methods.formatCPForCNPJ(cpfOrCnpj)
    },
    formatValue(value) {
      return currencyMixin.methods.formattedAmount(value)
    },
    atualizarSaldo(banco) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Atualizar o valor do saldo vai ignorar todas as transações de receita já efetuados.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Sair",
        confirmButtonText: "Atualizar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.put(`api/financeiro/saldo/banco`, {}, {
              params: {
                bancoId: banco.id,
                saldo: banco.valor
              }
            })
          } catch (error) {
            this.$swal({
              title: "Erro!",
              text: "Error ao atualizar saldo.",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
            console.error("Erro ao apagar aluguel:", error);
          }
          this.$swal({
            title: "Atualizado!",
            text: "Saldo Atualizado.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        } else if (
            result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
        console.log('Atualizando o saldo do banco:', banco);
        this.bancoEmEdicao = null;
      });
    }
  },
  // mounted() {
  //   var chart = document.getElementById(this.id).getContext("2d");
  //
  //   var gradientStroke2 = chart.createLinearGradient(0, 230, 0, 50);
  //
  //   gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
  //   gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
  //   gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors
  //
  //   let chartStatus = Chart.getChart(this.id);
  //   if (chartStatus != undefined) {
  //     chartStatus.destroy();
  //   }
  //
  //   new Chart(chart, {
  //     type: "doughnut",
  //     data: {
  //       labels: this.chart.labels,
  //       datasets: [
  //         {
  //           label: this.chart.datasets[0].label,
  //           weight: 9,
  //           cutout: 60,
  //           tension: 0.9,
  //           pointRadius: 2,
  //           borderWidth: 2,
  //           backgroundColor: [
  //             "#2152ff",
  //             "#3A416F",
  //             "#f53939",
  //             "#a8b8d8",
  //             "#cb0c9f",
  //           ],
  //           data: this.chart.datasets[0].data,
  //           fill: false,
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       plugins: {
  //         legend: {
  //           display: false,
  //         },
  //       },
  //       interaction: {
  //         intersect: false,
  //         mode: "index",
  //       },
  //       scales: {
  //         y: {
  //           grid: {
  //             drawBorder: false,
  //             display: false,
  //             drawOnChartArea: false,
  //             drawTicks: false,
  //           },
  //           ticks: {
  //             display: false,
  //           },
  //         },
  //         x: {
  //           grid: {
  //             drawBorder: false,
  //             display: false,
  //             drawOnChartArea: false,
  //             drawTicks: false,
  //           },
  //           ticks: {
  //             display: false,
  //           },
  //         },
  //       },
  //     },
  //   });
  // },
};
</script>

<style scoped>
.no-padding-icon {
  padding-right: 0;
}

.no-padding-next {
  padding-left: 0;
}
</style>
