<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mt-3">Informações sobre produtos</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row col-12">
        <div class="col-10">
          <label>Produto:</label>
          <Multiselect v-model="produto"
                       style="display: block !important;"
                       :options="produtosEstoque"
                       :custom-label="customLabel"
                       track-by="id"
                       :multiple="false"
                       :close-on-select="true"
                       placeholder="Selecione um produto"
                       @select="selectModelo()"
                       @remove="removeSelecionado()">
          </Multiselect>
        </div>
        <div class="col-sm-2">
          <label>Preco:</label>
          <CurrencyInput
              v-model="precoVenda"
              class="multisteps-form__input form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="row col-12">
          <div class="mt-3 col-sm-2 mt-sm-0">
            <label>Quantidade:</label>
           <input v-model="quantidade" class="form-control">
          </div>
          <div class="mt-3 col-sm-6 mt-sm-0">
            <label>Garantia pós venda em meses:</label>
            <select v-model="garantia" class="form-control">
              <option value="1">1</option>
              <option value="3">3</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="12">12</option>
            </select>
          </div>
          <div class="button-row d-flex mt-2">
            <soft-button
                type="button"
                color="light"
                variant="gradient"
                class="js-btn-next"
                title="Next"
                @click.prevent="addProduto"
            >Adicionar Produto
            </soft-button>
          </div>
        </div>
      </div>

<!--      <div class="container mt-2">-->
<!--        <div class="col-12 row">-->
<!--          &lt;!&ndash; Card &ndash;&gt;-->
<!--          <div class="card col-1">-->
<!--            <div class="card-body p-1">-->
<!--              <p class="card-title text-bold">Pelicula premium</p>-->
<!--              <p class="card-text text-sm">12x R$6.33 OU R$ 78.00</p>-->
<!--            </div>-->
<!--            &lt;!&ndash; Full width button at the bottom &ndash;&gt;-->
<!--            <div class="card-footer p-0">-->
<!--              <button class="btn badge bg-success w-100">Adicionar</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="row mt-3">
        <div class="col-sm-12">
          <ul class="list-group">
            <li v-for="(item, index) in itemPedido" :key="index"
                class="list-group-item">
              <div class="row">
                <div class="col-10 mt-3">
                  <tippy :hide-on-click="false" :interactive="true">
                    <i v-if="!item.precoCusto || item.precoCusto === 0"
                       class="fa fa-exclamation-circle blink"
                       style="color: red;">
                    </i>

                    <template #content>
                      O custo do produto está zerado! Preencha com o valor correto para evitar inconsistências no
                      financeiro.
                      <div class="card-body">
                        <p style="color: white">Vá ate a tela do produto clicando
                          <span class="badge btn btn-success" @click.prevent="toUrl(item.produto.id)">aqui</span>
                        </p>
                        <span class=" badge btn btn-success" @click.prevent="atualizarCusto(item)">
                          Já alterou o valor? Clique aqui para atualizar
                        </span>
                      </div>
                    </template>
                  </tippy>


                  <span v-tippy="'Clique para ir na pagina do produto'" class="icon-wrapper"
                        @click.prevent="toUrl(item.produto.id)">
                    <i class="fas fa-arrow-up icon-clickable"></i>
                  </span>
                  {{ getContentProduto(item) }}
                </div>
                <div class="col-1 mt-2">
                  <button class="btn btn-info" @click.prevent="editProduto(item, index)">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-1 mt-2">
                  <button class="btn btn-danger" @click.prevent="removeProduto(item.produto.id, index)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click.prevent="this.$parent.nextStep"
        >Salvar e ir pro proximo
        </soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';

import CurrencyInput from "@/CurrencyInput.vue";
import {Tippy} from "vue-tippy";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";
import {currencyMixin} from "@/mixins/currencyMixin";

export default {
  name: "ProductInfo",
  components: {
    Multiselect,
    Tippy,
    CurrencyInput,
    SoftButton,
  },
  props: {
    pedido: {
      type: [Object, Object],
      required: true
    },
  },
  emits: ['fetch'],
  data() {
    return {
      itemPedido: {},
      produtosEstoque: [],
      precoVenda: 0,
      garantia: 3,
      quantidade: 1,
      produto: null,
    };
  },
  created() {
    this.getProdutos()
    this.itemPedido = this.pedido.itemPedido
  },
  methods: {
    customLabel({modelo, cor, armazenamento, imei, serie, tipoProduto, precoVenda, modelosAplicadosTransient}) {
      let labelParts = [];

      if (tipoProduto === 'ACESSORIO') {
        labelParts.push(modelo || '');
        if (modelosAplicadosTransient) labelParts.push(modelosAplicadosTransient);
        if (cor) labelParts.push(cor);
      } else {
        // Adiciona cada parte se não for null ou undefined
        if (modelo) labelParts.push(modelo);
        if (cor) labelParts.push(cor);
        if (armazenamento) labelParts.push(armazenamento);
        if (imei) labelParts.push(imei);
        if (serie) labelParts.push(serie);
      }

      labelParts.push(currencyMixin.methods.formattedAmount(precoVenda || 0));

      return labelParts.join(' ').trim();
    },
    async toUrl(productId) {
      await this.$router.push(`/products/${productId}`);
    },
    async atualizarCusto(item) {
      const response = await axios.put(`api/pedido/item/${item.id}`);
      const data = response.data;
      this.itemPedido = data.itemPedido
    },
    formattedAmount(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    getContentProduto(item) {
      let contentParts = [];

      if (item.produto.tipoProduto === 'ACESSORIO') {
        contentParts.push(item.quantidade);
        contentParts.push(item.produto.modelo || '');
        contentParts.push(item.produto.modelosAplicadosTransient || '');
        contentParts.push(this.formattedAmount(item.precoVenda || 0));
      } else {
        if (item.produto.modelo) contentParts.push(item.produto.modelo);
        if (item.produto.armazenamento) contentParts.push(`${item.produto.armazenamento}GB`);
        if (item.produto.imei) contentParts.push(`IMEI: ${item.produto.imei}`);

        contentParts.push(this.formattedAmount(item.precoVenda || 0));

        if (item.produto.garantia) contentParts.push(`garantia: ${item.produto.garantia}`);
      }
      if (item.produto.cor) contentParts.push(item.produto.cor);

      return contentParts.join(', ').trim();
    },
    async getProdutos() {
      try {
        const response = await axios.get(`api/produto`, {
          params: {status: "DISPONIVEL"}
        });
        this.produtosEstoque = response.data;
      } catch (error) {
        console.error("Houve um erro ao buscar os dados:", error);
      }
    },
    selectModelo() {
      this.precoVenda = this.produto.precoVenda
      this.garantia = this.produto.garantia == null ? 0 : this.produto.garantia
    },
    async addProduto() {
      if (this.quantidade == null || this.quantidade <= 0) {
        toast(`Quantidade não pode ser menor ou igual a zero`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        return
      }

      if (!this.produto) {
        return;
      }
      const dto = {
        precoVenda: this.precoVenda,
        id: this.produto.id,
        garantia: this.garantia,
        quantidade:  this.produto.tipoProduto === 'IPHONE' ? 1 : this.quantidade,
      }

      try {
        let response = await axios.put(`api/pedido/produto/` + this.pedido.id, dto);
        toast("Produto adicionado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
        this.itemPedido = response.data.itemPedido
        this.removeSelecionado()
        this.$emit("fetch", response.data)
      } catch (e) {
        toast(`Erro ao adicionar produto: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }

    },
    editProduto(item, index) {
      this.produto = item.produto;
      this.precoVenda = item.precoVenda
      this.garantia = item.produto.garantia
      this.itemPedido.splice(index, 1); // Remove o produto da lista para ser editado novamente
    },
    removeSelecionado() {
      this.produto = null
      this.precoVenda = null
      this.garantia = null
    },
    async removeProduto(idProduto, index) {
      try {
        const response = await axios.delete(`api/pedido/produto/${this.pedido.id}/${idProduto}`);

        await this.getProdutos()
        this.$emit("fetch", response.data)
        this.itemPedido.splice(index, 1);

        toast("Produto removido do pedido", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast("Produto removido do pedido", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      }

    },
  }
};
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s linear infinite;
}

/* Estilos para o card dentro do Tippy */
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra ao card */
  border: none !important; /* Remove a borda */
  /* Outras propriedades de estilo do card conforme necessário */
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: green;
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: all 0.3s ease; /* Suaviza a transição de tamanho */
}

.icon-wrapper:hover {
  width: 18px; /* Tamanho maior quando o mouse está sobre o ícone */
  height: 18px;
}

.icon-clickable {
  color: white;
  font-size: 10px;
  transition: font-size 0.3s ease; /* Suaviza a transição de tamanho do ícone */
}

.icon-wrapper:hover .icon-clickable {
  font-size: 12px; /* Tamanho maior do ícone quando o mouse está sobre ele */
}

.multiselect__content-wrapper {
  position: absolute !important;;
  display: block !important;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
</style>
