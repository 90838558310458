<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="col-12 row d-flex align-items-end">
          <div class="form-group col-2">
            <label>Vendedor:</label>
            <select v-model="vendedor" class="form-control">
              <option v-for="funcionario in funcionarios" :key="funcionario.id" :value="funcionario.id">
                {{ funcionario.name }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <button class="btn btn-success" @click.prevent="createPedido">Criar pedido</button>
          </div>
          <div class="col-2">
            <button class="btn btn-danger" @click.prevent="deleteSimulacao">Apagar simulação</button>
          </div>
        </div>

        <div class="row">
          <div class="container m-2">
            <div class="row">
              <!-- Primeiro Card -->
              <div class="card col-md-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h6 class="card-title">Produtos: {{ formattedAmount(totalProdutos) }}</h6>
                      <h6 class="card-title">Trocas: {{ formattedAmount(totalTrocas) }}</h6>
                      <h6 class="card-title">Pagamentos: {{ formattedAmount(totalDinheiro) }}</h6>
                      <h6 class="card-title">Trocas + pagamentos: {{ formattedAmount(totalPago) }}</h6>
                      <h6 class="card-title">Total restante: {{ formattedAmount(restante) }}</h6>
                    </div>
                    <div v-if="role === 'admin'">
                      <h6 class="card-title">Lucro: {{ formattedAmount(calcularLucro) }}</h6>
                      <h6 class="card-title">Margem: {{ isNaN(calcularMargem) ? '0%' : calcularMargem + '%' }}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Segundo Card -->
              <div class="card col-md-9">
                <div class="card-body">
                  <div class="row">
                    <div v-for="(valor, maquininha) in dadosFiltrados" :key="maquininha" class="col-6">
                      <div class="d-flex align-items-center mb-2">
                        <h5 class="mb-0 mr-2">{{ maquininha }}</h5>
                        <button class="badge-style" @click="expandirParcelas(maquininha)">
                          <i :class="mostrarTodasParcelas[maquininha] ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
                          {{ mostrarTodasParcelas[maquininha] ? 'Mostrar menos' : 'Mostrar mais' }}
                        </button>
                      </div>
                      <div v-for="(parcelas, bandeiras) in valor" :key="bandeiras" class="col-12">
                        <h6>{{ bandeiras }}</h6>
                        <div class="row">
                          <ul class="col-12">
                            <li v-for="parcela in parcelas" :key="parcela.parcela" class="col-6">
                              <span>
                             {{ parcela.parcela === '0' ? 'Débito' : parcela.parcela }}, {{ parcela.taxa }}%, Valor: {{ formattedAmount(parcela.valorParcelado) }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--form panels-->
          <div class="row">
            <div class="col-12">
              <form class="mb-1">
                <div class="card container">
                  <div class="card-body">
                    <h5>Produtos:</h5>
                    <div class="mt-3 row">
                      <div class="col-10">
                        <label>Produto:</label>
                        <Multiselect v-model="produto"
                                     :options="produtosEstoque"
                                     :custom-label="customLabel"
                                     track-by="id"
                                     :multiple="false"
                                     :close-on-select="true"
                                     placeholder="Selecione um produto"
                                     @select="selectModelo()"
                                     @remove="removeSelecionado()">
                        </Multiselect>
                      </div>
                      <div class="mt-3 col-sm-2 mt-sm-0">
                        <label>Preco:</label>
                        <CurrencyInput
                            v-model="precoVenda"
                            class="multisteps-form__input form-control"
                            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-12">
                        <ul class="list-group">
                          <li v-for="(produto, index) in produtos" :key="index"
                              class="list-group-item">
                            <div class="row">
                              <div class="col-10 mt-3">
                                {{ getContentProduto(produto) }}
                              </div>
                              <div class="col-1 mt-2">
                                <button class="btn btn-info" @click.prevent="editProduto(produto, index)">
                                  <i class="bi bi-pencil-square"></i>
                                </button>
                              </div>
                              <div class="col-1 mt-2">
                                <button class="btn btn-danger" @click.prevent="removeProduto(index)">
                                  <i class="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row col-12">
                      <div class="mt-3 col-sm-6 mt-sm-0">
                        <label>Garantia pós venda em meses:</label>
                        <select v-model="garantia" class="form-control">
                          <option value="1">1</option>
                          <option value="3">3</option>
                          <option value="6">6</option>
                          <option value="8">8</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div class="button-row d-flex mt-2">
                        <soft-button
                            type="button"
                            color="light"
                            variant="gradient"
                            class="js-btn-next"
                            title="Next"
                            @click.prevent="addProduto"
                        >Adicionar Produto
                        </soft-button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="card container">
                <div class="card-body">
                  <h5 class="card-title">Trocas: </h5>
                  <ul v-if="produtosTroca.length" class="list-group">
                    <li v-for="(produto, index) in produtosTroca" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">

                      <div class="d-flex align-items-center">
                        <div v-if="produto" class="m-lg-2">
                          Modelo: {{ produto.modelo }} {{ produto.cor }} {{ produto.armazenamento }}, Custo:
                          R$ {{ produto.precoCusto }}, Venda: R$ {{ produto.precoVenda }}
                        </div>
                      </div>

                      <!-------MODAL--------->
                      <ModalProduto v-if="isModalVisible" title="Altere o produto"
                                    :produto-dto="selectedProduto"
                                    :show-modal="isModalVisible"
                                    @update:showModal="isModalVisible = $event"
                                    @update="update(produto, $event)">
                      </ModalProduto>

                      <div>
                        <button v-tippy="'Editar esse produto'"
                                class="btn btn-primary m-auto"
                                @click="showModal(produto)">
                          <i class="fa fa-edit" aria-hidden="true"></i>
                          <!-- Outro ícone comumente usado para editar -->
                        </button>
                        <button v-tippy="'Apagar esse produto'" type="button"
                                class="btn btn-danger m-auto m-sm-1"
                                @click="removeProdutoTroca(index)">
                          <i class="fa fa-trash" aria-hidden="true"></i> <!-- Ícone de lixeira -->
                        </button>
                      </div>
                    </li>
                  </ul>
                  <div v-else>
                    Sem trocas
                  </div>
                  <div>
                    <button type="button"
                            class="btn btn-primary col-3 mt-3"
                            @click="addProdutoTroca()">Adicionar troca
                    </button>
                  </div>
                </div>

                <ModalProduto v-if="isModalVisible" title="Altere o produto"
                              :produto-dto="selectedProduto"
                              :show-modal="isModalVisible"
                              @update:showModal="isModalVisible = $event"
                              @update="update(produto, $event)">
                </ModalProduto>

              </div>
              <div class="mt-1 card container">
                <div class=" card-body">
                  <div class="row">

                    <div class="col-2">
                      <label>Método de Pagamento:</label>
                      <select v-model="selectedPaymentMethod" class="form-control">
                        <option value="cartao">Cartão</option>
                        <option value="dinheiro">Dinheiro</option>
                        <option value="pix">Pix</option>
                      </select>
                    </div>

                    <div v-if="selectedPaymentMethod === 'cartao'" class="col-2">
                      <label>Juros por parte do:</label>
                      <select v-model="parteJuros" class="form-control">
                        <option selected value="VENDEDOR">Vendedor (Venda com juros incluso)</option>
                        <option value="COMPRADOR">Comprador (Venda sem juros incluso)</option>
                      </select>
                    </div>

                    <!-- Select para Maquininhas -->
                    <div v-if="selectedPaymentMethod === 'cartao'" class="col-3">
                      <label>Selecione a maquininha:</label>
                      <select v-model="maquininhaSelecionada" class="form-control">
                        <option disabled value="">Selecione uma Maquininha</option>
                        <option v-for="opcao in opcoesMaquininha" :key="opcao.id" :value="opcao.id">
                          {{ opcao.nome }} - {{ opcao.nomeBanco }}
                        </option>
                      </select>
                    </div>

                    <!-- Select para Bandeiras -->
                    <div v-if="selectedPaymentMethod === 'cartao' && bandeiras.length > 0" class="col-2">
                      <label>Selecione a bandeira:</label>
                      <select v-model="bandeiraSelecionada" class="form-control">
                        <option disabled value="">Selecione uma Bandeira</option>
                        <option v-for="bandeira in bandeiras" :key="bandeira" :value="bandeira">
                          {{ bandeira }}
                        </option>
                      </select>
                    </div>

                    <!-- Select para Parcelas e Taxas -->
                    <div v-if="selectedPaymentMethod === 'cartao' && parcelasTaxas.length > 0" class="col-3">
                      <label>Selecione a quantidade de parcelas:</label>
                      <select v-model="parcelaTaxaSelecionada" class="form-control">
                        <option disabled value="">Selecione Parcela e Taxa</option>
                        <option v-for="parcelaTaxa in parcelasTaxas" :key="parcelaTaxa.parcela" :value="parcelaTaxa">
                          {{ parcelaTaxa.parcela === '0' ? 'Débito' : parcelaTaxa.parcela }} {{ parcelaTaxa.parcela === '0' ? '' : 'parcelas'}} - Taxa: {{ parcelaTaxa.taxa }}%
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <label>Valor:</label>
                      <CurrencyInput
                          v-model="inputValue"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                      />
                      <p v-if="parcelaTaxaSelecionada">Valor Líquido: {{
                          formattedAmount(calcularValorLiquido())
                        }}</p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-sm-12">
                      <ul class="list-group">
                        <li v-for="(transacao, index) in transacoes" :key="index"
                            class="list-group-item d-flex flex-column align-items-start">

                          <div class="row col-12">
                            <div class="col-11 d-flex flex-column justify-content-center">
                              <div class="mb-2">
                                <span class="font-weight-bold">{{ formattedAmount(transacao.valor) }}</span> -
                                <span :class="{'text-primary': transacao.metodoPagamento === 'CARTAO',
                              'text-success': transacao.metodoPagamento === 'PIX'}">
                                {{ transacao.metodoPagamento }}
                             </span>
                              </div>

                              <div v-if="transacao.metodoPagamento === 'cartao'" class="mb-2">
                                {{ formatarTransacao(transacao) }}
                              </div>
                            </div>

                            <div class="col-1 d-flex align-items-center align-content-end">
                              <button class="btn btn-danger" @click.prevent="removeTransacao(transacao.id)">
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div class="m-1 row col-3">
                    <soft-button
                        type="button"
                        color="dark"
                        variant="gradient"
                        class="mb-0 ms-auto js-btn-next"
                        title="Next"
                        @click.prevent="addPagamento()"
                    >Adicionar pagamento
                    </soft-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";

import CurrencyInput from "@/CurrencyInput.vue";
import Multiselect from "vue-multiselect";
import {currencyMixin} from "@/mixins/currencyMixin";
import ModalProduto from "@/views/ModalProduto.vue";
import {mapState} from "vuex";

export default {
  name: "Simulacao",
  components: {ModalProduto, Multiselect, CurrencyInput, SoftButton},
  data() {
    return {
      showMenu: false,
      isModalVisible: false,
      selectedProduto: null,
      vendedor: 1,
      pedido: {},
      produtos: [],
      produtosEstoque: [],
      produto: null,
      precoVenda: 0,
      filteredModelos: [],
      produtosTroca: [],
      funcionarios: [],
      garantia: null,

      installments: 1,
      selectedPaymentMethod: 'cartao',
      inputValue: 0,
      transacoes: [],
      maquininhas: [],
      maquininhaSelecionada: null,
      bandeiraSelecionada: null,
      parcelaTaxaSelecionada: null,
      parteJuros: null,
      opcoesMaquininha: [],
      bandeiras: [],
      parcelasTaxas: [],
      mostrarTodasParcelas: {}
    };
  },
  computed: {
    ...mapState(['role']),
    calcularLucro() {
      return this.produtos.reduce((total, produto) => {
        const lucro = produto.precoVenda - produto.precoCusto;
        return total + lucro;
      }, 0);
    },
    calcularMargem() {
      let totalVendas = 0;
      let totalLucro = 0;

      this.produtos.forEach(produto => {
        totalVendas += produto.precoVenda;
        totalLucro += produto.precoVenda - produto.precoCusto;
      });

      const margemLiquidaTotal = (totalLucro / totalVendas) * 100;
      return margemLiquidaTotal.toFixed(0);
    },
    totalPago() {
      let reduce = this.transacoes.reduce((acc, transacao) => acc + transacao.valor, 0);
      if (this.produtosTroca.length) {
        return reduce + this.produtosTroca.reduce((total, produto) => {
          return produto.precoCusto
        }, 0);
      }
      return reduce
    },
    totalDinheiro() {
      return this.transacoes.reduce((acc, transacao) => acc + transacao.valor, 0)
    },
    totalTrocas() {
      return this.produtosTroca.reduce((total, produto) => {
        return produto.precoCusto
      }, 0);
    },
    totalProdutos() {
      return this.produtos.reduce((total, produto) => {
        return produto.precoVenda
      }, 0);
    },
    restante() {
      return this.totalProdutos - this.totalPago
    },
    processarDados() {
      let agrupados = {};

      this.maquininhas.forEach(item => {
        if (!agrupados[item.nome]) {
          agrupados[item.nome] = {};
        }

        item.bandeiraParcelaTaxa.forEach(bpt => {
          let chaveBandeiras = bpt.bandeiras.join(", ");

          if (!agrupados[item.nome][chaveBandeiras]) {
            agrupados[item.nome][chaveBandeiras] = [];
          }

          bpt.parcelasTaxas.forEach(parcelaTaxa => {
            let parcelaExistente = agrupados[item.nome][chaveBandeiras].find(parcela =>
                parcela.parcela === parcelaTaxa.parcela
            );

            if (!parcelaExistente) {
              let valorParcelado = this.calcularValorParcelado(this.restante, parcelaTaxa.taxa, parcelaTaxa.parcela);
              agrupados[item.nome][chaveBandeiras].push({
                parcela: parcelaTaxa.parcela,
                taxa: parcelaTaxa.taxa,
                valorParcelado: valorParcelado
              });
            }
          });
        });
      });

      return agrupados;
    },
    dadosFiltrados() {
      let dadosFiltrados = JSON.parse(JSON.stringify(this.processarDados)); // Deep clone

      for (let maquininha in dadosFiltrados) {
        for (let bandeiras in dadosFiltrados[maquininha]) {
          if (!this.mostrarTodasParcelas[maquininha]) {
            dadosFiltrados[maquininha][bandeiras] = dadosFiltrados[maquininha][bandeiras].filter(parcela =>
                parcela.parcela === 1 || parcela.parcela === 6 || parcela.parcela === 12
            );
          }
        }
      }

      return dadosFiltrados;
    }
  },
  watch: {
    maquininhaSelecionada(newVal) {
      this.bandeiraSelecionada = null;
      this.parcelaTaxaSelecionada = null;

      const maquininha = this.maquininhas.find(m => m.id === newVal);
      if (maquininha?.bandeiraParcelaTaxa) {
        this.bandeiras = maquininha.bandeiraParcelaTaxa.map(bpt => bpt.bandeiras).flat();
      } else {
        this.bandeiras = [];
      }
    },
    bandeiraSelecionada(newVal) {
      this.parcelaTaxaSelecionada = null;

      const maquininha = this.maquininhas.find(m => m.id === this.maquininhaSelecionada);
      if (maquininha?.bandeiraParcelaTaxa) {
        const bandeiraParcela = maquininha.bandeiraParcelaTaxa.find(bpt => bpt.bandeiras.includes(newVal));
        this.parcelasTaxas = bandeiraParcela ? bandeiraParcela.parcelasTaxas : [];
      } else {
        this.parcelasTaxas = [];
      }
    },
    selectedPaymentMethod() {
      this.parcelaTaxaSelecionada = null;
    }
  },
  created() {
    this.getMaquinasCartao()
  },
  mounted() {
    this.getProdutos()
    this.getVendedores()
    this.$store.state.isAbsolute = true;
    setNavPills();

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    expandirParcelas(maquininha) {
      this.mostrarTodasParcelas[maquininha] = !this.mostrarTodasParcelas[maquininha];
    },
    calcularValorLiquido() {
      if (!this.parcelaTaxaSelecionada && !this.parteJuros || this.parteJuros !== 'VENDEDOR') {
        return this.inputValue.toFixed(2);
      }
      const valorTaxa = this.inputValue * (this.parcelaTaxaSelecionada.taxa / 100); // Calcula o valor da taxa
      const valorLiquido = this.inputValue - valorTaxa; // Subtrai o valor da taxa do valor original
      return valorLiquido.toFixed(2); // Retorna o valor líquido com duas casas decimais
    },
    removeTransacao(index) {
      this.transacoes.splice(index, 1)
    },
    formatarTransacao(transacao) {
      if (!transacao?.bandeira) {
        return '';
      }
      return `${transacao.bandeira}, Juros: ${transacao.jurosPorParte}, Parcelas: ${transacao.parcela === 0 ? 'Débito' : transacao.parcela}, ${transacao.taxa}%`;
    },
    async getMaquinasCartao() {
      const response = await axios.get(`api/financeiro/maquinas/cartao`);
      this.maquininhas = response.data
      this.opcoesMaquininha = response.data.map(m => ({
        id: m.id,
        nome: m.nome,
        nomeBanco: m.banco ? m.banco.razaoSocialOrName : 'Sem Razao Social'
      }));
    },
    calcularValorParcelado(valor, taxa, parcelas) {
      let valorFinal = valor + (valor * (taxa / 100));
      return valorFinal / parcelas;
    },
    async addPagamento() {
      if (!this.inputValue || !this.selectedPaymentMethod) {
        return
      }
      this.isLoading = true
      const dto = {
        valor: this.inputValue,
        metodoPagamento: this.selectedPaymentMethod,
      }

      if (this.selectedPaymentMethod === 'cartao') {
        dto.jurosPorParte = this.parteJuros
        dto.taxa = this.parcelaTaxaSelecionada.taxa
        dto.parcela = this.parcelaTaxaSelecionada.parcela
        dto.bandeira = this.bandeiraSelecionada
        dto.valorBruto = this.inputValue
        dto.valor = Number(this.calcularValorLiquido())
      }

      this.transacoes.push({...dto})
    },
    showModal(produto) {
      this.selectedProduto = produto;
      this.isModalVisible = true;
    },
    update(oldProduto, newProduto) {
      oldProduto = newProduto;
    },
    customLabel({modelo, cor, armazenamento, imei, tipoProduto, precoVenda}) {
      let labelParts = [];

      if (tipoProduto === 'ACESSORIO') {
        labelParts.push(modelo || '');
      } else {
        // Adiciona cada parte se não for null ou undefined
        if (modelo) labelParts.push(modelo);
        if (cor) labelParts.push(cor);
        if (armazenamento) labelParts.push(armazenamento);
        if (imei) labelParts.push(imei);
      }

      labelParts.push(currencyMixin.methods.formattedAmount(precoVenda || 0));

      return labelParts.join(' ').trim();
    },
    formattedAmount(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    async getVendedores() {
      try {
        const response = await axios.get(`api/funcionario`);
        this.funcionarios = response.data
        const funcionarioId = Number(localStorage.getItem("funcionarioId"));
        const vendedor = this.funcionarios.find(f => f.id === funcionarioId);
        this.vendedor = vendedor.id;
      } catch (error) {
        console.error("Houve um erro ao buscar os dados: ", error);
      }
    },
    getContentProduto(item) {
      let contentParts = [];

      if (item.tipoProduto === 'ACESSORIO') {
        contentParts.push(item.modelo || '');
        contentParts.push(`R$ ${item.precoVenda}`);
      } else {
        if (item.modelo) contentParts.push(item.modelo);
        if (item.cor) contentParts.push(item.cor);
        if (item.armazenamento) contentParts.push(`${item.armazenamento}GB`);
        if (item.imei) contentParts.push(`IMEI: ${item.imei}`);

        contentParts.push(this.formattedAmount(item.precoVenda || 0));

        if (item.garantia) contentParts.push(`garantia: ${item.garantia}`);
      }

      return contentParts.join(', ').trim();
    },
    async getProdutos() {
      try {
        const response = await axios.get(`api/produto`, {
          params: {status: "DISPONIVEL"}
        });
        this.produtosEstoque = response.data;
      } catch (error) {
        console.error("Houve um erro ao buscar os dados:", error);
      }
    },
    editProduto(item, index) {
      this.produto = item;
      this.precoVenda = item.precoVenda
      this.garantia = item.garantia == null ? 0 : item.garantia
      this.produtos.splice(index, 1);
    },
    removeSelecionado() {
      this.produto = null
      this.precoVenda = null
      this.garantia = null
    },
    selectModelo() {
      this.precoVenda = this.produto?.precoVenda
      this.garantia = this.produto.garantia == null ? 0 : this.produto.garantia
    },
    async addProduto() {
      if (!this.produto) {
        this.$swal({
          title: "Erro ao salvar!",
          text: `Prencha todos os campos antes de salvar!`,
          icon: "error",
        });
        return;
      }
      this.produto.precoVenda = this.precoVenda
      this.produto.garantia = this.garantia

      this.produtos.push({...this.produto});
      this.produto = null
      this.precoVenda = null
      this.garantia = null
    },
    deleteSimulacao() {
      this.produtos = []
      this.produtosTroca = []
      this.transacoes = []
    },
    async createPedido() {
      if (!this.produtos?.length) {
        this.$swal({
          title: "Erro ao salvar!",
          text: `Preencha o campo de produtos antes de criar o pedido!`,
          icon: "error",
        });
        return;
      }
      const dto = {
        produtos: this.produtos,
        vendedorId: this.vendedor,
        trocas: this.produtosTroca
      }
      const response = await axios.post(`api/pedido/pre-completed`, dto);
      await this.$router.push(`/ecommerce/pedido/${response.data.id}`);
    },
    removeProduto(index) {
      this.produtos.splice(index, 1);
    },
    removeProdutoTroca(index) {
      this.produtosTroca.splice(index, 1);
    },
    addProdutoTroca() {
      this.produtosTroca.push({precoCusto: 0})
    }
  },
};
</script>

<style scoped>
.badge-style {
  background: none;
  border: none;
  color: #007bff; /* Cor do texto */
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

.badge-style i {
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.badge-style:hover i {
  transform: scale(1.2);
}
</style>
