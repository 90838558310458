<template>
  <div class="container-fluid">
    <div class="mt-3 container-fluid">
      <div class="form-group col-12">
        <div class="card">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link " :class="{ 'active': abaAtiva === 'pedido' }"
                 @click="abaAtiva = 'pedido'">Pedido</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " :class="{ 'active': abaAtiva === 'historico' }"
                 @click="abaAtiva = 'historico'">Histórico</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " :class="{ 'active': abaAtiva === 'metricas' }"
                 @click="fetchMetricas(); abaAtiva = 'metricas'">Métricas</a>
            </li>
          </ul>

          <div class="card-body">
            <div class="row">

              <div class="form-group col-2">
                <label>Vendedor:</label>
                <select v-model="pedido.vendedor.completedName" class="form-control" @change="update">
                  <option v-for="funcionario in funcionarios" :key="funcionario.id" :value="funcionario.completedName">
                    {{ funcionario.completedName }}
                  </option>
                </select>
              </div>

              <div class="col-2">
                <label class="text-uppercase">Status:</label>
                <span v-if="!isEditable" class="form-control text-uppercase rounded-pill"
                      :class="pedido.status === 'FINALIZADO' ? 'badge badge-success' : 'badge badge-info'"
                      @mouseover="isEditable = true">
                  {{ pedido.status }}
                </span>
                <select v-else v-model="pedido.status" class="form-control rounded-pill"
                        :class="pedido.status === 'FINALIZADO' ? 'badge badge-success' : 'badge badge-info'"
                        @mouseleave="isEditable = false"
                        @change="changeStatus">
                  <option class="badge badge-info" value="ABERTO">ABERTO</option>
                  <option class="badge badge-success" value="FINALIZADO">FINALIZADO</option>
                  <option class="badge badge-danger" value="CANCELADO">CANCELADO</option>
                </select>
              </div>

              <div class="col-3">
                <label class="text-uppercase">Origem da venda:</label>
                <select v-model="pedido.origem" class="form-control"
                        @change="updateOrigem">
                  <option value="INDICACAO">Indicação</option>
                  <option value="ANUNCIO_WHATSAPP">Anúncios / WhatsApp</option>
                  <option value="ANUNCIO_INSTAGRAM">Anúncios / Instagram</option>
                  <option value="ORGANICO">Orgânico</option>
                  <option value="GOOGLE">Google</option>
                  <option value="YOUTUBE">Youtube</option>
                </select>
                <i v-if="showSpanOrigem" class="fa fa-exclamation-triangle">
                  <span class="text-sm" style="color: red"> Indique a origem da venda</span>
                </i>
              </div>

              <div class="col-2">
                <label class="text-uppercase">Mensagem Inicial:</label>
                <input v-model="pedido.mensagemInicial" class="form-control" @change="updateOrigem"/>
                <i v-if="showSpanOrigem" class="fa fa-exclamation-triangle">
                  <span class="text-sm" style="color: red"> Indique a origem da venda</span>
                </i>
              </div>

              <celular-modal v-if="showModalCelularPedido"
                             :show-modal="showModalCelularPedido"
                             :cellphone="pedido.cliente.cellphone"
                             :client-name="pedido.cliente.nome"
                             @update:show-modal="showModalCelularPedido = $event"
                             @update="sendWhatsApp($event, 'pedido')">
              </celular-modal>

              <celular-modal v-if="showModalCelular"
                             :show-modal="showModalCelular"
                             :cellphone="pedido.cliente.cellphone"
                             :client-name="pedido.cliente.nome"
                             @update:show-modal="showModalCelular = $event"
                             @update="sendWhatsApp($event, 'garantia')">
              </celular-modal>


              <div class="col-3 m-auto">
                <div class="d-flex flex-column align-items-end" style="position: relative; align-self: flex-end;">

                  <div class="dropdown">
                    <button id="dropdownMenuButton" class="btn btn-primary mb-2" @click.prevent="toggleAllDropdowns" >
                      <i class="fas fa-bars"></i>
                    </button>

                    <transition name="fade-slide">
                      <div v-show="showAllDropdowns" :class="{'visible': showAllDropdowns}"
                           class="card-body show-dropdowns"
                           style="position: absolute; top: 100%; right: 0; width: auto; z-index: 1050;">
                        <!-- Dropdown Fiscal -->
                        <div class="dropdown" aria-labelledby="dropdownMenuButton">
                          <button class="btn  badge badge-success rounded-pill dropdown-toggle" type="button"
                                  :class="(!pedido.itemPedido || !pedido.itemPedido.length || !pedido.cliente) ? 'disabled' : ''"
                                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Fiscal
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#"
                               :class="{'disabled': !pedido.cliente || !pedido.itemPedido || !pedido.itemPedido.length}"
                               @click.prevent="!pedido.cliente || !pedido.itemPedido || !pedido.itemPedido.length ? null : gerarNfe(pedido.id)">
                              Gerar Nota fiscal da venda
                            </a>
                            <a class="dropdown-item"
                               :class="{'disabled': !pedido.itemTroca || !pedido.cliente || !pedido.itemTroca.length}"
                               href="#"
                               @click.prevent="gerarNfeTroca(pedido.id)">
                              Gerar Nota fiscal dos produtos de troca
                            </a>
                          </div>
                        </div>
                        <!-- Badge para Gerar PDF -->
                        <div>
                          <div class="dropdown">
                            <button id="dropdownMenuButton"
                                    class="btn badge badge-success rounded-pill dropdown-toggle" type="button"
                                    :class="(!pedido.itemPedido || !pedido.itemPedido.length || !pedido.cliente) ? 'disabled'  : ''"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Ordem de venda
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" href="#" @click.prevent="getPdf(pedido.id, 'pedido')">
                                Imprimir
                              </a>
                              <a class="dropdown-item" href="#" @click.prevent="showModalCelularPedido = true">
                                Enviar para o cliente pelo WhatsApp
                              </a>
                            </div>
                          </div>
                        </div>
                        <!-- Badge para Gerar PDF -->
                        <div>
                          <div class="dropdown mb-0">
                            <button id="dropdownMenuButton"
                                    class="btn badge badge-success rounded-pill dropdown-toggle" type="button"
                                    :class="(!pedido.itemPedido || !pedido.itemPedido.length || !pedido.cliente) ? 'disabled'  : ''"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Termo de garantia
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" href="#" @click.prevent="getPdf(pedido.id, 'garantia')">
                                Imprimir
                              </a>
                              <a class="dropdown-item" href="#" @click.prevent="showModalCelular = true">
                                Enviar para o cliente pelo WhatsApp
                              </a>
                            </div>
                          </div>
                        </div>
                        <!-- Badge para Gerar PDF de Troca -->
                        <div>
                  <span
                      :class="{'mb-3': true, 'btn': true, 'badge': true, 'badge-success': true, 'rounded-pill': true, 'disabled': !pedido.itemPedido || !pedido.cliente || !pedido.itemTroca || !pedido.itemTroca.length}"
                      @click="!pedido.itemPedido || !pedido.cliente || !pedido.itemTroca.length ? null : getPdfTroca(pedido.id)">
                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Recibo de troca
                  </span>
                        </div>
                        <!-- Badge para Overview -->
                        <div>
                  <span
                      :class="{'mb-0': true, 'btn': true, 'badge': true, 'badge-info': true, 'rounded-pill': true, 'disabled': !pedido.itemPedido || !pedido.cliente}"
                      @click="!pedido.itemPedido || !pedido.cliente ? null : showModal = true">
                    <i class="fa fa-eye" aria-hidden="true"></i> Overview
                  </span>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>


              <!--     MODAL OVERVIEW           -->
              <PedidoOverviewModal v-if="showModal" :show-modal="showModal" :pedido="pedido"
                                   @update:show-modal="showModal = $event"></PedidoOverviewModal>

            </div>
            <div class="row">
              <div class="col-2">
                <label class="text-uppercase">Data da venda:</label>
                <input v-model="dataCriacao" class="form-control" type="date" @change="updateOrigem"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content container-fluid">
      <div v-if="!loading && abaAtiva === 'pedido'"
           :class="{ show: abaAtiva === 'pedido', active: abaAtiva === 'pedido' }"
           class="tab-pane fade">
        <div class="row">
          <div class="form-group col-6">
            <h3>Valor total do pedido: {{ formattedAmount(pedido.financeiro.valorTotal) }}</h3>
          </div>

          <div class="multisteps-form">
            <div class="row">
              <div class="mx-auto col-12 col-lg-12 mb-sm-5">
                <div class="multisteps-form__progress">
                  <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      title="Produtos"
                      :class="activeStep >= 0 ? activeClass : ''"
                  >
                    <span>1. Produto</span>
                  </button>
                  <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Pricing"
                      :class="activeStep >= 1 ? activeClass : ''"
                  >
                    2. Cliente
                  </button>
                  <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Troca"
                      :class="activeStep >= 2 ? activeClass : ''"
                  >
                    3. Troca
                  </button>
                  <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Pricing"
                      :class="activeStep >= 3 ? activeClass : ''"
                  >
                    4. Financeiro
                  </button>
                </div>
              </div>
            </div>

            <!--form panels-->
            <div class="row">
              <div class="m-auto col-12 col-lg-12">
                <form class="mb-2 multisteps-form__form ">
                  <div class="card">
                    <div class="row card-body">
                      <div class="col-4">
                        <label>Desconto</label>
                        <CurrencyInput
                            v-model="pedido.desconto"
                            class="form-control"
                            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                            @update="update"
                        />
                      </div>
                      <div class="col-4">
                        <label>Frete</label>
                        <CurrencyInput
                            v-model="pedido.frete"
                            class="form-control"
                            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                            @update="update"
                        />
                      </div>
                    </div>
                  </div>
                  <product-info :pedido="pedido" :class="activeStep === 0 ? activeClass : ''" @fetch="fetchPedido"/>
                  <cliente :pedido="pedido" :class="activeStep === 1 ? activeClass : ''"
                           @fetch-cliente="fetchPedidoCliente"/>
                  <new-produto-troca :pedido="pedido" :class="activeStep === 2 ? activeClass : ''"
                                     @fetch="fetchPedido"/>
                  <pricing :pedido="pedido" :class="activeStep === 3 ? activeClass : ''" @fetch="fetchPedido"/>
                </form>
              </div>
            </div>

            <div class="mt-3">
              <label class="form-label">Observação</label>
              <textarea v-model="pedido.observacao" class="form-control" rows="4" @change="updateOrigem"></textarea>
            </div>
          </div>
        </div>
      </div>

      <!--   ABA HISTORICO   -->
      <div v-if="abaAtiva === 'historico'" class="row gx-4 tab-pane fade"
           :class="{ show: abaAtiva === 'historico', active: abaAtiva === 'historico' }">
        <div class="mt-4 col-lg-6 mt-lg-0">
          <timeline-list title="Histórico">
            <timeline-item
                v-for="evento in pedido.historico" :key="evento.id"
                :color=getColor(evento.description)
                :icon=getIcon(evento.description)
                :title=evento.description
                :date-time=formatDateP(evento.date)
                :description="'Usuario: '+ evento.funcionario.completedName"
                :badges=getBadge(evento.description)
            />
          </timeline-list>
        </div>
      </div>

      <!--   ABA METRICAS   -->
      <div v-if="abaAtiva === 'metricas'" class="row gx-4 tab-pane fade"
           :class="{ show: abaAtiva === 'metricas', active: abaAtiva === 'metricas' }">
        <div class="mt-4 col-lg-12">
          <div v-if="!loadingMetricas" class="card">
            <div v-if="pedido.vendedor.id" class="card-body">
              <div class="row col-12">
                <div v-if="role === 'admin'" class="mt-4 col-sm-2 mt-sm-0">
                  <div class="card border">
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Lucro</h6>
                        <p class="card-text">{{ formattedAmount(metricas.lucro) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="role === 'admin'" class="mt-4 col-sm-3 mt-sm-0">
                  <div class="card border">
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Lucro descontado comissao</h6>
                        <p class="card-text">{{ formattedAmount(metricas.lucroBrutoSemComissao) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="role === 'admin'" class="mt-4 col-sm-3 mt-sm-0">
                  <div class="card border">
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Margem bruta</h6>
                        <p class="card-text">% {{ metricas.margemBruta }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="role === 'admin'" class="mt-4 col-sm-4 mt-sm-0">
                  <div class="card border">
                    <div class="card">
                      <div class="card-body">
                        <h6 class="card-title">Margem bruta descontado comissao</h6>
                        <p class="card-text">% {{ metricas.margemBrutaSemComissao }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-12 mt-3">
                <div v-if="role === 'admin' || Number(funcionarioId) === pedido.vendedor.id"
                     class="mt-4 col-sm-4 mt-sm-0">

                  <div class="card border">
                    <div class="card small">
                      <div class="card-body">
                        <div class="card-title row col-12">
                          <h6>Comissão aproximada <i class="fa fa-smile-o" aria-hidden="true"></i></h6>
                        </div>
                        <p class="card-text">{{ formattedAmount(metricas.comissaoAproximada.comissaoTotal) }}</p>
                        <p class="small text-muted">(Apenas você e o supervisor podem ver sua própria comissão)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card col-lg-12">
            <div class="loader card-body align-center align-items-center"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import ProductInfo from "@/views/ecommerce/products/components/ProductInfo.vue";
import Cliente from "@/views/pages/Users/components/Cliente.vue";
import Pricing from "@/views/ecommerce/products/components/Pricing.vue";
import NewProdutoTroca from "@/views/ecommerce/products/components/NewProdutoTroca.vue";

import PedidoOverviewModal from "@/PedidoOverviewModal.vue";
import {mapState} from "vuex";
import TimelineList from "@/views/pages/projects/components/TimelineList.vue";
import TimelineItem from "@/views/pages/projects/components/TimelineItem.vue";
import {DateTime} from "luxon";
import CurrencyInput from "@/CurrencyInput.vue";
import {toast} from "vue3-toastify";
import {currencyMixin} from "@/mixins/currencyMixin";
import CelularModal from "@/CelularModal.vue";

export default {
  name: "PedidoId",
  components: {
    CelularModal,
    CurrencyInput,
    TimelineItem, TimelineList, PedidoOverviewModal, NewProdutoTroca, Pricing, Cliente, ProductInfo
  },
  data() {
    return {
      showModalCelular: false,
      showModalCelularPedido: false,
      showAllDropdowns: false,
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 4,
      pedido: {},
      metricas: {},
      loading: false,
      isEditable: false,

      showSpanOrigem: false,
      showModal: false,
      abaAtiva: 'pedido',
      loadingMetricas: false,
      funcionarios: [],
      statusOriginal: null,
      dataCriacao: null
    };
  },
  computed: {
    ...mapState(['role', 'funcionarioId']),
  },
  created() {
    this.fetchPedido()
    this.getVendedores()
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },

  methods: {
    toggleAllDropdowns() {
      this.showAllDropdowns = !this.showAllDropdowns;
    },
    async getVendedores() {
      try {
        const response = await axios.get(`api/funcionario`);
        this.funcionarios = response.data
      } catch (error) {
        console.error("Houve um erro ao buscar os dados: ", error);
      }
    },
    async gerarNfeTroca() {
      try {
        const response = await axios.post(`api/fiscal/${this.pedido.id}/nfe/troca`, {});
        await this.$router.push(`/fiscal/${response.data.id}`);
        toast(`NFe criada!`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        if (e.response?.data) {
          try {
            const erroData = e.response.data;
            console.log(erroData.erros[0]);
            toast(`${erroData.erros[0].mensagem}`, {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              transition: "flip",
              theme: "dark",
              type: "error"
            })
          } catch (erroDeAnalise) {
            console.error('Erro ao analisar a mensagem de erro:', e.response.data);
          }
        } else {
          toast(`Erro ao emitir NFe`, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark",
            type: "error"
          })
        }
      }
    },
    async gerarNfe() {
      try {
        const response = await axios.post(`api/fiscal/${this.pedido.id}/nfe`, {});
        await this.$router.push(`/fiscal/${response.data.id}`);
        toast(`NFe criada!`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        if (e.response?.data) {
          try {
            const erroData = e.response.data;
            console.log(erroData.erros[0]);
            toast(`${erroData.erros[0].mensagem}`, {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              transition: "flip",
              theme: "dark",
              type: "error"
            })
          } catch (erroDeAnalise) {
            console.error('Erro ao analisar a mensagem de erro:', e.response.data);
          }
        } else {
          toast(`Erro ao emitir NFe`, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark",
            type: "error"
          })
        }
      }
    },
    async sendWhatsApp({cellphone, description}, tipo) {
      const dto = {
        number: 55 + currencyMixin.methods.limparStrings(cellphone),
        description: `${description}`
      }

      if (!currencyMixin.methods.validarCelular(cellphone)) {
        toast(`O celular do cliente não tem 11 digitos.`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        return
      }

      try {
        await axios.post(`api/whatsapp/send/${tipo}/${this.pedido.id}`, dto);
        toast("Enviado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e);
        const message = !e.response.data ? 'Verifique com o suporte' : e.response.data.message;
        toast(`Erro ao enviar whatsapp: ${message}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    async update() {
      const dto = {
        desconto: this.pedido.desconto,
        frete: this.pedido.frete,
        vendedorId: this.funcionarios.find(f => f.completedName === this.pedido.vendedor.completedName).id
      }
      try {
        const response = await axios.put(`api/pedido/${this.pedido.id}`, dto);
        toast("Pedido atualizado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
        this.pedido = response.data
      } catch (e) {
        toast(`Erro ao atualizar pedido: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    getColor(descricao) {
      if (!descricao) return 'success';

      const descricaoMin = descricao.toLowerCase();
      if (descricaoMin.includes("removendo")) {
        return 'danger'
      } else if (descricaoMin.includes("cliente")) {
        return 'info'
      } else if (descricaoMin.includes("pagamento")) {
        return 'primary'
      } else if (descricaoMin.includes("adicionando")) {
        return 'success'
      } else if (descricaoMin.includes("alterando") || descricaoMin.includes("atualizando")) {
        return 'warning'
      } else if (descricaoMin.includes("criação")) {
        return 'success'
      }
    },
    getBadge(descricao) {
      if (!descricao) return [];

      let badges = [];

      const descricaoMin = descricao.toLowerCase();
      if (descricaoMin.includes("removendo")) {
        badges.push('DELEÇÃO')
      }
      if (descricaoMin.includes("cliente")) {
        badges.push('CLIENTE')
      }
      if (descricaoMin.includes("pagamento")) {
        badges.push('PAGAMENTO')
      }
      if (descricaoMin.includes("criação")) {
        badges.push('CRIAÇÃO')
      }
      if (descricaoMin.includes("desconto")) {
        badges.push('PAGAMENTO', 'DESCONTO')
      }
      if (descricaoMin.includes("frete")) {
        badges.push('PAGAMENTO', 'FRETE')
      }
      if (descricaoMin.includes("status")) {
        badges.push('STATUS')
      }
      if (descricaoMin.includes("produto")) {
        badges.push('PRODUTO')
      }
      return badges
    },
    getIcon(descricao) {
      if (!descricao) return '';

      const descricaoMin = descricao.toLowerCase();
      if (descricaoMin.includes("removendo")) {
        return 'fa fa-minus'
      } else if (descricaoMin.includes("cliente")) {
        return 'fa fa-user'
      } else if (descricaoMin.includes("pagamento")) {
        return 'fa fa-credit-card'
      } else if (descricaoMin.includes("adicionando")) {
        return 'fa fa-plus'
      } else if (descricaoMin.includes("alterando")) {
        return 'fa fa-pencil-alt'
      } else if (descricaoMin.includes("criação")) {
        return 'fa fa-check-circle'
      } else if (descricaoMin.includes("atualizando")) {
        return 'fa fa-redo'
      }

    },
    formatDateP(longValue) {
      if (longValue == null) {
        return 'Sem data';
      }
      const dt = DateTime.fromMillis(longValue, {zone: 'America/Sao_Paulo'});
      return dt.toFormat('dd/MM/yyyy HH:mm');
    },
    totalPago() {
      let reduce = this.pedido.financeiro.transacoes.reduce((acc, transacao) => acc + transacao.valor, 0);
      if (this.pedido.itemTroca) {
        return reduce + this.pedido.itemTroca.reduce((acumulador, itemAtual) => {
          return acumulador + (itemAtual.precoCusto || 0);
        }, 0);
      }
      return reduce
    },
    formattedAmount(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    async fetchMetricas() {
      try {
        this.loadingMetricas = true

        const _id = this.$route.params._id;
        const response = await axios.get(`api/pedido/${_id}/metricas`);
        this.metricas = response.data

        this.loadingMetricas = false
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },
    async updateOrigem() {
      try {
        const _id = this.$route.params._id;
        await axios.put(`api/pedido/marketing/${_id}`, {}, {
          params: {
            origem: this.pedido.origem,
            mensagemInicial: this.pedido.mensagemInicial,
            observacao: this.pedido.observacao,
            dataCriacao: currencyMixin.methods.converterDataParaLong(this.dataCriacao)
          }
        });
        toast("Pedido atualizado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        this.$swal({
          title: "Erro ao setar origem!",
          text: `Erro ao setar origem! ${error}`,
        });
      }
    },
    async changeStatus() {
      try {
        if (this.pedido.status === 'FINALIZADO' && this.totalPago() < this.pedido.financeiro.valorTotal) {
          try {
            const result = await this.$swal({
              title: "Valor do financeiro divergente!",
              text: "Recomendamos que finalize o pedido apenas se o valor dos pagamentos for igual ou maior ao valor do pedido.",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Sair",
              confirmButtonText: "Finalizar mesmo assim!",
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              buttonsStyling: false,
            });
            if (!result.isConfirmed) {
              this.pedido.status = this.statusOriginal
              return;
            }
          } catch (e) {
            console.error("Erro no Swal:", e);
            return;
          }
        }
        if (this.pedido.status === 'CANCELADO') {
          await this.cancelar()
          return;
        }
        await axios.put(`api/pedido/status/${this.pedido.status}/${this.pedido.id}`);
        this.$swal({
          title: "Status alterado!",
          text: `Status alterado para ${this.pedido.status}.`,
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
      } catch (e) {
        console.log("Erro ao mudar status", e)
        this.$swal({
          title: "Erro ao mudar status!",
          icon: "error",
        });
      }
    },
    async cancelar() {
      await this.$swal({
        title: "Voce tem certeza?",
        text: "Apagar um pedido apagará também o financeiro e a troca (se tiver).",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Sair",
        confirmButtonText: "Cancelar!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.put(`api/pedido/status/${this.pedido.status}/${this.pedido.id}`);
            await this.loadProdutos();
          } catch (error) {
            console.error("Erro ao apagar aluguel:", error);
          }
          this.$swal({
            title: "Deleted!",
            text: "Pedido deletado.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        } else if (
            result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
        if (this.pedido.status === 'CANCELADO') {
          await this.$router.push(`/ecommerce/pedido/list`);
        }
      });
    },
    async fetchPedido(pedido) {
      if (pedido) {
        this.pedido = pedido
        this.statusOriginal = this.pedido.status
        return
      }
      this.loading = true
      try {
        const _id = this.$route.params._id;
        const response = await axios.get(`api/pedido/${_id}`);
        this.pedido = response.data
        this.statusOriginal = this.pedido.status
        this.dataCriacao = currencyMixin.methods.longToDateSimples(this.pedido.dataCriacao)
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
      this.loading = false
    },
    fetchPedidoCliente(cliente) {
      this.pedido.cliente = cliente
    },
    async getPdf(id, tipo) {
      const idToast = toast.loading(
          'Baixando arquivo...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );

      try {
        await axios.get(`api/pdf/${tipo}/${id}`, {
          responseType: 'blob'
        })
            .then(response => {
              const blob = new Blob([response.data], {type: 'application/pdf'});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = `${id}.pdf`; // Nome do arquivo a ser baixado.
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(error => {
              console.error("Houve um erro ao baixar o PDF:", error);
            });

        toast.update(idToast, {
          render: `Arquivo baixado com sucesso!`,
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success",
          isLoading: false,
        })
      } catch (e) {
        toast.update(idToast, {
          render: `Erro ao baixar arquivo!`,
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error",
          isLoading: false,
        })
      }
    },
    async getPdfTroca(id) {
      const idToast = toast.loading(
          'Baixando arquivo...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );

      try {
        await axios.get(`api/pdf/pedido/troca/${id}`, {
          responseType: 'blob'
        })
            .then(response => {
              const blob = new Blob([response.data], {type: 'application/pdf'});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = `${id}.pdf`; // Nome do arquivo a ser baixado.
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(error => {
              console.error("Houve um erro ao baixar o PDF:", error);
            });

        toast.update(idToast, {
          render: `Arquivo baixado com sucesso!`,
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success",
          isLoading: false,
        })
      } catch (e) {
        toast.update(idToast, {
          render: `Erro ao baixar arquivo!`,
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error",
          isLoading: false,
        })
      }
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
  }
  ,
}
;
</script>

<style scoped>
.loader {
  border: 5px solid #f3f3f3; /* Cor clara do spinner */
  border-top: 5px solid #3498db; /* Cor azul (pode ser alterada) */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-body.show-dropdowns {
  display: block;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.10s ease, transform 0.1s ease;
  visibility: hidden; /* Adiciona controle de visibilidade */
}

.card-body.show-dropdowns.visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible; /* Fica visível */
}


.dropdown-menu {
  background-color: #fff; /* Fundo branco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.dropdown-item {
  color: #495057; /* Cor do texto dos itens */
  transition: background-color 0.1s ease-in-out; /* Transição suave para o efeito de hover */
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse ou focar */
  color: #0056b3; /* Cor do texto ao passar o mouse ou focar */
  text-decoration: none; /* Remove o sublinhado do texto ao passar o mouse ou focar */
}

.dropdown-item:active {
  background-color: #e9ecef; /* Cor de fundo ao clicar */
  color: #004085; /* Cor do texto ao clicar */
}

.card-body.show-dropdowns::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9); /* Fundo branco com leve opacidade */
  z-index: -1;
  transition: opacity 0.1s ease; /* Adiciona transição para o efeito suave */
  opacity: 0; /* Começa escondido */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.card-body.show-dropdowns.visible::before {
  opacity: 1; /* Transição para visível */
}
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: opacity 0.1s ease, transform 0.1s ease;
}

/* Estado inicial para a transição de entrada */
.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden;
}

/* Estado final para a transição de entrada (e estado inicial para saída) */
.fade-slide-enter-to, .fade-slide-leave {
  opacity: 1;
  transform: translateY(1px);
  visibility: visible;
}

</style>
