export const currencyMixin = {
    methods: {
        formattedAmount(valor) {
            if (valor == null) {
                valor = 0
            }
            let currencyValue = parseFloat(valor);
            return currencyValue.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2
            });
        },
        longToDateAndHoraBrasil(timestamp) {
            if (timestamp == null) {
                return 'Sem data';
            }

            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
        longToDateBrasil(timestamp) {
            if (!timestamp) {
                return null;
            }

            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // adicione +1 porque getUTCMonth() começa de 0
            const day = String(date.getUTCDate()).padStart(2, '0');

            return `${day}/${month}/${year}`;
        },
        longToDateSimples(timestamp) {
            if (!timestamp) {
                return null;
            }

            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // adicione +1 porque getUTCMonth() começa de 0
            const day = String(date.getUTCDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        getFirstDayOfMonthInUTCLong() {
            const now = new Date(); // Pega a data e hora atual
            const firstDayOfMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)); // Define o primeiro dia do mês atual em UTC
            return firstDayOfMonth.getTime(); // Retorna o timestamp em milissegundos
        },
        getFirstDayOfMonthUTC() {
            const now = new Date(); // Data e hora atual
            const year = now.getUTCFullYear(); // Ano atual em UTC
            const month = (now.getUTCMonth() + 1).toString().padStart(2, '0'); // Mês atual em UTC, ajustado para base 1 e formatado para dois dígitos
            return `${year}-${month}-01`; // Formata a string conforme pedido
        },
        getLastDayOfMonthUTC() {
            const now = new Date(); // Data e hora atual
            const year = now.getUTCFullYear(); // Ano atual em UTC
            const nextMonth = new Date(Date.UTC(year, now.getUTCMonth() + 1, 1)); // Primeiro dia do próximo mês em UTC
            const lastDay = new Date(nextMonth - 1); // Volta um dia (em milissegundos) para o último dia do mês atual
            const month = (lastDay.getUTCMonth() + 1).toString().padStart(2, '0'); // Mês, formatado para dois dígitos
            const day = lastDay.getUTCDate().toString().padStart(2, '0'); // Dia, formatado para dois dígitos
            return `${year}-${month}-${day}`; // Formata a string conforme pedido
        },
        getLastDayOfMonthInUTCLong() {
            const now = new Date(); // Pega a data e hora atual
            const firstDayOfNextMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)); // Define o primeiro dia do próximo mês em UTC
            const lastDayOfMonth = new Date(firstDayOfNextMonth - 1); // Subtrai 1 milissegundo para voltar ao último dia do mês atual
            return lastDayOfMonth.getTime(); // Retorna o timestamp em milissegundos
        },
        longToDateCompleted(timestamp) {
            if (!timestamp) {
                return null;
            }

            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // adicione +1 porque getUTCMonth() começa de 0
            const day = String(date.getUTCDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        longToDateCompletedFormat(timestamp) {
            if (!timestamp) {
                return null;
            }

            const date = new Date(timestamp);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // adicione +1 porque getUTCMonth() começa de 0
            const day = String(date.getUTCDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        converterDataParaLong(data) {
            if (!data) {
                return null;
            }

            if (Number.isInteger(data)) {
                return data;
            }
            // Considera a data como UTC
            const [ano, mes, dia] = data.split('-').map(Number);
            const dataUTC = new Date(Date.UTC(ano, mes - 1, dia));

            return dataUTC.getTime();
        },
        converterDataAndHoraParaLong(data) {
            if (!data) {
                return null;
            }
            if (Number.isInteger(data)) {
                return data;
            }

            // Trata a data como UTC
            const dataUTC = new Date(data + ':00Z'); // Adiciona segundos e o indicador de UTC ('Z')

            return dataUTC.getTime();
        },
        formatarCPF(cpf) {
            let numeros = this.limparStrings(cpf) // Remove caracteres não numéricos
            if (!numeros) {
                return null
            }

            numeros = numeros.slice(0, 11); // Limita a 11 dígitos

            // Aplica a formatação
            numeros = numeros.replace(/(\d{3})(\d)/, '$1.$2');
            numeros = numeros.replace(/(\d{3})(\d)/, '$1.$2');
            numeros = numeros.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

            return numeros
        },
        limparStrings(valor) {
            let newVal = valor
            return newVal == null ? null : newVal.replace(/\D/g, '');
        },
        formatarCelular(cellphone) {
            let cell = this.limparStrings(cellphone)

            if (!cell) {
                return null
            }

            return cell.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        },
        validarCelular(celular) {
            let copia = celular
            const limpo = this.limparStrings(copia);
            const regex = /^\d{11}$/;
            return regex.test(limpo);
        },
        formatCep(cep) {
            let numeros = this.limparStrings(cep)

            if (!numeros) {
                return null
            }
            numeros = numeros.slice(0, 8);

            if (numeros.length > 5) {
                numeros = numeros.replace(/^(\d{5})(\d)/, '$1-$2');
            }
            return numeros;
        },
        formatarCNPJ(cnpj) {
            let numeros = this.limparStrings(cnpj); // Assume uma função que remove caracteres não numéricos
            if (!numeros) {
                return null;
            }

            numeros = numeros.slice(0, 14); // Limita a 14 dígitos, o comprimento de um CNPJ

            // Insere os pontos, barra e traço no formato XX.XXX.XXX/XXXX-XX
            return numeros.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
        },
        formatCPForCNPJ(valor) {
            if (!valor) {
                return null;
            }
            const cpfOrCnpj = this.verificarCPFouCNPJ(valor);

            if (cpfOrCnpj === 'CNPJ') {
                return this.formatarCNPJ(valor)
            } else if (cpfOrCnpj === 'CPF') {
                return this.formatarCPF(valor)
            }

            return null;
        },
        verificarCPFouCNPJ(valor) {
            if (!valor) {
                return null;
            }
            const limpo = this.limparStrings(valor)

            // Verifica se é um CPF (11 dígitos)
            const padraoCPF = /^\d{11}$/;
            if (padraoCPF.test(limpo)) {
                return 'CPF';
            }

            // Verifica se é um CNPJ (14 dígitos)
            const padraoCNPJ = /^\d{14}$/;
            if (padraoCNPJ.test(limpo)) {
                return 'CNPJ';
            }

            return null;
        }
    }
};