<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <soft-avatar
            :img="account.logoUrl"
            alt="bruce"
            size="xl"
            shadow="sm"
            border-radius="lg"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ account.name }}</h5>
          <!--          <p class="mb-0 font-weight-bold text-sm">CEO / Co-Founder</p>-->
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <!--        <label class="form-check-label mb-0 me-2">-->
        <!--          <small id="profileVisibility">Switch to invisible</small>-->
        <!--        </label>-->
        <!--        <soft-switch id="profile" name="profileVisibility" checked/>-->
      </div>
    </div>
  </div>

  <div v-if="account.name" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <a class="mb-1 font-weight-bolder" :href="`${getPriceList}`">Link para o Ecommerce com preço final</a> <i
            class="fas fa-copy" @click="copiarValor(getPriceList)"></i><br>
          <a class="mb-1 font-weight-bolder" :href="`${getPriceListAtacado}`">Link para o Ecommerce com preço de
            atacado</a> <i class="fas fa-copy" @click="copiarValor(getPriceListAtacado)"></i>
        </div>
      </div>
    </div>
  </div>

  <div v-if="item === 'geral'">
    <div id="basic-info" class="card mt-4">
      <div class="card-header">
        <h5>Informações básicas</h5>
      </div>
      <div class="card-body pt-0 d-flex flex-column">
        <div class="row">
          <div class="col-6">
            <label class="form-label">Primeiro nome ou razao social</label>
            <input v-model="account.nomeOrRazaoSocial" type="text" class="form-control">
          </div>
          <div class="col-6">
            <label class="form-label">Fantasia ou sobrenome</label>
            <input v-model="account.sobrenomeOrFantasia" type="text" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">CPF ou CNPJ</label>
            <input v-model="account.cpfOrCnpj" type="text" class="form-control" @input="formatCnpj()">
          </div>
          <div class="col-6">
            <label class="form-label">Inscrição Estadual ou RG</label>
            <input v-model="account.inscricaoOrRg" type="number" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-2">Logo</label>
            <input type="file" class="form-control" accept="image/jpeg,image/png" @change.prevent="handleFileUpload">
          </div>
          <div class="col-6">
            <label class="form-label mt-2">Email</label>
            <input v-model="account.email" type="text" class="form-control">
          </div>
        </div>
        <div class="row mb-3 mt-1">
          <div class="col-md-3 form-group">
            <label for="inputCEP"><i class="bi bi-house-door-fill me-2"></i>CEP:</label>
            <input id="inputCEP" v-model="account.cep" class="form-control" placeholder="Digite o CEP"
                   @input="formatCep()">
          </div>
          <div class="col-md-1 form-group mt-4">
            <button class="btn btn-success" @click.prevent="fetchAddress">
              <i v-if="!searchAddress" class="fas fa-search"></i>
              <i v-else class="fa fa-spinner fa-spin"></i>
            </button>
          </div>
          <div class="col-md-4 form-group">
            <label>Endereço:</label>
            <input v-model="account.rua" class="form-control" type="text"/>
          </div>
          <div class="col-md-4 form-group">
            <label>Cidade:</label>
            <input v-model="account.cidade" class="form-control" type="text"/>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-3 form-group">
            <label>Bairro:</label>
            <input v-model="account.bairro" class="form-control" type="text"/>
          </div>
          <div class="col-md-1 form-group">
            <label>UF:</label>
            <input v-model="account.uf" class="form-control" type="text"/>
          </div>
          <div class="col-md-2 form-group">
            <label for="inputNumero">Numero:</label>
            <input id="inputNumero" v-model="account.numero" class="form-control" type="text"/>
          </div>
          <div class="col-md-4 form-group">
            <label for="inputCelular"><i class="bi bi-telephone-fill me-2"></i>Numero do celular:</label>
            <input id="inputCelular" v-model="account.cellphone" class="form-control" type="text"
                   placeholder="(51) 99999-4847"
                   @input="formatarCelular"/>
          </div>
        </div>
        <soft-button class="mt-auto ms-auto col-4" color="dark" variant="gradient" size="sm"
                     :disabled="isLoading" @click.prevent="update">
          Atualizar conta
        </soft-button>
      </div>
    </div>
    <div id="password" class="card mt-4">
      <div class="card-header">
        <h5>Mude sua senha</h5>
      </div>
      <div class="card-body pt-0">
        <label class="form-label">Senha atual</label>
        <input
            v-model="currentPassword"
            class="form-control"
            id="currentPassword"
            type="password"
            placeholder="Senha atual"
        />
        <label class="form-label">Nova senha</label>
        <input v-model="newPassword" class="form-control" id="newPassword" type="password" placeholder="Nova senha"/>
        <label class="form-label">Confirme a nova senha</label>
        <input
            v-model="newPassword2"
            class="form-control"
            id="confirmPassword"
            type="password"
            placeholder="Confirme a nova senha"
        />
        <h5 class="mt-5">Como ter uma senha forte?</h5>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">Um caractere especial</span>
          </li>
          <li>
            <span class="text-sm">Min 6 caracteres</span>
          </li>
          <li>
            <span class="text-sm">Um número</span>
          </li>
          <li>
            <span class="text-sm">Mude com frequência</span>
          </li>
        </ul>
        <soft-button
            class="float-end mt-6 mb-0"
            color="dark"
            variant="gradient"
            size="sm"
            :disabled="isLoading"
            @click.prevent="updatePassword"
        >Update password
        </soft-button
        >
      </div>
    </div>
    <!--  <div id="2fa" class="card mt-4">-->
    <!--    <div class="card-header d-flex">-->
    <!--      <h5 class="mb-0">Two-factor authentication</h5>-->
    <!--      <soft-badge color="success" class="ms-auto">Enabled</soft-badge>-->
    <!--    </div>-->
    <!--    <div class="card-body">-->
    <!--      <div class="d-flex">-->
    <!--        <p class="my-auto">Security keys</p>-->
    <!--        <p class="text-secondary text-sm ms-auto my-auto me-3">-->
    <!--          No Security Keys-->
    <!--        </p>-->
    <!--        <soft-button-->
    <!--          color="dark"-->
    <!--          variant="outline"-->
    <!--          size="sm"-->
    <!--          class="mb-0"-->
    <!--          type="button"-->
    <!--          >Add</soft-button-->
    <!--        >-->
    <!--      </div>-->
    <!--      <hr class="horizontal dark" />-->
    <!--      <div class="d-flex">-->
    <!--        <p class="my-auto">SMS number</p>-->
    <!--        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>-->
    <!--        <soft-button-->
    <!--          color="dark"-->
    <!--          variant="outline"-->
    <!--          size="sm"-->
    <!--          class="mb-0"-->
    <!--          type="button"-->
    <!--          >Edit</soft-button-->
    <!--        >-->
    <!--      </div>-->
    <!--      <hr class="horizontal dark" />-->
    <!--      <div class="d-flex">-->
    <!--        <p class="my-auto">Authenticator app</p>-->
    <!--        <p class="text-secondary text-sm ms-auto my-auto me-3">-->
    <!--          Not Configured-->
    <!--        </p>-->
    <!--        <soft-button-->
    <!--          color="dark"-->
    <!--          variant="outline"-->
    <!--          size="sm"-->
    <!--          class="mb-0"-->
    <!--          type="button"-->
    <!--          >Set up</soft-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <div id="accounts" class="card mt-4">
      <div class="card-header">
        <h5>Termo de Garantia</h5>
        <p class="text-sm">
          Aqui você pode alterar o texto base do seu termo de garantia
        </p>
      </div>
      <div class="card-body pt-0 d-flex flex-column" style="min-height: 500px; overflow: auto">
        <div v-if="!isLoading" class="col-12 row">
          <!-- Conteúdo do Editor Quill -->
          <quill-editor :content="observacoes" @change="handleContentChange"></quill-editor>
        </div>
        <soft-button class="mt-auto ms-auto col-4" color="dark" variant="gradient" size="sm"
                     :disabled="isLoading" @click.prevent="update">
          Atualizar garantia
        </soft-button>
      </div>
    </div>
    <div id="notifications" class="card mt-4">
      <div class="card-header">
        <h5>Automações</h5>
      </div>
      <div class="card-body pt-0">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Atividade</p>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Termo de garantia automático</span>
                  <span class="text-xs font-weight-normal">
                    Envio de termo de garantia automático para seu cliente ao finalizar o pedido pelo WhatsApp
                </span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsPush" name="mentionsPush"/>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Assinatura automática no termo de garantia</span>
                  <span class="text-xs font-weight-normal">
                    Imprima seu termo de garantia com a assinatura do seu certificado A3
                </span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsPush" name="mentionsPush"/>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Relatório diário automático</span>
                  <span class="text-xs font-weight-normal">
                    Receba no seu ao final do dia no seu WhatsApp informações sobre faturamento, lucro e quantidade de pedidos
                </span>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <soft-switch id="mentionsPush" name="mentionsPush"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="item === 'bancos'">
    <div id="bancos" class="card mt-4">
      <div class="card-header">
        <h5>Bancos e taxas</h5>
      </div>
      <div class="card-body pt-0 d-flex flex-column">
        <div class="row col-12">
          <div class="table-responsive">
            <table class="table mb-0 align-items-center">
              <tbody>
              <tr v-for="banco in bancos" :key="banco.nome">
                <td>
                  <div class="px-2 py-1 d-flex">
                    <div>
                      <!-- Substitua o caminho do logo conforme necessário -->
                      <!--                      <img src="@/assets/img/small-logos/santander.png" class="avatar avatar-sm me-2" alt="logo_banco"/>-->
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ banco.nome }}</h6>
                      <p class="mb-0 text-sm">Ag: {{ banco.agencia }} - CC: {{ banco.conta }} </p>
                      <p class="mb-0 text-sm">{{ banco.razaoSocialOrName }} - {{ format(banco.cpfOrCnpj) }}</p>
                    </div>
                  </div>
                </td>
                <td class="text-sm text-center align-middle" @click.prevent="">
                  <div>
                    <button class="btn btn-info btn-sm" @click.prevent="bancoSelected = banco; isModalVisible = true">Atulizar</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <soft-button class="mt-auto ms-auto col-4" color="dark" variant="gradient" size="sm"
                                 :disabled="isLoading"
                                 @click.prevent="isModalVisible = true">
                      Criar banco
                    </soft-button>

                    <ModalBanco
                        v-if="isModalVisible"
                        title="Banco"
                        :show-modal="isModalVisible"
                        :banco="bancoSelected"
                        @update:show-modal="isModalVisible = $event; getBancos()">
                    </ModalBanco>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="bancos" class="card mt-4">
      <div class="card-header">
        <h5>Maquinas e taxas</h5>
      </div>
      <div class="card-body pt-0 d-flex flex-column">
        <div class="row col-12">
          <div class="table-responsive">
            <table class="table mb-0 align-items-center">
              <tbody>
              <tr v-for="maquina in maquinas" :key="maquina.id">
                <td>
                  <div class="px-2 py-1 d-flex">
                    <!--                  <div>-->
                    <!--                     Substitua o caminho do logo conforme necessário -->
                    <!--                    <img src="@/assets/img/small-logos/santander.png" class="avatar avatar-sm me-2" alt="logo_banco"/>-->
                    <!--                  </div>-->
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ maquina.nome }}</h6>
                      <p class="mb-0 text-sm">{{ maquina.banco.nome }}, Ag: {{ maquina.banco.agencia }} - CC:
                        {{ maquina.banco.conta }} </p>
                      <!--                    <p class="mb-0 text-sm">{{ banco.razaoSocialOrName }} - {{ format(banco.cpfOrCnpj) }}</p>-->
                    </div>
                  </div>
                </td>

                <td>
                  <div v-for="(item, index) in maquina.bandeiraParcelaTaxa" :key="index">
                    <div class="row col-12">
                      <div>
                        <label>Bandeiras</label>
                        <p>{{ item.bandeiras.join(', ') }}</p>
                      </div>
                      <div v-for="(parcelaTaxa, ptIndex) in item.parcelasTaxas" :key="ptIndex" class="col-12">
                        <div class="row">
                          <div class="col-3">
                            <input v-model="parcelaTaxa.parcela" disabled class="form-control" type="number"
                                   placeholder="Número da parcela">
                          </div>
                          <div class="col-3">
                            <div class="input-group">
                              <input v-model="parcelaTaxa.taxa" disabled class="form-control" type="number"
                                     placeholder="Taxa da parcela (%)">
                              <span class="input-group-text">%</span>
                            </div>
                          </div>
                          <!--                        <div class="col-2">-->
                          <!--                          <button class="btn btn-danger" @click.prevent="removeParcelaTaxa(index, ptIndex)">Remover</button>-->
                          <!--                        </div>-->
                        </div>
                      </div>
                      <!--                    <div class="col-12">-->
                      <!--                      <button class="btn btn-primary" @click.prevent="addParcelaTaxa(index)">Adicionar Parcela e Taxa</button>-->
                      <!--                    </div>-->
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <soft-button class="mt-auto ms-auto col-12" color="dark" variant="gradient" size="sm"
                                 :disabled="isLoading"
                                 @click.prevent="isModalVisibleTaxas = true">
                      Crir taxas
                    </soft-button>

                    <ModalTaxas
                        v-if="isModalVisibleTaxas"
                        :bancos="bancos"
                        title="Adicione Maquininhas e Taxas"
                        :show-modal="isModalVisibleTaxas"
                        @update:show-modal="isModalVisibleTaxas = $event;"
                        @update="getBancos">
                    </ModalTaxas>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import QuillEditor from "vue-quill-editor/src/editor.vue";

import axios from "axios";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import ModalBanco from "@/views/ModalBanco.vue";
import ModalTaxas from "@/views/ModalTaxas.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";


export default {
  name: "SideNavItem",
  components: {
    SoftAvatar,
    ModalTaxas,
    ModalBanco,
    SoftButton,
    SoftSwitch,
    QuillEditor
  },
  props: {
    item: String
  },
  data() {
    return {
      observacoes: null,
      bancoSelected: null,

      isLoading: false,
      account: {},
      searchAddress: false,
      selectedFile: null,
      isModalVisible: null,
      isModalVisibleTaxas: null,
      bancos: {},
      rua: null,
      maquinas: {},
      currentPassword: null,
      newPassword: null,
      newPassword2: null
    };
  },
  computed: {
    getPriceListAtacado() {
      return 'https://' + this.account.name.toLowerCase().split(' ').join('') + '.techphone.io/ecommerce/list/f911a465-fdbf-4975-8527-d54a35b7b547'
    },
    getPriceList() {
      return 'https://' + this.account.name.toLowerCase().split(' ').join('') + '.techphone.io/ecommerce/list/92a7500b-9eb1-4f45-8744-46c226754b12'
    }
  },
  created() {
    this.getAccountSettings();
    this.getBancos();
    this.getMaquinasAndTaxas();
    this.formatarCelular()
    this.formatCep()
    if (this.account.cpfOrCnpj) {
      this.formatCnpj()
    }
  },
  methods: {
    copiarValor(text) {
      const el = document.createElement('textarea');
      el.value = text
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      toast("Copiado!", {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
        transition: "flip",
        theme: "dark",
        type: "success"
      })
    },
    async updatePassword() {
      const dto = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      }

      try {
        await axios.post(`api/account/change/password`, dto)

        toast("Senha atualizada!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        toast(`Erro ao atualizar conta: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          type: "error",
          theme: "dark"
        })
      }
    },
    handleContentChange(editor) {
      this.observacoes = editor.html
    },
    async handleFileUpload(event) {
      this.selectedFile = event.target.files[0];

      const allowedTypes = ['image/jpeg', 'image/png'];

      if (!allowedTypes.includes(this.selectedFile.type)) {
        toast("Por favor, selecione um arquivo de imagem válido.", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        });
        return;
      }


      const formData = new FormData();
      formData.append('image', this.selectedFile);

      try {
        await axios.post(`api/account/logo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        toast("Logo atualizada com sucesso!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar arquivo: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          type: "error",
          theme: "dark"
        })
      }
    },
    async update() {
      const dto = this.account

      dto.textoTermoGarantia = this.observacoes
      dto.cellphone = currencyMixin.methods.limparStrings(this.account.cellphone)
      dto.cep = currencyMixin.methods.limparStrings(this.account.cep)
      dto.cpfOrCnpj = currencyMixin.methods.limparStrings(dto.cpfOrCnpj)

      try {
        await axios.post(`api/account`, dto);
        toast("Conta atualizada!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        toast(`Erro ao atualizar conta: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          type: "error",
          theme: "dark"
        })
      }
    },
    async getAccountSettings() {
      this.isLoading = true
      const response = await axios.get(`api/account`);
      this.account = response.data;
      this.observacoes = this.account.textoTermoGarantia
      this.isLoading = false
    },
    async getBancos() {
      this.isLoading = true
      const response = await axios.get(`api/financeiro/banco`);
      this.bancos = response.data;
      this.isLoading = false
    },
    async getMaquinasAndTaxas() {
      this.isLoading = true
      const response = await axios.get(`api/financeiro/maquinas/cartao`);
      this.maquinas = response.data;
      this.isLoading = false
    },
    format(cpfOrCnpj) {
      return currencyMixin.methods.formatCPForCNPJ(cpfOrCnpj)
    },
    formatCnpj() {
      this.account.cpfOrCnpj = currencyMixin.methods.formatarCNPJ(this.account.cpfOrCnpj)
    },
    formatCep() {
      this.account.cep = currencyMixin.methods.formatCep(this.account.cep)
    },
    formatarCelular() {
      this.account.cellphone = currencyMixin.methods.formatarCelular(this.account.cellphone)
    },
    async fetchAddress() {
      let cep = currencyMixin.methods.formatCep(this.account.cep)
      if (cep && cep.length >= 8) {
        this.searchAddress = true
        try {
          this.account.cep = this.account.cep.replace(/\D/g, '');
          const response = await fetch(`https://viacep.com.br/ws/${this.account.cep}/json/`);
          const data = await response.json();
          this.account.rua = data.logradouro
          this.account.bairro = data.bairro
          this.account.cidade = data.localidade
          this.account.uf = data.uf
        } catch (error) {
          console.error("Erro ao buscar o endereço:", error);
        }
        this.searchAddress = false
      }
    },
  },
};
</script>

<style scoped>
</style>
