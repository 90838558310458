<template>
  <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Pagamentos</h5>
    <div class="col-4">
      <h6>Total pago: {{ currencyMixin.methods.formattedAmount(totalPago) }}</h6>
    </div>
    <br>
    <div class="mt-3 multisteps-form__content">
      <div class="row mt-3 mb-3">

        <div class="col-2">
          <label>Método de Pagamento:</label>
          <select v-model="selectedPaymentMethod" class="form-control">
            <option value="cartao">Cartão</option>
            <option value="dinheiro">Dinheiro</option>
            <option value="pix">Pix</option>
          </select>
        </div>

        <div v-if="selectedPaymentMethod === 'pix'" class="col-6">
          <label>Selecione o banco para onde o pix sera feito:</label>
          <select v-model="bancoSelected" class="form-control">
            <option v-for="banco in bancos" :key="banco.id" :value="banco.id">
              {{ banco.nome }} - {{ banco.razaoSocialOrName }} | Conta: {{ banco.conta }} Agência: {{ banco.agencia }}
            </option>
          </select>
        </div>

        <div v-if="selectedPaymentMethod === 'cartao'" class="col-2">
          <label>Juros por parte do:</label>
          <select v-model="parteJuros" class="form-control">
            <option selected value="VENDEDOR">Vendedor (Venda com juros incluso)</option>
            <option value="COMPRADOR">Comprador (Venda sem juros incluso)</option>
          </select>
        </div>

        <!-- Select para Maquininhas -->
        <div v-if="selectedPaymentMethod === 'cartao'" class="col-3">
          <label>Selecione a maquininha:</label>
          <select v-model="maquininhaSelecionada" class="form-control">
            <option disabled value="">Selecione uma Maquininha</option>
            <option v-for="opcao in opcoesMaquininha" :key="opcao.id" :value="opcao.id">
              {{ opcao.nome }} - {{ opcao.nomeBanco }}
            </option>
          </select>
        </div>

        <!-- Select para Bandeiras -->
        <div v-if="selectedPaymentMethod === 'cartao' && bandeiras.length > 0" class="col-2">
          <label>Selecione a bandeira:</label>
          <select v-model="bandeiraSelecionada" class="form-control">
            <option disabled value="">Selecione uma Bandeira</option>
            <option v-for="bandeira in bandeiras" :key="bandeira" :value="bandeira">
              {{ bandeira }}
            </option>
          </select>
        </div>

        <!-- Select para Parcelas e Taxas -->
        <div v-if="selectedPaymentMethod === 'cartao' && parcelasTaxas.length > 0" class="col-3">
          <label>Selecione a quantidade de parcelas:</label>
          <select v-model="parcelaTaxaSelecionada" class="form-control">
            <option disabled value="">Selecione Parcela e Taxa</option>
            <option v-for="parcelaTaxa in parcelasTaxas" :key="parcelaTaxa.parcela" :value="parcelaTaxa">
              {{ parcelaTaxa.parcela === 0 ? 'Débito' : parcelaTaxa.parcela }} {{ parcelaTaxa.parcela === '0' ? '' : 'parcelas'}} - Taxa: {{ parcelaTaxa.taxa }}%
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Valor:</label>
          <CurrencyInput
              v-model="inputValue"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
          <p v-if="parcelaTaxaSelecionada">Valor Líquido: {{ calcularValorLiquido() }}</p>
        </div>
        <div class="col-3">
          <label>Data e hora do pagamento:</label>
          <input v-model="dataPagamento" type="datetime-local" class="form-control"/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12">
          <ul class="list-group">
            <li v-for="(transacao, index) in transacoes" :key="index"
                class="list-group-item d-flex flex-column align-items-start">

              <div class="row col-12">
                <div class="col-11 d-flex flex-column justify-content-center">
                  <div class="mb-2">
                    <span class="font-weight-bold">{{ formatValue(transacao.valor) }}</span> -
                    <span :class="{'text-primary': transacao.metodoPagamento === 'CARTAO', 'text-success': transacao.metodoPagamento === 'PIX'}">
                      {{ transacao.metodoPagamento }}
                    </span>
                  </div>

                  <div v-if="transacao.metodoPagamento === 'CARTAO'" class="mb-2">
                    {{ formatarTransacao(transacao.transacaoTaxa) }}
                  </div>
                  <div v-else-if="transacao.metodoPagamento === 'PIX'" class="mb-2">
                    {{ formatarTransacaoPix(transacao) }}
                  </div>

                  <div class="mb-2">
                    {{ formatDate(transacao.dataPagamento) }}
                  </div>
                </div>

                <div class="col-1 d-flex align-items-center align-content-end">
                  <button class="btn btn-danger" @click.prevent="removeTransacao(transacao.id)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
            ype="button"
            color="light"
            variant="gradient"
            class="js-btn-prev"
            @click.prevent="this.$parent.prevStep"
        >Anterior
        </soft-button
        >
        <soft-button
            :disabled="isDisabled"
            type="button"
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Next"
            @click.prevent="addPagamento()"
        >Adicionar pagamento
        </soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";

import CurrencyInput from "@/CurrencyInput.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";

export default {
  name: "Pricing",
  components: {
    CurrencyInput,
    SoftButton,
  },
  props: {
    pedido: null
  },
  emits: ['fetch-financeiro', 'fetch'],
  data() {
    return {
      installments: 1,
      selectedPaymentMethod: 'cartao',
      inputValue: 0,
      transacoes: [],

      desconto: 0,
      dataPagamento: null,
      frete: 0,
      isLoading: false,
      maquininhas: [],
      maquininhaSelecionada: null,
      bandeiraSelecionada: null,
      parcelaTaxaSelecionada: null,
      bancoSelected: null,
      bancos: [],
      parteJuros: null,
      opcoesMaquininha: [],
      bandeiras: [],
      parcelasTaxas: []
    }
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    },
    totalPago() {
      let reduce = this.transacoes.reduce((acc, transacao) => acc + transacao.valor, 0);
      if (this.pedido.itemTroca) {
        return reduce + this.pedido.itemTroca.reduce((acumulador, itemAtual) => {
          return acumulador + (itemAtual.precoCusto || 0);
        }, 0);
      }
      return reduce
    },
    isDisabled() {
      return !this.inputValue || !this.selectedPaymentMethod  || this.isLoading
    }
  },
  watch: {
    maquininhaSelecionada(newVal) {
      this.bandeiraSelecionada = null;
      this.parcelaTaxaSelecionada = null;

      const maquininha = this.maquininhas.find(m => m.id === newVal);
      if (maquininha?.bandeiraParcelaTaxa) {
        this.bandeiras = maquininha.bandeiraParcelaTaxa.map(bpt => bpt.bandeiras).flat();
      } else {
        this.bandeiras = [];
      }
    },
    bandeiraSelecionada(newVal) {
      this.parcelaTaxaSelecionada = null;

      const maquininha = this.maquininhas.find(m => m.id === this.maquininhaSelecionada);
      if (maquininha?.bandeiraParcelaTaxa) {
        const bandeiraParcela = maquininha.bandeiraParcelaTaxa.find(bpt => bpt.bandeiras.includes(newVal));
        this.parcelasTaxas = bandeiraParcela ? bandeiraParcela.parcelasTaxas : [];
      } else {
        this.parcelasTaxas = [];
      }
    },
    selectedPaymentMethod() {
      this.parcelaTaxaSelecionada = null;
    }
  },
  created() {
    this.transacoes = this.pedido.financeiro.transacoes
    this.getMaquinasCartao()
    this.getCartao()
  },
  mounted() {
    if (document.getElementById("choices-currency")) {
      let element = document.getElementById("choices-currency");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
  methods: {
    calcularValorLiquido() {
      if (!this.parcelaTaxaSelecionada && !this.parteJuros || this.parteJuros !== 'VENDEDOR') {
        return currencyMixin.methods.formattedAmount(this.inputValue.toFixed(2));
      }
      const valorTaxa = this.inputValue * (this.parcelaTaxaSelecionada.taxa / 100); // Calcula o valor da taxa
      const valorLiquido = this.inputValue - valorTaxa; // Subtrai o valor da taxa do valor original
      return currencyMixin.methods.formattedAmount(valorLiquido.toFixed(2)); // Retorna o valor líquido com duas casas decimais
    },
    formatValue(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    formatDate(date) {
      return currencyMixin.methods.longToDateAndHoraBrasil(date)
    },
    formatarTransacaoPix(transacao) {
      if (!transacao?.banco) {
        return '';
      }
      return `${transacao?.banco?.nome} - ${transacao?.banco?.razaoSocialOrName} | Agência ${transacao?.banco?.agencia}, Conta: ${transacao?.banco?.conta}`;
    },
    formatarTransacao(transacao) {
      if (!transacao?.maquinaCartao) {
        return '';
      }
      return `${transacao.bandeira}, Juros: ${transacao.jurosPorParte}, Parcelas: ${transacao.parcela === 0 ? 'Débito' : transacao.parcela}, ${transacao.taxa}%, Maquina: ${transacao.maquinaCartao.nome} - Banco: ${transacao.maquinaCartao.banco.razaoSocialOrName}`;
    },
    async getMaquinasCartao() {
      const response = await axios.get(`api/financeiro/maquinas/cartao`);
      this.maquininhas = response.data
      this.opcoesMaquininha = response.data.map(m => ({
        id: m.id,
        nome: m.nome,
        nomeBanco: m.banco ? m.banco.razaoSocialOrName : 'Sem Razao Social'
      }));
    },
    async getCartao() {
      const response = await axios.get(`api/financeiro/banco`);
      this.bancos = await response.data.map(m => ({
        id: m.id,
        nome: m.nome,
        razaoSocialOrName: m.razaoSocialOrName ? m.razaoSocialOrName : 'Sem Razao Social',
        conta: m.conta,
        agencia: m.agencia
      }));
    },
    async addPagamento() {
      if (!this.inputValue || !this.selectedPaymentMethod) {
        return
      }
      this.isLoading = true
      const dto = {
        valor: this.inputValue,
        metodoPagamento: this.selectedPaymentMethod,
        dataPagamento: currencyMixin.methods.converterDataAndHoraParaLong(this.dataPagamento)
      }

      if (this.selectedPaymentMethod === 'cartao') {
        dto.jurosPorParte = this.parteJuros
        dto.maquininhaId = this.maquininhaSelecionada
        dto.taxa = this.parcelaTaxaSelecionada.taxa
        dto.parcela = this.parcelaTaxaSelecionada.parcela
        dto.bandeira = this.bandeiraSelecionada
        dto.valorBruto = this.inputValue

      } else if (this.selectedPaymentMethod === 'pix') {
        dto.bancoId = this.bancos.find(m => m.id === this.bancoSelected).id;
      }

      try {
        const response = await axios.post(`api/financeiro/transacao/${this.pedido.id}`, dto);

        this.inputValue = 0
        this.dataPagamento = null
        await this.fetchPedido()
        this.$emit("fetch", response.data)
        this.isLoading = false

        toast("Transação adicionada", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar financeiro: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    async fetchPedido() {
      let response = await axios.get(`api/pedido/${this.pedido.id}`);
      this.transacoes = response.data.financeiro.transacoes
    },
    async removeTransacao(idProduto) {
      try {
        await axios.delete(`api/financeiro/transacao/${this.pedido.id}/${idProduto}`)

        toast("Transação removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
        await this.fetchPedido()
      } catch (e) {
        toast(`Erro ao atualizar financeiro: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    }
  },
};
</script>
