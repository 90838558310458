<template>
  <div class="container-fluid">
    <div class="card mt-2 my-card">
      <div class="card-body">
        <!-- Seção de Histórico de Mensagens -->
        <div ref="messageHistory" class="message-history">
          <div v-for="(msg, index) in sortedMessages" :key="msg.id" :ref="setMessageRef(index)"
               :class="{'message-card': true, 'message-left': msg.role === 'assistant', 'message-right': msg.role === 'user'}">
          <span class="role-name">
            <!-- Ícone e Nome da Role -->
            <i v-if="msg.role === 'assistant'" class="fas fa-brain icon-brain"></i>
            <i v-else class="fas fa-user icon-user"></i>
            {{ msg.role === 'assistant' ? 'Assistente inteligente' : 'Você' }}
          </span>
            <div class="message-content">{{ msg.content[0].text.value }}</div>
          </div>
          <div v-if="isLoading" class="loading-icon">
            <i class="fas fa-brain icon-brain"></i>
            <div class="message-content">
              <span>.</span><span>.</span><span>.</span>
            </div>
          </div>
        </div>

        <!-- Seção de Input de Mensagem -->
        <form @submit.prevent="sendMessage">
          <div class="mb-3">
            <textarea v-model="newMessage" class="form-control" rows="3"
                      placeholder="Digite sua mensagem...">

            </textarea>
          </div>
          <div class="row col-12 d-flex">
            <div class="col-2">
              <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
            <div class="col-10 align-content-end align-items-end">
              <span class="btn btn-sm m-1 btn-outline-success">Quais produtos posso fazer promoção e manter 15% de margem?</span>
              <span class="btn btn-sm m-1 btn-outline-success">Quais produtos posso fazer promoção e manter R$500 de margem?</span>
              <span
                  class="btn btn-sm m-1 btn-outline-success">Me de uma copy para promoção meu do modelo mais vendido</span>
              <span class="btn btn-sm m-1 btn-outline-success">Quais são as proximas datas comemorativas para fazer uma promoção</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "FullScreenChatCard",
  data() {
    return {
      newMessage: '',
      messages: [],
      chatHistory: {},
      isLoading: false,
    };
  },
  computed: {
    sortedMessages() {
      if (!this.chatHistory.data) return [];
      return [...this.chatHistory.data].sort((a, b) => a.created_at - b.created_at);
    }
  },
  created() {
    this.getMessages()
  },
  methods: {
    setMessageRef(index) {
      if (index === this.sortedMessages.length - 1) {
        return 'lastMessage';
      }
    },
    async getMessages() {
      const response = await axios.get(`api/chat/messages`);
      this.chatHistory = response.data
      this.scrollToBottom(); // Rolar para o final após carregar as mensagens
    },
    async sendMessage() {
      this.isLoading = true;

      const dto = {
        content: this.newMessage
      }
      try {
        this.newMessage = ''
        const response = await axios.post(`api/chat/message`, dto);

        const run = response.data;

        await this.getMessages()

        await this.waitForCompletion(run.id);
        this.isLoading = false; // Desativa o ícone de carregamento

        await this.getMessages()
      } catch (e) {
        console.log(e)
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const lastMessageElement = this.$refs.lastMessage;
        if (lastMessageElement) {
          lastMessageElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    async waitForCompletion(runId) {
      const checkCompletion = async () => {
        try {
          const response = await axios.get(`api/chat/run?run=${runId}`);
          const runData = response.data;

          if (runData.completed_at !== null) {
            // Se completed_at não é null, encerra a espera
            return true;
          } else {
            // Se completed_at ainda é null, espera e tenta novamente
            await new Promise(resolve => setTimeout(resolve, 5000)); // Espera 5 segundos antes de tentar novamente
            return checkCompletion();
          }
        } catch (e) {
          console.error("Erro ao verificar a conclusão:", e);
          throw e; // Propaga o erro
        }
      };

      return checkCompletion();
    }
  },
};
</script>

<style scoped>
.my-card {
  height: 100vh; /* Altura total da viewport */
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.message-history {
  flex-grow: 1; /* Ocupa o espaço disponível */
  overflow-y: auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 10px 10px 0 0;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.message-card {
  background-color: white;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.message-left {
  align-items: flex-start;
}

.message-right {
  align-items: flex-end;
}

.role-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.message-content {
  background-color: #f8f9fa;
  padding: 8px;
  border-radius: 5px;
}

.icon-brain {
  color: green;
}

.icon-user {
  color: black;
}

.loading-icon span {
  display: inline-block;
  animation: jump 1s infinite;
}

.loading-icon span:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-icon span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Estiliza a barra de rolagem para navegadores baseados em WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do trilho da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Cor do manipulador da barra de rolagem */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor do manipulador ao passar o mouse */
}

/* Estiliza a barra de rolagem para Firefox */
* {
  scrollbar-width: thin; /* "auto" ou "thin" */
  scrollbar-color: #888 #f1f1f1; /* Cor do manipulador e do trilho */
}

.message-history::-webkit-scrollbar {
  /* Estilos para navegadores baseados em WebKit */
}
</style>
