<template>
  <Modal :show-modal="showModal" title="Altere o produto" label-button="Salvar mudanças"
         @update:showModal="emitModal($event)"
         @update="update()">
    <div class="container">
      <form class="mt-2">
        <div class="form-group">
          <label>Descrição:</label>
          <input
              v-model="item.descricao"
              class="form-control"
          />
        </div>

        <div class="form-group row col-12">
          <div class="col-3">
            <label>CFOP</label>
            <input v-model="item.cfop" class="form-control" >
          </div>
          <div class="col-3">
            <label>NCM</label>
            <input v-model="item.codigo_ncm" class="form-control" >
          </div>
          <div class="col-3">
            <label>CSOSN</label>
            <input v-model="item.icms_situacao_tributaria" class="form-control" >
          </div>
        </div>

        <div class="form-group row col-12">
          <div class="col-3">
            <label>PIS</label>
            <input v-model="item.pis_situacao_tributaria" class="form-control" >
          </div>
          <div class="col-3">
            <label>COFINS</label>
            <input v-model="item.cofins_situacao_tributaria" class="form-control" >
          </div>
        </div>


        <div class="form-group row">
          <div class="col-md-4 mb-3">
            <label class="form-label">Valor Bruto</label>
            <CurrencyInput
                v-model="item.valor_unitario_tributavel"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
          <div class="col-3">
            <label>Quantidade</label>
            <input v-model="item.quantidade_comercial" class="form-control" >
          </div>
        </div>
      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";
import CurrencyInput from "@/CurrencyInput.vue";

export default {
  name: "ModalProduto",
  components: {CurrencyInput, Modal},
  props: {
    showModal: Boolean,
    title: String,
    itemDto: [Object, () => {
    }]
  },
  emits: ['update', 'update:showModal'],
  data() {
    return {
      abaAtiva: 'produto',
      item: {},
      showFields: false,
    }
  },
  created() {
    this.item = this.itemDto
  },
  methods: {
    emitModal(event) {
      this.$emit('update:showModal', event);
    },
    update() {
      this.$emit('update', this.item)
    },
  }
}
</script>