<template>
  <div class="container mt-5">
    <div class="card col-5">
      <div class="card-body">
        <h3>Cadastro de {{ cadastro.categoria }}</h3>
      </div>
    </div>
    <form>
      <div class="form-group col-3 mt-3">
        <label>Tipo:</label>
        <select v-model="tipo" class="form-control">
          <option selected value="PF">Pessoa Fisica</option>
          <option value="PJ">Pessoa Juridica</option>
        </select>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 form-group custom-select-container">
          <label for="inputNome"><i class="bi bi-person-fill me-2"></i>Nome ou razão social:</label>
          <input
              v-model="cadastro.nome"
              class="multisteps-form__input form-control"
              type="text"
          />
        </div>
        <div class="col-md-6 form-group">
          <label for="inputSobrenome">Sobrenome ou fantasia:</label>
          <input id="inputSobrenome" v-model="cadastro.sobrenome" class="multisteps-form__input form-control"
                 type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label><i class="bi bi-house-door-fill me-2"></i>CEP:</label>
          <input v-model="cadastro.cep" class="form-control" placeholder="Digite o CEP"
                 @input="fetchAddress"/>
        </div>
        <div class="col-md-9 form-group">
          <label for="inputEndereco">Endereço:</label>
          <input id="inputEndereco" v-model="cadastro.endereco" class="form-control" type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4 form-group">
          <label><i class="bi bi-house-door-fill me-2"></i>Bairro:</label>
          <input v-model="cadastro.bairro" type="text" class="form-control" placeholder="Bairro"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Cidade:</label>
          <input v-model="cadastro.cidade" class="form-control" type="text"/>
        </div>
        <div class="col-md-3 form-group">
          <label>UF:</label>
          <input v-model="cadastro.uf" class="form-control" type="text"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 form-group">
          <label>Numero:</label>
          <input v-model="cadastro.numero" class="form-control" type="text"/>
        </div>
        <div class="col-md-6 form-group">
          <label><i class="bi bi-telephone-fill me-2"></i>Numero do celular:</label>
          <input v-model="cadastro.cellphone" class="form-control" type="text"
                 @input="formatarCelular"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 form-group">
          <label><i class="bi bi-card-text me-2"></i>CPF ou CNPJ:</label>
          <input v-model="cadastro.cpfOrCnpj" class="form-control"
                 :class="{'is-invalid': !cpfValid}"
                 type="text">
          <span v-if="!cpfValid" class="invalid-feedback">CPF inválido!</span>
        </div>
        <div class="col-md-6 form-group">
          <label>RG ou IE:</label>
          <input v-model="cadastro.rg" class="form-control" type="text"/>
        </div>
      </div>
      <div class="form-group">
        <label for="inputEmail"><i class="bi bi-envelope-fill me-2"></i>EMAIL:</label>
        <input id="inputEmail" v-model="cadastro.email" class="form-control" type="email" list="emailSuggestions"
               placeholder="seuemail@example.com" @input="sugerirDominio"/>
        <datalist id="emailSuggestions">
          <option v-for="(dominio, index) in dominiosPopulares" :key="index"
                  :value="email + dominio"></option>
        </datalist>
      </div>

      <div class="form-group">
        <div class="col-md-12 form-group">
          <quill-editor v-model="observacoes"></quill-editor>
        </div>
      </div>

      <button type="submit" class="btn btn-primary" @click.prevent="createCliente">Salvar</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import QuillEditor from "vue-quill-editor/src/editor.vue";
import {toast} from "vue3-toastify";

export default {
  name: "NewCliente",
  components: {QuillEditor},
  data() {
    return {
      cadastro: {
        nome: null,
        sobrenome: null,
        cep: null,
        endereco: null,
        cidade: null,
        bairro: null,
        numero: null,
        cellphone: null,
        rg: null,
        cpfOrCnpj: null,
        email: null,
        uf: null,
        categoria: null
      },
      dominiosPopulares: ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'],
      cpfValid: true,

      observacoes: null,
      categoria: null
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.params._id !== from.params._id) {
        this.cadastro.categoria = this.$route.params._id
        this.categoria = this.$route.params._id
        console.log(this.$route.params);
      }
    }
  },
  mounted() {
    this.cadastro.categoria = this.$route.params._id
    this.categoria = this.$route.params._id
    console.log(this.$route.params);
  },
  methods: {
    async createCliente() {
      this.cadastro.categoria = this.cadastro.categoria.toUpperCase()
      try {

        if (this.cadastro.categoria === 'CLIENTE') {

          const cadastro = this.cadastro;
          cadastro.categoria = null
          await axios.post(`api/cliente`, this.cadastro);
          toast(`${this.categoria.toLowerCase()} criado com sucesso`, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          })

        } else {
          this.cadastro.razaoSocial = this.cadastro?.nome
          this.cadastro.fantasia = this.cadastro?.sobrenome
          await axios.post(`api/fornecedor`, this.cadastro);

          toast(`${this.categoria.toLowerCase()} criado com sucesso`, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          })
        }
      } catch (e) {
        toast(`Erro ao criar ${this.categoria.toLowerCase()}: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          type: "error",
          theme: "dark"
        })
        this.cadastro.categoria = this.categoria
        return
      }
      await this.$router.push(`/cadastro/${this.categoria.toLowerCase()}/list`);

    },
    sugerirDominio() {
      // Sugere domínios apenas se "@" foi digitado e não há outro ponto após o "@"
      if (this.email.includes('@') && !this.email.split('@')[1].includes('.')) {
        this.dominiosPopulares = ['gmail.com', 'outlook.com', 'yahoo.com.br'];
      } else {
        this.dominiosPopulares = [];
      }
    },
    formatarCelular() {
      this.cadastro.cellphone = this.cadastro.cellphone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      this.cadastro.cellphone = this.cadastro.cellphone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    },
    async fetchAddress() {
      if (this.cep.length >= 8) {
        try {
          this.cep = this.cep.replace(/\D/g, '');
          const response = await fetch(`https://viacep.com.br/ws/${this.cep}/json/`);
          const data = await response.json();
          this.cadastro.endereco = data.logradouro
          this.cadastro.bairro = data.bairro
          this.cadastro.cidade = data.localidade
          this.cadastro.uf = data.uf
        } catch (error) {
          console.error("Erro ao buscar o endereço:", error);
        }
      }
    },
  },
};
</script>

<style>
.custom-select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background: white;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
}

.custom-select li {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-select li:hover {
  background-color: #f5f5f5;
}
</style>
