<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div class="row col-12">
                <div class="col-6">
                  <div class="col-4">
                    <img src="@/assets/logo.png" class="img" style="width: 50px" alt="main_logo"/>
                  </div>
                  <div class="d-lg-flex">
                    <div>
                      <p class="mb-0">
                        Quer enviar uma lista como essa para seu cliente? <br>
                        <a class="bold"
                           href="https://wa.me/5551980336681?text=Ol%C3%A1%2C+vim+pelo+Ecommerce+da+Tech+Phone">Use
                          a Tech Phone!</a>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="mt-4 ms-auto ">
                  <div class="dropdown d-inline">
                    <soft-button
                        id="navbarDropdownMenuLink2"
                        color="dark"
                        variant="outline"
                        class="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                      Grupo
                    </soft-button>
                    <ul
                        class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                        aria-labelledby="navbarDropdownMenuLink2"
                        style>
                      <li>
                        <a class="dropdown-item border-radius-md"
                           :class="{ selected: status === 'DISPONIVEL' }"
                           @click.stop="status = 'DISPONIVEL'"
                        >DISPONIVEL
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item border-radius-md"
                           :class="{ selected: status === 'NO_CAMINHO' }"
                           @click.stop="status = 'NO_CAMINHO'"
                        >NO CAMINHO
                        </a>
                      </li>
                      <li>
                        <hr class="horizontal dark my-2"/>
                      </li>
                      <li class="px-4 mt-2">
                        <button class="btn btn-sm btn-primary" @click.prevent="getProdutos()">Filtrar</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal para visualização do carrossel -->
          <div id="carrosselModal" class="modal fade" tabindex="-1" aria-labelledby="carrosselModalLabel"
               aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-danger btn-close" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <!-- Aqui será injetado o conteúdo do carrossel dinamicamente -->
                  <div id="dynamicCarousel" class="carousel slide" data-bs-ride="carousel">
                    <!-- Indicadores do carrossel -->
                    <div class="carousel-indicators">
                      <button v-for="(link, index) in selectedProduct.links" :key="'indicator-' + index" type="button"
                              data-bs-target="#dynamicCarousel" :data-bs-slide-to="index"
                              :class="{ 'active': index === 0 }" aria-current="index === 0"
                              :aria-label="'Slide ' + (index + 1)"></button>
                    </div>
                    <!-- Conteúdo do carrossel -->
                    <div class="carousel-inner">
                      <div v-for="(file, index) in selectedProduct.links" :key="'slide-' + index"
                           :class="['carousel-item', { 'active': index === 0 }]">
                        <template v-if="isImage(file.type)">
                          <img :src="file.url" :alt="file.name" class="d-block w-100" style="object-fit: cover;">
                        </template>
                        <template v-else>
                          <video :src="file.url" controls class="d-block w-100" style="object-fit: cover;"></video>
                        </template>
                      </div>
                    </div>
                    <!-- Controles do carrossel -->
                    <button class="carousel-control-prev" type="button" data-bs-target="#dynamicCarousel"
                            data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Anterior</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#dynamicCarousel"
                            data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Próximo</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <!-- Remova a tabela se não for mais necessária -->
              <div class="row">
                <div v-for="produto in produtos" :key="produto.id" class="col-sm-2 mb-3">
                  <div class="card text-white shadow-sm shadow-lg custom-card" style="height: auto; max-width: 100%;">
                    <div class="card-title text-center mt-2">
                      <small class="badge" :class="produto.status === 'NO_CAMINHO' ? 'bg-info' : 'bg-success'">{{
                          produto.status === 'NO_CAMINHO' ? 'NO CAMINHO' : produto.status
                        }}</small>
                    </div>
                    <div :id="'carouselProduto' + produto.id" class="carousel slide" data-bs-ride="false"
                         style="max-height: 200px; overflow: hidden;">
                      <div class="carousel-inner">
                        <div v-for="(file, index) in produto.links" :key="index"
                             :class="['carousel-item', { 'active': index === 0 }]">
                          <div class="d-flex justify-content-center align-items-center" style="min-height: 50px;">
                            <template v-if="isImage(file.type)">
                              <img :src="file.url" :alt="file.name" class="d-block w-100" style="object-fit: cover;">
                            </template>
                            <template v-else>
                              <video :src="file.url" controls class="d-block w-100" style="object-fit: cover;"
                                     @click.stop></video>
                            </template>
                          </div>
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button"
                              :data-bs-target="'#carouselProduto' + produto.id" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Anterior</span>
                      </button>
                      <button class="carousel-control-next" type="button"
                              :data-bs-target="'#carouselProduto' + produto.id" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Próximo</span>
                      </button>
                    </div>

                    <div v-if="produto.links && produto.links.length" class="text-center mt-2">
                      <button class="btn btn-light btn-sm" @click="openCarouselModal(produto)">Ver mais
                      </button>
                    </div>

                    <div class="card-body text-center">
                      <h5 class="card-title">{{ produto.modelo }} {{ produto.cor }} {{ produto.armazenamento }}
                        {{ produto.isNovo ? ' NOVO' : '' }} {{ produto.grade }}
                        {{ produto.bateria === null || produto.isNovo ? '' : produto.bateria + '%' }}</h5>
                    </div>
                    <div class="card-footer text-center">
                      <h6 class="card-subtitle mb-2 text-muted">
                        <!-- Formatação de preço -->
                        {{ currencyMixin.methods.formattedAmount(produto.precoVenda) }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import axios from "axios";
import {currencyMixin} from "./mixins/currencyMixin";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "EcommerceList",
  components: {SoftButton},
  data() {
    return {
      produtos: null,
      subdomain: null,
      hash: null,
      modalContentUrl: '', // URL do conteúdo a ser exibido no modal
      modalContentType: '', // Tipo do conteúdo: 'image' ou 'video'
      modalContentName: '', // Nome (alt) do conteúdo para acessibilidade
      selectedProduct: {},
      status: null
    }
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    }
  },
  mounted() {
    const host = window.location.hostname;
    this.subdomain = host.split('.')[0];
    this.hash = this.$route.params.hash
    this.getProdutos()
  },
  methods: {
    openCarouselModal(produto) {
      this.selectedProduct = produto; // Define o produto selecionado

      const bootstrap = this.$store.state.bootstrap;

      // Abre o modal
      this.$nextTick(() => {
        const modal = new bootstrap.Modal(document.getElementById('carrosselModal'));
        modal.show();
      });
    },
    isImage(type) {
      return type.startsWith('.png') || type.startsWith('.jpeg') || type.startsWith('.jpg');
    },
    isVideo(type) {
      return type.startsWith('.mp4') || type.startsWith('.mp3');
    },
    async getProdutos() {
      await axios.get(`api/ecommerce`, {
        params: {hash: this.hash, status: this.status, name: this.subdomain}
      }).then(response => this.produtos = response.data)
    },
    formatImei(imei) {
      if (imei > 3) {
        return '...' + imei.slice(-3);
      }
    },
  }
};
</script>

<style scoped>
.custom-card {
  transition: background-color 0.3s ease;
  font-size: 0.8rem; /* Tamanho da fonte reduzido */
}

.custom-card:hover {
  background-color: whitesmoke; /* Um preto mais claro para o efeito de hover */
  cursor: pointer; /* Opcional: muda o cursor para indicar que é clicável */
}

.carousel-inner img, .carousel-inner video {
  height: 200px; /* Altura fixa para imagens e vídeos */
}

.card-body, .card-footer {
  padding: 0.5rem; /* Padding reduzido */
}

.btn-sm {
  padding: 0.25rem 0.5rem; /* Botões menores */
  font-size: 0.7rem;
}

.carousel-item video {
  z-index: 1; /* Ajusta conforme necessário */
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
}

.modal-dialog {
  max-width: 80%; /* Aumenta a largura do modal */
}

.modal-dialog .carousel-inner img, .modal-dialog .carousel-inner video {
  height: 400px; /* Define a altura das imagens e vídeos do carrossel */
  object-fit: cover; /* Mantém a proporção ao ajustar a altura */
}

.modal-dialog .carousel-control-prev, .modal-dialog .carousel-control-next {
  width: 40px; /* Ajusta a largura dos botões para serem mais estreitos */
  height: 40px; /* Ajusta a altura, se necessário */
  top: calc(50% - 20px); /* Centraliza verticalmente, ajuste conforme necessário */
}

.modal-dialog .carousel-control-prev {
  left: -60px; /* Ajusta para posicionar fora do carrossel */
}

.modal-dialog .carousel-control-next {
  right: -60px; /* Ajusta para posicionar fora do carrossel */
}
</style>

