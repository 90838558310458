<template>
  <Modal :show-modal="showModal" :title="title" label-button="Salvar mudanças"
         @update:showModal="emitModal($event)"
         @update="update()">
    <div class="container">

      <form class="mt-2">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-3">
              <label for="acessorio">Tipo produto:</label>
              <select v-model="produto.tipoProduto" :disabled="produto.id" class="form-control">
                <option value="IPHONE">APARELHO</option>
                <option value="ACESSORIO">ACESSÓRIO</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="produto.tipoProduto === 'ACESSORIO'">
          <form>
            <div class="form-group col-12 row">
              <div v-if="(acessorios.length > 0 && produto.tipoProduto === 'ACESSORIO') && !produto.id"
                   class="col-12">
                <label for="acessorio">Ou Selecione um existe:</label>
                <Multiselect
                    v-model="produtoSelecionado"
                    :options="acessorios"
                    :custom-label="customLabel"
                    :multiple="false"
                    @select="selectProduto(produtoSelecionado)"
                    @deselect="deselect()">
                </Multiselect>
              </div>
            </div>

            <!-- Campos padrão -->
            <div class="form-group col-12 row">
              <div class="col-4">
                <label for="acessorio">Acessório:</label>
                <input
                    v-model="produto.modelo"
                    class="form-control"
                    :disabled="disabled"
                />
              </div>

              <div class="col-6">
                <label for="modeloAplicado">Modelos aplicados</label>
                <multiselect v-model="produto.modelosAplicadosTransient" :options="$store.state.iphones"
                             :multiple="true"
                             :close-on-select="false"
                             :clear-on-select="false"
                             :preserve-search="true" placeholder="Selecione os modelos" :preselect-first="true">
                  <template #selection="{ values, isOpen }">
                    <span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{ values.length }}
                      modelos selecionados
                    </span>
                  </template>
                </multiselect>
              </div>

              <div class="col-4">
                <label for="quantidade">Quantidade:</label>
                <input v-model="produto.quantidade" type="number" class="form-control">
              </div>

              <div class="col-md-4">
                <label>Cor:</label>
                <input
                    v-model="produto.cor"
                    class="form-control"
                    placeholder="Digite a cor"
                />
              </div>
            </div>
          </form>
        </div>


        <div v-else>
          <div class="form-group">

            <label>Modelo:</label>
            <input
                v-model="produto.modelo"
                list="iphone-models"
                class="form-control"
            />
            <datalist id="iphone-models">
              <option v-for="iphone in $store.state.iphones" :key="iphone">{{ iphone }}</option>
            </datalist>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <div class="mb-3">
                <div class="form-check form-switch p-0">
                  <div class="d-flex flex-column-reverse gap-1">
                    <input v-model="produto.isVisibleInPriceList" class="form-check-input ms-0" type="checkbox"
                           role="switch" checked/>
                    <label class="form-check-label" for="switchCheckLabelTop">Visível no Ecommerce?</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="mb-3">
                <div class="form-check form-switch p-0">
                  <div class="d-flex flex-column-reverse gap-1">
                    <input id="switchCheckLabelTop" v-model="produto.isNovo" class="form-check-input ms-0"
                           type="checkbox"
                           role="switch"/>
                    <label class="form-check-label" for="switchCheckLabelTop">É lacrado (novo) ?</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row col-12">
            <div class="d-flex align-items-center mb-3"> <!-- Contêiner flexível para alinhar o título e o botão -->
              <h5 class="mb-0">Fiscal</h5>
              <button class="btn btn-link p-0 ms-2" style="background:none; border:none; cursor:pointer;"
                      @click.prevent="toggleFields">
                <i v-if="!showFields" class="fas fa-plus"></i>
                <i v-else class="fas fa-minus"></i>
              </button>
            </div>

            <div v-if="showFields" class="col-3">
              <label>CFOP</label>
              <input v-model="produto.cfop" class="form-control" @input.prevent="clearInput()">
            </div>
            <div v-if="showFields" class="col-3">
              <label>NCM</label>
              <input v-model="produto.ncm" class="form-control" @input.prevent="clearInput()">
            </div>
            <div v-if="showFields" class="col-3">
              <label>CSOSN</label>
              <input v-model="produto.cst" class="form-control" @input.prevent="clearInput()">
            </div>
          </div>


          <div class="form-group row">
            <div class="col-md-6">
              <div class="mb-3">
                <label>IMEI</label>
                <input v-model="produto.imei" class="form-control" @input.prevent="clearInput()">
              </div>
            </div>

            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">Bateria:</label>
                <div class="input-group">
                  <input v-model="produto.bateria"
                         type="text" class="form-control"
                         @input.prevent="formatBateria">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="mb-3">
                <label>Grade:</label>
                <input v-model="produto.grade" type="text" class="form-control">
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label>Cor:</label>
              <input
                  v-model="produto.cor"
                  class="form-control"
                  placeholder="Digite a cor"
              />
            </div>

            <!-- Campo Armazenamento -->
            <div class="col-md-4">
              <div class="form-group">
                <label for="armazenamento">Armazenamento:</label>
                <input v-model="produto.armazenamento" list="armazenamentos" class="form-control"/>
                <datalist id="armazenamentos">
                  <option v-for="opcao in $store.state.armazenamentos" :key="opcao" :value="opcao">
                    {{ opcao }}
                  </option>
                </datalist>
              </div>
            </div>

            <div class="col-md-3">
              <div class="mb-3">
                <label class="form-label">Número de serie</label>
                <input v-model="produto.serie" class="form-control" type="text"/>
              </div>
            </div>
          </div>

          <div class="form-group row">

            <div class="col-6 mb-3">
              <label for="garantiaApple" class="form-label">Garantia apple</label>
              <input v-model="produto.garantiaApple" type="date" class="form-control">
            </div>

            <div class="col-6 mb-3">
              <label>Garantia pós venda em meses:</label>
              <select v-model="produto.garantia" class="form-control">
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="8">8</option>
                <option value="12">12</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 row">
          <div class="col-md-4 mb-3">
            <label for="precoCusto" class="form-label">Preço de Custo</label>
            <CurrencyInput
                v-model="produto.precoCusto"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>


          <div class="col-md-4 mb-3">
            <label for="precoVenda" class="form-label">Preço Mínimo de Venda</label>
            <CurrencyInput
                v-model="produto.precoMinimoVenda"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>

          <div class="col-md-4 mb-3">
            <label for="precoVenda" class="form-label">Preço de Venda</label>
            <CurrencyInput
                v-model="produto.precoVenda"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="observacao" class="form-label">Observação</label>
          <textarea id="observacao" v-model="produto.observacao" class="form-control"
                    rows="3"></textarea>
        </div>
      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";
import CurrencyInput from "@/CurrencyInput.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "ModalProduto",
  components: {Multiselect, CurrencyInput, Modal},
  props: {
    isSearch: Boolean,
    showModal: Boolean,
    title: {type: String, default: 'Altere o produto'},
    precoCusto: {type: Number, default: null},
    precoVenda: {type: Number, default: null},
    precoMinimoVenda: {type: Number, default: null},
    quantidade: {type: Number, default: 0},
    produtoDto: {type: Object},
  },
  emits: ['update', 'update:showModal'],
  data() {
    return {
      abaAtiva: 'produto',
      acessorios: [],
      produto: {},
      modelosSelecionados: [],
      showFields: false,
      originalProduct: null,
      shouldEmitUpdate: false,
      disabled: false,
      produtoSelecionado: null
    }
  },
  created() {
    this.produto = this.produtoDto === null ? {} : this.produtoDto;
    if (!this.produto) {
      this.produto = this.getDefaultValue()
    }
    if (this.precoCusto) {
      this.produto.precoCusto = this.precoCusto
    }

    if (this.precoVenda) {
      this.produto.precoVenda = this.precoVenda
    }

    if (this.quantidade) {
      this.produto.quantidade = this.quantidade
    }

    if (this.precoMinimoVenda) {
      this.produto.precoMinimoVenda = this.precoMinimoVenda
    }

    if (this.produto.cst == null) {
      this.produto.cst = 102
    }
    if (this.produto.cfop == null) {
      this.produto.cfop = 5102
    }
    if (this.produto.ncm == null) {
      this.produto.ncm = "8517.62.62"
    }
    if (this.produto?.garantiaApple) {
      this.produto.garantiaApple = currencyMixin.methods.longToDateSimples(this.produto?.garantiaApple)
    }
    this.originalProduct = JSON.stringify(this.produtoDto)
    if (this.produto?.isVisibleInPriceList === null) {
      this.produto.isVisibleInPriceList = true
    }

    if (this.isSearch) {
      this.searchAcessrios();
    }
  },
  methods: {
    selectProduto(produto) {
      this.produto.modelo = produto.modelo;
      this.produto.modelosAplicados = produto.modelosAplicados;
      this.produto.modelosAplicadosTransient = produto.modelosAplicadosTransient;
      this.produto.cor = produto.cor;
      this.produto.quantidade = 0;
      this.produto.id = produto.id;
      this.produto.precoCusto = 0;
      this.produto.precoMinimoVenda = 0;
      this.produto.precoVenda = 0;
      this.disabled = true
    },
    deselect() {
      this.produto.modelo = null;
      this.produto.modelosAplicados = null;
      this.produto.modelosAplicadosTransient = null;
      this.produto.cor = null;
      this.produto.quantidade = 0;
      this.produto.id = null;
      this.produto.precoCusto = 0;
      this.produto.precoMinimoVenda = 0;
      this.produto.precoVenda = 0;
      this.disabled = false
    },
    customLabel({modelo, precoCusto, precoVenda, modelosAplicadosTransient}) {
      return `${modelo} - ${modelosAplicadosTransient == null ? '' : modelosAplicadosTransient}, Custo: ${precoCusto}, Venda: ${precoVenda}`
    },
    async searchAcessrios() {
      const response = await axios.get(`api/produto`, {
        params: {
          tipoProduto: 'ACESSORIO',
        }
      });
      this.acessorios = response.data
    },
    toggleFields() {
      this.showFields = !this.showFields; // Alterna a visibilidade dos campos
    },
    emitModal(event) {
      if (this.shouldEmitUpdate) {
        if (this.produto.modelo == null || this.produto.precoCusto == null) {
          this.shouldEmitUpdate = false; // Resetar a flag
          return;
        }
      }
      this.$emit('update:showModal', event);
    },
    update() {
      this.shouldEmitUpdate = true; // Setar a flag antes das verificações
      if (this.produto.modelo == null || this.produto.precoCusto == null) {
        toast(`Adicione um modelo e preço de custo antes de salvar`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        this.shouldEmitUpdate = false; // Resetar a flag se a verificação falhar
        return
      }
      this.produto.garantiaApple = currencyMixin.methods.converterDataParaLong(this.produto?.garantiaApple)
      if (this.originalProduct !== JSON.stringify(this.produto)) {
        this.$emit('update', this.produto)
      } else {
        this.produto.garantiaApple = currencyMixin.methods.longToDateSimples(this.produto?.garantiaApple)
      }
      this.$emit('update:showModal', false)
    },
    clearInput() {
      this.produto.imei = this.produto.imei.replace(/\D/g, '');
    },
    formatBateria() {
      this.produto.bateria = this.produto.bateria.replace(/\D/g, '');
    },
    getDefaultValue() {
      return {
        id: null,
        accountId: null,
        quantidade: 0,
        modelo: null,
        imei: null,
        serie: null,
        precoCusto: null,
        precoMinimoVenda: null,
        precoVenda: null,
        isVisibleInPriceList: true,
        isNovo: false,
        grade: null,
        descricao: null,
        cor: null,
        armazenamento: null,
        bateria: null,
        garantiaApple: null,
        garantia: null,
        contents: [],
        observacao: '',
        dataEntrada: null,
        dataSaida: null,
        status: null,
        tipoProduto: null,
        ncm: null,
        cfop: null,
        cst: null,
      }
    }
  }
}
</script>