<template>
  <div class="container-fluid">
    <div
        class="mt-4 page-header min-height-150 border-radius-xl"
        :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="form-group col-2">
          <label>Vendedor:</label>
          <select v-model="vendedor" class="form-control">
            <option v-for="funcionario in funcionarios" :key="funcionario.id" :value="funcionario.id">
              {{ funcionario.completedName }}
            </option>
          </select>
        </div>
        <div class="multisteps-form">
          <div class="row">
            <!--form panels-->
            <div class="row">
              <div class="m-auto col-12 col-lg-8">
                <form class="mb-8 multisteps-form__form">
                  <div
                      class="p-3 bg-white card border-radius-xl"
                      data-animation="FadeIn"
                  >
                    <h5 class="font-weight-bolder">Informações dos produtos</h5>
                    <div class="multisteps-form__content">
                      <div class="mt-3 row">
                        <div class="col-12 col-sm-8">
                          <div class="form-floating">
                            <input
                                id="modeloIphone"
                                v-model="produtoAtual.modelo"
                                class="form-control"
                                type="text"
                                placeholder="Modelo do Iphone"
                                @input="filterModelos"
                            >
                            <label for="modeloIphone" class="form-label">Modelo do Iphone:</label>
                          </div>
                          <ul v-if="filteredModelos.length" class="suggestions-list">
                            <li
                                v-for="produto in filteredModelos"
                                :key="produto.id"
                                @click="selectModelo(produto)">
                              {{ produto.modelo }} {{ produto.armazenamento }} {{ produto.cor }} -
                              {{ formattedAmount(produto.precoVenda) }} - {{ produto.imei }}
                            </li>
                          </ul>
                        </div>
                        <div class="mt-3 col-12 col-sm-4 mt-sm-0 form-floating">
                          <CurrencyInput
                              id="precoVenda"
                              v-model="produtoAtual.precoVenda"
                              class="multisteps-form__input form-control"
                              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                          />
                          <label for="precoVenda" class="form-label">Preço:</label>
                        </div>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <soft-button
                            type="button"
                            color="light"
                            variant="gradient"
                            class="js-btn-next"
                            title="Next"
                            :disabled="!produtoAtual || !vendedor"
                            @click.prevent="addProduto"
                        >Adicionar Produto
                        </soft-button>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <soft-button
                            type="button"
                            color="dark"
                            variant="gradient"
                            class="js-btn-next"
                            title="Next"
                            @click.prevent="showModal()"
                        >Criar uma encomenda
                        </soft-button>
                      </div>
                    </div>
                  </div>

                </form>
                <!-------MODAL--------->
                <ModalProduto v-if="isModalVisible" title="Crie um produto para a encomenda"
                              :produto-dto="null"
                              :show-modal="isModalVisible"
                              @update:showModal="isModalVisible = $event"
                              @update="createEncomenda($event)">
                </ModalProduto>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import CurrencyInput from "@/CurrencyInput.vue";
import ModalProduto from "@/views/ModalProduto.vue";

export default {
  name: "NewPedido",
  components: {ModalProduto, CurrencyInput, SoftButton},
  data() {
    return {
      showMenu: false,
      isModalVisible: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      vendedor: 1,
      pedido: {},
      produtos: [],
      produtosEstoque: [],
      produtoAtual: {
        id: null,
        modelo: null,
        imei: null,
        cor: null,
        preco: null,
        observacao: null,
        armazenamento: null,
      },
      filteredModelos: [],
      funcionarios: [],
    };
  },
  mounted() {
    this.getProdutos()
    this.getVendedores()
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    formattedAmount(valor) {
      if (valor == null) {
        valor = 0
      }
      let currencyValue = parseFloat(valor);
      return currencyValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    },
    async getVendedores() {
      try {
        const response = await axios.get(`api/funcionario`);
        this.funcionarios = response.data
        const funcionarioId = Number(localStorage.getItem("funcionarioId"));
        const vendedor = this.funcionarios.find(f => f.id === funcionarioId);
        this.vendedor = vendedor.id;
      } catch (error) {
        console.error("Houve um erro ao buscar os dados: ", error);
      }
    },
    async fetchPedidoEmpty() {
      try {
        let response = await axios.post(`api/pedido/empty`, {}, {
          params: {vendedorId: this.vendedor}
        });
        this.pedido = response.data
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },
    async createEncomenda(produto) {
      try {
        const produtos = []
        produtos.push(produto)
        const dto = {
          produtos: produtos,
          vendedorId: this.vendedor
        }

        let response = await axios.post(`api/pedido/encomenda`, dto);
        await this.$router.push(`/ecommerce/pedido/${response.data.id}`);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    filterModelos() {
      if (this.produtoAtual.modelo) {
        this.filteredModelos = this.produtosEstoque.filter(produto =>
            produto.modelo &&
            produto.modelo.toLowerCase().includes(this.produtoAtual.modelo.toLowerCase())
        );
      } else {
        this.filteredModelos = [];
      }
    },
    async getProdutos() {
      try {
        const response = await axios.get(`api/produto`, {
          params: {status: "DISPONIVEL"}
        });
        this.produtosEstoque = response.data;
      } catch (error) {
        console.error("Houve um erro ao buscar os dados:", error);
      }
    },
    selectModelo(produto) {

      this.produtoAtual.modelo = produto.modelo;
      this.produtoAtual.cor = produto.cor;
      this.produtoAtual.armazenamento = produto.armazenamento;
      this.produtoAtual.precoVenda = produto.precoVenda;
      this.produtoAtual.id = produto.id;
      this.produtoAtual.imei = produto.imei;
      this.filteredModelos = [];
    },
    async addProduto() {
      if (!this.produtoAtual.modelo || !this.produtoAtual.precoVenda) {
        this.$swal({
          title: "Erro ao salvar!",
          text: `Prencha todos os campos antes de salvar!`,
          icon: "error",
        });
        return;
      }
      const dto = {
        precoVenda: this.produtoAtual.precoVenda,
        id: this.produtoAtual.id
      }
      await this.fetchPedidoEmpty();
      await axios.put(`api/pedido/produto/` + this.pedido.id, dto)

      this.produtos.push({...this.produtoAtual});
      this.produtoAtual = {modelo: '', preco: ''};
      await this.$router.push(`/ecommerce/pedido/${this.pedido.id}`);
    },
    editProduto(index) {
      this.produtoAtual = this.produtos[index];
      this.produtos.splice(index, 1); // Remove o produto da lista para ser editado novamente
    },
    async removeProduto(idProduto, index) {
      await axios.delete(`api/pedido/produto/${this.pedido.id}/${idProduto}`)
      this.produtos.splice(index, 1);
    },
  },
};
</script>

<style>
.suggestions-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
</style>
