<template>
  <div class="container-fluid">
    <div class="card mt-3">
      <div class="card-body">
        <div class="d-flex col-2">
          <div class="dropdown d-inline">
            <soft-button
                id="navbarDropdownMenuLink2"
                color="dark"
                variant="outline"
                class="dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >Filtros
            </soft-button
            >
            <ul
                class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                aria-labelledby="navbarDropdownMenuLink2"
                style
            >
              <li>
                <a class="dropdown-item border-radius-md"
                   :class="{ selected: status === 'NAO_EMITIDO' }"
                   @click.stop="status = 'NAO_EMITIDO'"
                >Status: NÃO EMITIDO</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md"
                   :class="{ selected: status === 'ERRO' }"
                   @click.stop="status = 'ERRO'"
                >Status: COM ERRO</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md"
                   :class="{ selected: status === 'PROCESSANDO' }"
                   @click.stop="status = 'PROCESSANDO'"
                >Status: PROCESSANDO</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md"
                   :class="{ selected: status === 'PROCESSANDO' }"
                   @click.stop="status = 'PROCESSANDO'"
                >Status: PROCESSANDO</a
                >
              </li>
              <li>
                <a class="dropdown-item border-radius-md"
                   :class="{ selected: status === 'CANCELADO' }"
                   @click.stop="status = 'CANCELADO'"
                >Status: CANCELADO</a
                >
              </li>
              <li>
                <hr class="horizontal dark my-2"/>
              </li>
              <li class="px-4 mt-2">
                <button class="btn btn-sm btn-primary" @click.prevent="loadPedidos()">Filtrar</button>
              </li>
              <li>
                <hr class="horizontal dark my-2"/>
              </li>
              <li>
                <a
                    class="dropdown-item px-4 mt-2 border-radius-md text-danger"
                    @click.prevent="removeFilters();"
                >Remove Filter</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="table-responsive">
        <table id="order-list" class="table table-flush">
          <tbody>
          <tr v-for="fiscal in fiscais" :key="fiscal.id">
            <td class="col-1">
              <div class="dropdown">
                <button id="dropdownMenuButton"
                        class="btn badge badge-success rounded-pill dropdown-toggle" type="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fas fa-bars" aria-hidden="true"></i>
                </button>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#"
                     :class="{'disabled': fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'}"
                     @click.prevent="getStatus(fiscal.id)">
                    Consultar Status
                  </a>
                  <a class="dropdown-item" href="#"
                     :class="{'disabled': fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'}"
                     @click.prevent="reenviar(fiscal.id)">
                    Enviar NFe
                  </a>
                  <a class="dropdown-item"
                     :class="{'disabled': !fiscal.caminhoDanfe}"
                     href="#"
                     @click.prevent="abrir(fiscal.caminhoDanfe)">
                    Imprimir danfe
                  </a>
                  <a class="dropdown-item"
                     href="#"
                     :class="{'disabled': fiscal.status !== 'EMITIDO'}"
                     @click.prevent="showModalCorrecao(fiscal.id)">
                    Emitir carta de correção
                  </a>
                  <a class="dropdown-item"
                     href="#"
                     :class="{'disabled': fiscal.status !== 'EMITIDO'}"
                     @click.prevent="showModalCancelamento(fiscal.id)">
                    Cancelar NFe
                  </a>
                  <a v-if="fiscal.caminhoPdfCartaCorrecao" class="dropdown-item"
                     href="#"
                     @click.prevent="abrir(fiscal.caminhoPdfCartaCorrecao)">
                    Baixar PDF Carta de correção
                  </a>
                  <a v-if="fiscal.caminhoXmlCartaCorrecao" class="dropdown-item"
                     href="#"
                     @click.prevent="abrir(fiscal.caminhoXmlCartaCorrecao)">
                    Baixar XML Carta de correção
                  </a>
                </div>
              </div>
            </td>

            <text-modal v-if="showModal"
                        :id="fiscal.id"
                        :show-modal="showModal"
                        :title="'Carta de correção'"
                        @update:show-modal="showModal = $event"
                        @update="correcao($event)">
            </text-modal>

            <text-modal v-if="modalCancelamento"
                        :id="fiscal.id"
                        :show-modal="modalCancelamento"
                        :title="'Justificativa do cancelamento'"
                        @update:show-modal="modalCancelamento = $event"
                        @update="cancelar($event)">
            </text-modal>


            <td class="font-weight-bold col-1">
              <div>
                <router-link
                    :to="`/fiscal/${fiscal.id}`">
                  <div>
                      <span
                          v-tippy="`Clique para abrir a nota fiscal`"
                          class="badge badge-sm rounded-pill badge-info">Nota: {{ fiscal.id }}
                      </span>
                  </div>
                </router-link>
                <router-link
                    :to="`/ecommerce/pedido/${fiscal.pedidoId}`">
                  <div>
                      <span
                          v-tippy="`Clique para abrir o pedido`"
                          class="badge badge-sm rounded-pill badge-info">Pedido: {{ fiscal.pedidoId }}
                      </span>
                  </div>
                </router-link>
              </div>
            </td>
            <td class="font-weight-bold text-center col-1">
                      <span
                          v-tippy="`${fiscal?.mensagemSefaz === null ? '' : fiscal?.mensagemSefaz}`"
                          class="badge badge-sm rounded-pill"
                          :class='fiscal.status === "ERRO" ? "badge-danger" : (fiscal.status === "EMITIDO" ? "bg-success" : "badge-info")'
                      >{{ fiscal.status === 'NAO_EMITIDO' ? 'NÃO EMITIDO' : fiscal.status }}
                      </span>
            </td>
            <td class="font-weight-bold col-2">
              <div>
                <span class="badge bg-info">{{ fiscal.notaFiscal.natureza_operacao }}</span>
              </div>
            </td>
            <td class="font-weight-bold col-2">
              <a>{{ formatarDataHoraCompleta(fiscal.notaFiscal.data_emissao) }}</a>
            </td>
            <td class="font-weight-bold col-2">
              <span v-if="fiscal.notaFiscal.nome_destinatario.length > 5"
                    v-tippy="`${fiscal.notaFiscal.nome_destinatario}`">
                {{ labelCliente(fiscal.notaFiscal.nome_destinatario) }}
              </span>
              <span v-else>{{ labelCliente(fiscal.notaFiscal.nome_destinatario) }}</span>
            </td>
            <td class="font-weight-bold col-3">
              <div>
                <span>Valor total: {{ formatValue(fiscal.notaFiscal.valor_total) }}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination v-model="page" :records="totalContents" :per-page="pageSize" @paginate="loadPedidos($event)"/>
      </div>
    </div>
  </div>
</template>

<script>

import Pagination from "v-pagination-3";

import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import TextModal from "@/views/TextModal.vue";

export default {
  name: "NFeList",
  components: {
    TextModal,
    SoftButton,
    Pagination,
  },
  data() {
    return {
      fiscais: {},
      startDate: null,
      endDate: null,
      status: null,

      page: 1,
      pageSize: 50,
      totalContents: 0,
      showModal: false,
      modalCancelamento: false,
    };
  },
  created() {
    this.loadPedidos();
  },
  methods: {
    async showModalCorrecao() {
      this.showModal = true
    },
    async showModalCancelamento() {
      this.modalCancelamento = true
    },
    async correcao({id, texto}) {
      try {
        const dto = {
          correcao: texto
        }
        const response = await axios.post(`api/fiscal/nfe/${id}/carta_correcao`, dto);
        toast(`${response.data.mensagem_sefaz}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.codigo} - ${e.response?.data?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.loadPedidos();
    },
    async cancelar({id, texto}) {
      try {
        const dto = {
          justificativa: texto
        };
        const config = {
          data: dto
        };
        const response = await axios.delete(`api/fiscal/nfe/${id}`, config);
        toast(`${response.data.mensagem_sefaz}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.codigo} - ${e.response?.data?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.loadPedidos();
    },
    abrir(caminho) {
      const urlCompleta = `https://api.focusnfe.com.br/${caminho}`;
      window.open(urlCompleta, '_blank');
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async verificarStatus(id) {
      const idToast = toast.loading(
          'Processando envio...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );

      let tentativas = 0;
      const maxTentativas = 10; // Define o número máximo de tentativas
      const intervalo = 2000; // Define o intervalo entre as tentativas em milissegundos (2000ms = 2 segundos)

      while (tentativas < maxTentativas) {
        try {
          const response = await axios.get(`api/fiscal/nfe/status/${id}`);

          // Verifica se o status é diferente de 'processando_autorizacao'
          if (response.data.status !== 'processando_autorizacao') {
            if (response.data.status === 'autorizado') {
              toast.update(idToast, {
                render: `NFe emitida com sucesso!`,
                autoClose: 2000,
                position: toast.POSITION.TOP_RIGHT,
                transition: "flip",
                theme: "dark",
                type: "success",
                isLoading: false,
              })
              return response.data
            }
            toast.update(idToast, {
              render: `${response.data.mensagem_sefaz} - ${response.data.status}`,
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              transition: "flip",
              theme: "dark",
              type: "error",
              isLoading: false,
            })
            return response.data; // Retorna os dados da resposta quando o status for diferente
          }

          // Se o status ainda for 'processando_autorizacao', espera um pouco antes de tentar novamente
          console.log('Status ainda em processamento, aguardando para nova tentativa...');
          await this.delay(intervalo);
        } catch (e) {
          console.log('Erro ao tentar verificar o status:', e);
          break; // Interrompe o loop em caso de erro
        }

        tentativas++;
      }
    },
    async reenviar(id) {
      try {
        await axios.post(`api/fiscal/nfe/${id}`);
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.codigo} - ${e.response?.data?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      try {
        await this.verificarStatus(id)
      } catch (e) {
        console.log(e);
      }
      await this.loadPedidos();
    },
    async getStatus(id) {
      const response = await axios.get(`api/fiscal/nfe/status/${id}`);
      if (response.data.status === 'autorizado') {
        toast(`NFe emitida com sucesso!`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      }
      if (response.data.status === 'erro_autorizacao') {
        toast(`${response.data.mensagem_sefaz} - ${response.data.status}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.loadPedidos();
    },
    formatarDataHoraCompleta(dataISO) {
      const opcoes = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      };
      return new Intl.DateTimeFormat('pt-BR', opcoes).format(new Date(dataISO));
    },
    async removeFilters() {
      this.status = null
      await this.loadPedidos()
    },
    labelCliente(cliente) {
      if (cliente == null) {
        return 'Sem cliente';
      } else {
        if (cliente.length > 10) {
          return cliente.substring(0, 10) + '...';
        } else {
          return cliente;
        }
      }
    },
    formatValue(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    formatValues(itemProduto) {
      return itemProduto.map(item => item.descricao).join(', ');
    },
    async loadPedidos(page) {
      try {
        const queryParams = {
          status: this.status,
          page: page == null ? 0 : page - 1,
          size: this.pageSize
        }
        const response = await axios.get(`api/fiscal`, {
          params: queryParams
        });
        const data = response.data;
        this.fiscais = data.content;
        this.totalContents = data.totalElements
      } catch (error) {
        console.error("Erro ao carregar PDFs:", error);
      }
    },
  }
}

</script>

<style scoped>
.dropdown-menu {
  min-width: 200px; /* Define a largura mínima do menu dropdown */
  background-color: #ffffff; /* Cor de fundo do menu dropdown */
  border: 1px solid #ddd; /* Borda do menu dropdown */
  border-radius: 0.25rem; /* Arredondamento das bordas */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para adicionar profundidade */
}

.dropdown-item {
  color: #495057; /* Cor do texto dos itens */
  padding: 10px 20px; /* Espaçamento interno para aumentar a área de clique e melhorar a legibilidade */
  transition: background-color 0.2s ease-in-out; /* Transição suave para o efeito de hover */
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse ou focar */
  color: #0056b3; /* Cor do texto ao passar o mouse ou focar */
  text-decoration: none; /* Remove o sublinhado do texto ao passar o mouse ou focar */
}

.dropdown-item:active {
  background-color: #e9ecef; /* Cor de fundo ao clicar */
  color: #004085; /* Cor do texto ao clicar */
}
</style>