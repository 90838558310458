<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
            is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-primary"
            :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
                class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="text-center card-body px-lg-5 py-lg-5">
                  <div class="mb-4 text-center text-muted">
                    <h2>2-Step Verification</h2>
                  </div>
                  <div class="row gx-2 gx-sm-3">
                    <div class="col">
                      <div class="form-group">
                        <input
                            v-model="code1"
                            ref="input1"
                            @input="moveFocus(1, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                            v-model="code2"
                            ref="input2"
                            @input="moveFocus(2, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                            v-model="code3"
                            ref="input3"
                            @input="moveFocus(3, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                            ref="input4"
                            v-model="code4"
                            @input="moveFocus(4, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                            v-model="code5"
                            ref="input5"
                            @input="moveFocus(5, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                            v-model="code6"
                            ref="input6"
                            @input="moveFocus(6, $event)"
                            type="text"
                            class="form-control form-control-lg"
                            maxlength="1"
                            autocomplete="off"
                            autocapitalize="off"
                        />
                      </div>
                    </div>

                  </div>
                  <div class="text-center">
                    <soft-button
                        class="mt-0 mb-3"
                        variant="gradient"
                        color="warning"
                        full-width
                        @click.prevent="checkMfa"
                    >Send code
                    </soft-button>
                    <span class="text-sm text-muted"

                    >Não sabe qual codigo é esse? Entre em contato com seu adminstrador</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
                class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                  class="m-3 position-relative bg-gradient-warning h-100 px-7 border-radius-lg d-flex flex-column justify-content-center"
              >
                <img
                    src="../../../assets/img/shapes/pattern-lines.svg"
                    alt="pattern-lines"
                    class="position-absolute opacity-4 start-0"
                />
                <div class="position-relative">
                  <img
                      class="max-width-500 w-100 position-relative z-index-2"
                      src="../../../assets/img/illustrations/danger-chat-ill.png"
                      alt="chart-ill"
                  />
                </div>
                <h4 class="mt-5 text-white font-weight-bolder">
                  "Attention is the new currency"
                </h4>
                <p class="text-white">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";

const body = document.getElementsByTagName("body")[0];
import {mapMutations} from "vuex";
import axios from "axios";


export default {
  name: "VerificationIllustration",
  components: {
    Navbar,
    SoftButton
  },
  data() {
    return {

      code1: null,
      code2: null,
      code3: null,
      code4: null,
      code5: null,
      code6: null,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async checkMfa() {

      const _id = this.$route.query.query;
      const formatCode = this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
      const dto = {
        code: formatCode
      }
      try {
        const response = await axios.post(`api/auth/mfa?uuid=${_id}`, dto);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('nome', response.data.nome);
        localStorage.setItem('funcionarioId', response.data.funcionarioId);
        localStorage.setItem('role', response.data.role);
        await this.$router.push(`/ecommerce/overview/`);
      } catch (e) {
        this.$swal({
          title: "Credenciais invalidas!",
          text: `Confirme os campos de user e password ou entre em contato com o administrador`,
          icon: "error",
        });
      }
    },
    moveFocus(inputNumber, event) {
      if (event.target.value.length >= 1) {
        let nextInputNumber = inputNumber + 1;
        let nextInputRef = `input${nextInputNumber}`;
        if (this.$refs[nextInputRef]) {
          this.$refs[nextInputRef].focus();
        }
      }
    }
  },
};
</script>
