<template>
  <input ref="inputRef" @change.prevent="update"
  />
</template>

<script>
import {watch} from 'vue'
import {useCurrencyInput} from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number, // Vue 2: value
    options: Object
  },
  emits: ['update', 'change'],
  setup(props) {
    const {inputRef, setOptions, setValue} = useCurrencyInput(props.options)

    watch(
        () => props.modelValue, // Vue 2: props.value
        (value) => {
          setValue(value)
        }
    )

    watch(
        () => props.options,
        (options) => {
          setOptions(options)
        }
    )

    return {inputRef}
  },
  methods: {
    update() {
      this.$emit('update')
      this.$emit('change', event.target.value);
    }
  }
}
</script>