<template>
  <Modal :show-modal="showModal"
         :title=title
         :label-button="'Enviar'"
         @update:showModal="$emit('update:showModal', $event)"
         @update="confirm">
    <div class="row justify-content-center col-12">

      <div class="form-group col-12">
        <label class="form-label">Confirme o texto enviado junto com o arquivo</label>
        <textarea v-model="texto" class="form-control" type="text"></textarea>
        <p class="text-muted">O texto deverá conter de 15 a 1000 caracteres.</p>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/views/Modal.vue";

export default {
  name: "TextModal",
  components: {Modal},
  props: {
    showModal: Boolean,
    title: String,
    id: String
  },
  emits: ['update', 'update:showModal'],
  data() {
    return {
      texto: null
    }
  },
  methods: {
    confirm() {
      const dto = {
        texto: this.texto,
        id: this.id
      }
      this.$emit('update', dto);
    },
  }
}
</script>