<template>
  <Modal :show-modal="showModal" :title="title"
         :label-button="'Salvar'"
         @update:showModal="updateModal($event)">
    <div class="container">
      <div class="row align-items-end"> <!-- Adiciona alinhamento vertical -->
        <div class="col-2">
          <label>Data inicial</label>
          <input v-model="startDate" type="date" class="form-control form-control-sm">
        </div>
        <div class="col-2">
          <label>Data final</label>
          <input v-model="endDate" type="date" class="form-control form-control-sm">
        </div>
        <div class="col-3 d-flex align-items-center">
          <div>
            <span class="badge btn btn-info" @click.prevent="getTransacoesByBanco()">Buscar</span>
          </div>
        </div>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th class="text-center clickable" @click.prevent="sortTable('metodoPagamento')">
            Método de Pagamento
            <font-awesome-icon
                :icon="getSortIcon('metodoPagamento')"
                :style="{ color: sort.split(',')[0] === 'metodoPagamento' ? '#63E6BE' : 'black' }"
                size="sm"/>
          </th>
          <th class="text-center clickable" @click.prevent="sortTable('valor')">
            Valor
            <font-awesome-icon
                :icon="getSortIcon('valor')"
                :style="{ color: sort.split(',')[0] === 'valor' ? '#63E6BE' : 'black' }"
                size="sm"/>
          </th>
          <th class="text-center clickable" @click.prevent="sortTable('tipo')">
            Tipo
            <font-awesome-icon
                :icon="getSortIcon('tipo')"
                :style="{ color: sort.split(',')[0] === 'tipo' ? '#63E6BE' : 'black' }"
                size="sm"/>
          </th>
          <th class="text-center clickable" @click.prevent="sortTable('dataPagamento')">
            Data de Pagamento
            <font-awesome-icon
                :icon="getSortIcon('dataPagamento')"
                :style="{ color: sort.split(',')[0] === 'dataPagamento' ? '#63E6BE' : 'black' }"
                size="sm"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="transacao in transacoes" :key="transacao.dataPagamento">
          <td class="text-center">{{ transacao.metodoPagamento }}</td>
          <td class="text-center">{{ formatValue(transacao.valor) }}</td>
          <td class="text-center">
          <span :class="['badge', 'rounded-pill', transacao.tipo === 'CREDITO' ? 'bg-success' : 'bg-danger']">
            {{ transacao.tipo }}
          </span>
          </td>
          <td class="text-center">{{ formatDate(transacao.dataPagamento) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

import axios from "axios";
import {currencyMixin} from "@/mixins/currencyMixin";
import {faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';


export default {
  name: "ModalTransacoes",
  components: {Modal, FontAwesomeIcon},
  props: {
    showModal: Boolean,
    title: String,
    dataInicial: String,
    dataFinal: String,
    banco: Object
  },
  emits: ['update:showModal', 'update'],
  data() {
    return {

      disabled: false,
      valor: 0,
      transacao: {
        metodoPagamento: null,
        valor: null,
        tipo: null,
        dataPagamento: null,
      },
      faArrowUp,
      faArrowDown,
      startDate: null,
      endDate: null,
      sort: 'dataPagamento,desc',
      transacoes: []
    }
  },
  created() {
    this.startDate = currencyMixin.methods.getFirstDayOfMonthUTC()
    this.endDate = currencyMixin.methods.getLastDayOfMonthUTC()
    this.getTransacoesByBanco();
  },
  methods: {
    sortTable(field) {
      let sortDir = 'asc';
      if (this.sort.startsWith(field)) {
        sortDir = this.sort.endsWith('desc') ? 'asc' : 'desc';
      }
      this.sort = `${field},${sortDir}`;
      this.getTransacoesByBanco();
    },
    getSortIcon(field) {
      const strings = this.sort.split(',');
      if (strings[0] === field) {
        return strings[1].includes('asc') ? faArrowUp : faArrowDown;
      }
      return faArrowUp;
    },
    formatValue(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    formatDate(date) {
      return currencyMixin.methods.longToDateAndHoraBrasil(date)
    },
    async getTransacoesByBanco() {
      const params = {
        dataInicial: currencyMixin.methods.converterDataParaLong(this.startDate),
        dataFinal: currencyMixin.methods.converterDataParaLong(this.endDate),
        sort: this.sort
      }
      const response = await axios.get(`api/financeiro/banco/${this.banco.id}`, {
        params: params
      });
      this.transacoes = response.data
    },
    async updateModal(event) {
      await this.$emit('update:showModal', event)
      await this.$emit('update')
    },
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>