<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!-- Card header -->
        <div class="pb-0 card-header">
          <div class="row mt-2 col-12">
            <div class="col-sm-4 custom-card-size">
              <div class="card border">
                <div class="card-body">
                  <h6 class="card-title">
                    <i class="fa fa-money"></i> Contas abertas a vencer nos próximos 7 dias
                  </h6>
                  <p>
                    {{ formatValue(overview.somaTotalProximo7diasAVencer) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-4 custom-card-size">
              <div class="card border">
                <div class="card-body">
                  <h6 v-tippy="{ content: 'Clique para filtrar' }" class="card-title badge btn badge bg-danger" 
                      @click="loadOnlyVencidos">
                    <i class="fa fa-money"></i> Total de contas vencidas
                  </h6>
                  <p>
                    {{ formatValue(overview.somaTotalAtrasado) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-0 card-header">
          <div class="d-lg-flex col">

            <div class="row align-items-end"> <!-- Adiciona alinhamento vertical -->
              <div class="col">
                <label>Data inicial</label>
                <input v-model="startDate" type="date" class="form-control form-control-sm">
              </div>
              <div class="col">
                <label>Data final</label>
                <input v-model="endDate" type="date" class="form-control form-control-sm">
              </div>
              <div class="col d-flex flex-column align-items-center">
                <div>
                  <span class="badge btn btn-info mb-1" @click.prevent="loadFinanceiros()">Buscar</span>
                </div>
                <div>
                  <span class="badge btn btn-danger mb-0"
                        @click.prevent="startDate = null; endDate = null; loadFinanceiros()">Limpar filtros
                  </span>
                </div>
              </div>
            </div>

            <div class="my-auto ms-auto ">
              <div class="my-auto ms-auto">
                <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    @click.prevent="showModal()"
                >+&nbsp; Adicionar conta</a
                >
              </div>
            </div>

            <ModalFinanceiro v-if="isModalVisible" title="Crie uma despesa"
                             :show-modal="isModalVisible"
                             @update:showModal="isModalVisible = $event; loadFinanceiros()">
            </ModalFinanceiro>

            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="dropdown">
                <soft-button
                    id="navbarDropdownMenuLink2"
                    color="dark"
                    variant="outline"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >Filtros
                </soft-button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'ABERTO' }"
                     @click.prevent="status = 'ABERTO'; loadFinanceiros()">
                    Status: ABERTO
                  </a>
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'FECHADO' }"
                     @click.prevent="status = 'FECHADO'; loadFinanceiros()">
                    Status: FECHADO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-flush">
          <tbody>
          <tr v-for="(financeiro, index) in financeiros" :key="index" class="card"
              @click="cardSelecionado = financeiro">
            <td class="d-flex justify-content-between align-items-center">
              <div class="col">
                <span v-if="isAtrasado(financeiro.dataVencimento)" class="badge btn bg-danger">{{ 'EM ATRASO'  }}</span>
                <h6>{{
                    longToDateFormatted(financeiro.dataVencimento) == null ? 'Sem data' : longToDateFormatted(financeiro.dataVencimento)
                  }} </h6>
                <h5 v-if="!isEditableFornecedor || cardSelecionado !== financeiro"
                    @click.prevent="setRecebedor(financeiro)">
                  {{ financeiro.nomeRecebedor == null ? 'Sem cadastro' : financeiro.nomeRecebedor }}
                </h5>
                <div v-else class="col-6">
                  <Multiselect v-if="recebedores"
                               v-model="financeiro.recebedor"
                               :options="recebedores"
                               label="name"
                               track-by="id"
                               :multiple="false"
                               @select="updateFinanceiro(financeiro)">
                  </Multiselect>
                </div>
              </div>

              <div class="col-2">
                <span v-if="!isEditable || cardSelecionado !== financeiro"
                      v-tippy="'O financeiro mudará de automaticamente status quando o total pago ser igual ao valor total'"
                      :class='financeiro.status === "FECHADO" ? "badge badge-success badge-sm" : "badge badge-danger badge-sm"'
                      @mouseover="isEditable = true">
                {{ financeiro.status }}
                </span>
                <select v-else-if="isEditable" v-model="financeiro.status"
                        :class='financeiro.status === "FECHADO" ? "badge badge-success" : "badge badge-danger"'
                        class="form-select rounded-pill"
                        @mouseleave="isEditable = false"
                        @change.prevent="updateFinanceiro(financeiro)">
                  <option value="ABERTO" class="badge badge-danger">ABERTO</option>
                  <option value="FECHADO" class="badge badge-success">FECHADO</option>
                </select>
              </div>

              <div v-if="cardSelecionado === financeiro" class="col m-2">
                <Multiselect v-model="financeiro.tipoDespesa"
                             :options="tipoDespesa"
                             label="label"
                             track-by="id"
                             :multiple="false"
                             deselect-label="Você precisa selecionar 1 valor"
                             :allow-empty="false"
                             @select="updateFinanceiro(financeiro)">
                </Multiselect>
              </div>
              <span v-else class="badge rounded-pill bg-success">{{ financeiro.tipoDespesa.label }}</span>
            </td>

            <td>
              <div class="row col-12">
                <div v-if="cardSelecionado === financeiro" class="col-6 d-flex justify-content-start">
                  <span>Valor Total:
                    <CurrencyInput
                        v-model="financeiro.valor"
                        class="form-control"
                        :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                        @blur="editandoValor = false"
                        @update="updateFinanceiro(financeiro)"
                    />
                    </span>
                </div>
                <div v-else class="col-6 d-flex justify-content-start" @click="editandoValor = true">
                  <span>Total: {{ formatValue(financeiro.valor) }}</span>
                </div>
                <h5 class="col-6 d-flex justify-content-end">Pago: {{
                    formatValue(totalPago(financeiro.transacoes))
                  }}</h5>
              </div>
            </td>

            <td v-if="cardSelecionado === financeiro">
              <ul class="list-group">
                <div class="col-12 row">
                  <div class="col-2">
                    <label>
                      Valor
                    </label>
                  </div>
                  <div class="col-2">
                    <label>
                      Método
                    </label>
                  </div>
                  <div class="col-2">
                    <label>
                      Data
                    </label>
                  </div>
                  <div class="col-4">
                    <label>
                      Banco
                    </label>
                  </div>
                </div>
                <li v-for="(transacao, index) in financeiro.transacoes" :key="index"
                    class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="input-group row col-12  d-flex">
                    <div class="col-2">
                      <CurrencyInput
                          v-model="transacao.valor"
                          :disabled="transacao.id"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                      />
                    </div>
                    <div class="col-2">
                      <select v-model="transacao.metodoPagamento" :disabled="transacao.id" class="form-control">
                        <option value="CARTAO">Cartão</option>
                        <option value="DINHEIRO">Dinheiro</option>
                        <option value="PIX">Pix</option>
                      </select>
                    </div>
                    <div class="col-2">
                      <input v-model="transacao.dataPagamento" :disabled="transacao.id" type="date"
                             class="form-control">
                    </div>
                    <div class="col-4">
                      <select v-if="!transacao.id" v-model="transacao.banco" class="form-control">
                        <option v-for="banco in bancos" :key="banco.id" :value="banco.id">
                          {{ banco.nome }} - {{ banco.razaoSocialOrName }} | Conta: {{ banco.conta }} Agência:
                          {{ banco.agencia }}
                        </option>
                      </select>
                      <select v-else disabled class="form-control">
                        <option value="" selected>
                          {{ transacao?.banco?.nome }} - {{ transacao?.banco?.razaoSocialOrName }} | Conta:
                          {{ transacao?.banco?.conta }} Agência:
                          {{ transacao?.banco?.agencia }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2 d-flex align-items-end align-content-end">
                      <div>
                        <button :disabled="isLoading" class="btn btn-sm btn-danger m-1"
                                @click.prevent="excluirTransacao(transacao.id, index, financeiro)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                      <div>
                        <button v-if="!transacao.id" :disabled="isLoading || isDisabled(transacao)"
                                class="btn btn-sm btn-success m-1"
                                @click.prevent="changeTransacao(transacao, financeiro)">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="form-group mt-5">
                <label for="observacao" class="form-label">Observação</label>
                <textarea id="observacao" v-model="financeiro.observacao" class="form-control"
                          rows="3"
                          @change="updateFinanceiro(financeiro)"></textarea>
              </div>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <button :disabled="isLoading" class="btn btn-primary btn-sm"
                          @click.prevent="financeiro.transacoes.push({valor:0})">Adicionar
                    transacao
                  </button>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <button :disabled="isLoading" class="btn btn-sm btn-danger"
                          @click.prevent="excluirConta(financeiro.id)">
                    Excluir essa conta
                  </button>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination v-model="page" :records="totalContents" :per-page="pageSize" @paginate="loadPedidosByDate"/>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from "@/components/SoftButton.vue";

import ModalFinanceiro from "@/views/ModalFinanceiro.vue";
import CurrencyInput from "@/CurrencyInput.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";
import Pagination from "v-pagination-3";

export default {
  name: "FinanceiroAPagar",
  components: {Pagination, Multiselect, CurrencyInput, ModalFinanceiro, SoftButton},
  data() {
    return {
      financeiros: [],
      page: 1,
      totalContents: 0,
      pageSize: 20,
      status: null,
      isEditable: false,
      isEditableFornecedor: false,

      cardSelecionado: null,
      clienteName: null,
      clientes: [],
      dateSelected: 7,
      startDate: null,
      endDate: null,
      isModalVisible: false,
      editandoValor: false,
      isLoading: false,
      tipoDespesa: null,
      showSelect: false,
      overview: null,
      recebedores: null,
      onlyVencidos: false,
      recebedor: null
    }
  },
  created() {
    // this.startDate = currencyMixin.methods.getFirstDayOfMonthUTC();
    // this.endDate = currencyMixin.methods.getLastDayOfMonthUTC();
    this.loadPedidosByDate()
    this.getBancos()
    this.getTiposDespesa()
    this.getRecebedores()
    this.getOverview()
  },
  methods: {
    async setRecebedor(financeiro) {
      this.isEditableFornecedor = true;
      financeiro.recebedor = await this.recebedores.filter(recebedorDto => recebedorDto.id === financeiro.recebedorId && financeiro.nomeRecebedor === recebedorDto.name)
    },
    isAtrasado(dataVencimento) {
      const now = new Date();
      const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
      return dataVencimento <= nowUTC.getTime()
    },
    isDisabled(transacao) {
      return !transacao.valor || !transacao.metodoPagamento || !transacao.banco
    },
    async getOverview() {
      this.isLoading = true
      const res = await axios.get(`api/contasAPagar/overview`);
      this.overview = res.data;
      this.isLoading = false
    },
    async updateFinanceiro(financeiro) {
      this.isLoading = true
      try {
        const data = {
          valor: financeiro.valor,
          desconto: financeiro.metodoPagamento,
          dataVencimento: currencyMixin.methods.converterDataAndHoraParaLong(financeiro.dataVencimento),
          recebedorId: financeiro.recebedor?.id,
          nomeRecebedor: financeiro.recebedor?.name,
          observacao: financeiro.observacao,
          tipoDespesaId: financeiro.tipoDespesa.id,
          status: financeiro.status
        }

        await axios.put(`api/contasAPagar/${financeiro.id}`, data);
        await this.loadFinanceiros()
        toast("Dados alterados!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao savlar: ${e}!`,
        });
      }
      this.isLoading = false
    },
    async changeTransacao(transacao, pedido) {
      if (this.isDisabled(transacao)) {
        return
      }
      this.isLoading = true
      const data = {
        confirmado: true,
        valor: transacao.valor,
        metodoPagamento: transacao.metodoPagamento,
        dataPagamento: currencyMixin.methods.converterDataParaLong(transacao.dataPagamento),
        id: transacao.id,
        bancoId: transacao.banco
      }

      try {
        const axiosResponse = await axios.post(`api/contasAPagar/transacao/${pedido.id}`, data);
        const conta = await axiosResponse.data;
        await this.$nextTick(() => {
          pedido.transacoes = []
          conta.transacoes.forEach(t => {
            t.dataPagamento = currencyMixin.methods.longToDateSimples(t.dataPagamento);
          });
          pedido.transacoes.push(...conta.transacoes);
          pedido.status = conta.status
        });
        toast("Transação adicionada", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao savlar: ${e}!`,
        });
      }
      this.isLoading = false
    },
    async excluirConta(id) {
      this.$swal({
        title: "Deseja excluir essa conta?",
        text: "Excluir essa conta é uma operação sem volta",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Sair",
        confirmButtonText: "Excluir!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.delete(`api/contasAPagar/${id}`)
            await this.loadFinanceiros();
            toast("Conta removida", {
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              transition: "flip",
              theme: "dark"
            })
          } catch (error) {
            this.$swal({
              icon: "error",
              title: `Erro ao deletar: ${error}!`,
            });
          }
          this.$swal({
            title: "Deleted!",
            text: "Conta deletada.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        } else if (
            result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    },
    formatValue(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    async excluirTransacao(id, index, conta) {
      if (!id) {
        conta.transacoes.splice(index, 1);
        toast("Transação removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
        return
      }
      try {
        const axiosResponse = await axios.delete(`api/contasAPagar/transacao/${conta.id}/${id}`);
        const pedido = axiosResponse.data;
        conta.transacoes.splice(index, 1);
        await this.$nextTick(() => {
          conta.status = pedido.status
        });
        toast("Transação removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao deletar: ${e}!`,
        });
      }

    },
    showModal() {
      this.isModalVisible = true;
    },
    async getTiposDespesa() {
      const response = await axios.get(`api/contasAPagar/despesa`);
      this.tipoDespesa = response.data
    },
    async getBancos() {
      const response = await axios.get(`api/financeiro/banco`);
      this.bancos = await response.data.map(m => ({
        id: m.id,
        nome: m.nome,
        razaoSocialOrName: m.razaoSocialOrName ? m.razaoSocialOrName : 'Sem Razao Social',
        conta: m.conta,
        agencia: m.agencia
      }));
    },
    totalPago(transacoes) {
      if (!transacoes || transacoes.length == null) {
        return 0
      }
      return transacoes.reduce((acc, transacao) => acc + transacao.valor, 0);
    },
    longToDateFormatted(timestamp) {
      return currencyMixin.methods.longToDateBrasil(timestamp)
    },
    async loadOnlyVencidos () {
      this.onlyVencidos = true
      this.startDate = null
      this.endDate = null
      this.status = 'ABERTO'
      await this.loadPedidosByDate()
    },
    loadPedidosByDate(page) {
      this.page = page == null ? 1 : page
      this.pageSize = 20
      this.loadFinanceiros()
    },
    async loadFinanceiros() {
      const queryParams = {
        status: this.status == null ? 'ABERTO' : this.status,
        page: this.page - 1,
        size: this.pageSize,
        dataInicial: currencyMixin.methods.converterDataParaLong(this.startDate),
        dataFinal: currencyMixin.methods.converterDataParaLong(this.endDate),
        onlyVencidos: this.onlyVencidos
      }
      try {
        const response = await axios.get(`api/contasAPagar`, {
          params: queryParams
        });
        this.financeiros = response.data.content;
        this.totalContents = response.data.totalElements
        await this.financeiros.forEach(f => {
          f.transacoes.forEach(t => {
            t.dataPagamento = currencyMixin.methods.longToDateSimples(t.dataPagamento);
          });
        })
      } catch (error) {
        console.error("Erro ao carregar Produtos:", error);
      }
    },
    searchCliente() {
      if (this.clienteName.length > 3) {
        axios.get(`api/cliente`, {
          params: {
            nome: this.clienteName
          }
        })
            .then(response => {
              this.clientes = response.data
            })
            .catch(error => {
              console.log(error)
            });
      }
    },
    async getRecebedores() {
      const response = await axios.get(`api/contasAPagar/recebedores`);
      this.recebedores = response.data
    },
  }
};
</script>

<style scoped>
.card {
  border: 1px solid;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card:hover {
  background-color: #f5f5f5;
}

.col-2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.col-2 > div {
  flex: 1;
  text-align: center;
}

.btn-sm i {
  font-size: 1em; /* Ajuste o tamanho conforme necessário */
}

.dropdown-menu {
  min-width: 200px; /* Define a largura mínima do menu dropdown */
  background-color: #ffffff; /* Cor de fundo do menu dropdown */
  border: 1px solid #ddd; /* Borda do menu dropdown */
  border-radius: 0.25rem; /* Arredondamento das bordas */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para adicionar profundidade */
}

.dropdown-item {
  color: #495057; /* Cor do texto dos itens */
  padding: 10px 20px; /* Espaçamento interno para aumentar a área de clique e melhorar a legibilidade */
  transition: background-color 0.2s ease-in-out; /* Transição suave para o efeito de hover */
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse ou focar */
  color: #0056b3; /* Cor do texto ao passar o mouse ou focar */
  text-decoration: none; /* Remove o sublinhado do texto ao passar o mouse ou focar */
}

.dropdown-item:active {
  background-color: #e9ecef; /* Cor de fundo ao clicar */
  color: #004085; /* Cor do texto ao clicar */
}

.custom-card-size .card {
  padding: 10px;
  font-size: 0.8rem;
}

.custom-card-size .card-body {
  padding: 8px;
}
</style>
