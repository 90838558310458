<template>
  <div class="container mt-5 card">
    <div class="card-body">
      <div class="container">
        <div class="row">
          <!-- Coluna à esquerda -->
          <div class="col-md-6">
            <h5 class="bold">Nota Fiscal</h5>
            <h6 class="badge badge-sm rounded-pill"
                :class='fiscal.status === "ERRO" ? "badge-danger" : (fiscal.status === "EMITIDO" ? "bg-success" : "badge-info")'>
              {{ fiscal.status === 'NAO_EMITIDO' ? 'NÃO EMITIDO' : fiscal.status }}</h6>
          </div>

          <!-- Coluna à direita -->
          <div class="col-md-6 d-flex justify-content-end">
            <div class="dropdown">
              <button id="dropdownMenuButton"
                      class="btn badge badge-success rounded-pill dropdown-toggle" type="button"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bars" aria-hidden="true"></i>
              </button>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#"
                   :class="{'disabled': fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'}"
                   @click.prevent="getStatus(fiscal.id)">
                  Consultar Status
                </a>
                <a class="dropdown-item" href="#"
                   :class="{'disabled': fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'}"
                   @click.prevent="reenviar(fiscal.id)">
                  Enviar NFe
                </a>
                <a class="dropdown-item"
                   :class="{'disabled': !fiscal.caminhoDanfe}"
                   href="#"
                   @click.prevent="abrir(fiscal.caminhoDanfe)">
                  Imprimir danfe
                </a>
                <a class="dropdown-item"
                   :class="{'disabled': !fiscal.caminhoXml}"
                   href="#"
                   @click.prevent="abrir(fiscal.caminhoXml)">
                  Baixar XML
                </a>
                <a class="dropdown-item"
                   href="#"
                   :class="{'disabled': fiscal.status !== 'EMITIDO'}"
                   @click.prevent="showModalCorrecao(fiscal.id)">
                  Emitir carta de correção
                </a>
                <a class="dropdown-item"
                   href="#"
                   :class="{'disabled': fiscal.status !== 'EMITIDO' }"
                   @click.prevent="showModalCancelamento(fiscal.id)">
                  Cancelar NFe
                </a>
                <a v-if="fiscal.caminhoPdfCartaCorrecao" class="dropdown-item"
                   href="#"
                   @click.prevent="abrir(fiscal.caminhoPdfCartaCorrecao)">
                  Baixar PDF Carta de correção
                </a>
                <a v-if="fiscal.caminhoXmlCartaCorrecao" class="dropdown-item"
                   href="#"
                   @click.prevent="abrir(fiscal.caminhoXmlCartaCorrecao)">
                  Baixar XML Carta de correção
                </a>
              </div>
            </div>

            <text-modal v-if="modalCorrecao"
                        :id="fiscal.id"
                        :show-modal="modalCorrecao"
                        :title="'Carta de correção'"
                        @update:show-modal="modalCorrecao = $event"
                        @update="correcao($event)">
            </text-modal>

            <text-modal v-if="modalCancelamento"
                        :id="fiscal.id"
                        :show-modal="modalCancelamento"
                        :title="'Justificativa do cancelamento'"
                        @update:show-modal="modalCancelamento = $event"
                        @update="cancelar($event)">
            </text-modal>


          </div>
        </div>
      </div>


      <div class="row col-12">
        <div class="col-3">
          <label class="form-label">Tipo *</label>
          <input v-model="nfe.natureza_operacao" disabled type="text" class="form-control" placeholder="Emissão Propria">
        </div>
        <div class="col-3">
          <label class="form-label">Serie *</label>
          <input v-model="nfe.serie" disabled type="text" class="form-control" required placeholder="1">
        </div>
        <div class="col-3">
          <label class="form-label">Número</label>
          <input v-model="fiscal.id" disabled type="number" class="form-control" placeholder="11111">
        </div>
        <div class="col-3">
          <label class="form-label">Data emissão</label>
          <input v-model="nfe.data_emissao" :disabled="fiscal.status === 'EMITIDO'" type="datetime-local"
                 class="form-control">
        </div>
      </div>

      <div class="row col-12">
        <div class="col-12">
          <label class="form-label">Número NFE</label>
          <div class="input-group">
            <input ref="inputNfe" v-model="fiscal.chaveSefaz" disabled type="text" class="form-control">
            <button class="btn btn-outline-secondary btn-sm" type="button" style="margin-bottom: 0 !important;"
                    @click="copiarValor">
              <i class="fas fa-copy"></i>
            </button>
          </div>
        </div>
        <hr class="horizontal dark my-2"/>
      </div>


      <!--      <div class="row col-12">-->
      <!--        <div class="col-2">-->
      <!--          <label class="form-label">Data saida</label>-->
      <!--          <input type="datetime-local" class="form-control">-->
      <!--        </div>-->
      <!--        <div class="col-3">-->
      <!--          <label class="form-label">Natureza de operação *</label>-->
      <!--          <input v-model="nfe.natureza_operacao" disabled type="text" class="form-control"-->
      <!--                 placeholder="Venda de mercadoria a não contribuinte">-->
      <!--        </div>-->
      <!--        <div class="col-3">-->
      <!--          <label class="form-label">Código do regime tributário *</label>-->
      <!--          <input disabled type="text" class="form-control" placeholder="Simples nacional">-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row col-12 mt-2">
        <div class="row">
          <h5 class="bold">Destinatário</h5>
        </div>
        <div v-if="cliente" class="row">
          <div class="col-3">
            <label class="form-label">Nome/Razão Social</label>
            <input v-model="cliente.nome" disabled type="text" class="form-control">
          </div>
          <div class="col-3">
            <label class="form-label">Sobrenome/Fantasia</label>
            <input v-model="cliente.sobrenome" disabled type="text" class="form-control">
          </div>
          <div class="col-3">
            <label class="form-label">CPF/CNPJ</label>
            <input v-model="cliente.cpf" disabled type="text" class="form-control">
          </div>
          <div class="col-3">
            <label class="form-label">Endereco</label>
            <input v-model="cliente.endereco" disabled type="text" class="form-control">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <a class="text-muted">Altere o destinatario, se necessario: </a>
              <Multiselect v-model="cliente"
                           :options="clientes"
                           :disabled="fiscal.status === 'EMITIDO'"
                           :custom-label="customLabel"
                           track-by="id"
                           :multiple="false"
                           @select="setCliente()">
              </Multiselect>
            </div>
          </div>
        </div>

        <hr class="horizontal dark my-2"/>
      </div>

      <div class="row col-12">
        <div class="row mt-2">
          <h5 class="bold">Itens da nota fiscal</h5>
        </div>
        <ul v-if="nfe.items.length" class="list-group">
          <li v-for="(item, index) in nfe.items" :key="index"
              class="list-group-item d-flex justify-content-between align-items-center">

            <div class="d-flex align-items-center">
              <div v-if="item" class="m-lg-2">
                {{ item.descricao }} {{ currencyMixin.methods.formattedAmount(item.valor_unitario_tributavel) }}, CFOP: {{
                  item.cfop
                }},
                Quantidade: {{ item.quantidade_comercial }}, Total: {{ currencyMixin.methods.formattedAmount(item.valor_bruto) }}
              </div>
            </div>

            <!-------MODAL--------->
            <ModalItem v-if="isModalVisible" title="Altere o produto"
                       :item-dto="selectedProduto"
                       :show-modal="isModalVisible"
                       @update:showModal="isModalVisible = $event"
                       @update="update($event)">
            </ModalItem>

            <div>
              <button v-tippy="'Editar esse produto'"
                      :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'"
                      class="btn btn-primary m-auto"
                      @click.prevent="showModal(item)">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </button>
              <button v-tippy="'Apagar esse produto'" type="button"
                      :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'"
                      class="btn btn-danger m-auto m-sm-1"
                      @click.prevent="removeProduto(index, item.id)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </li>
        </ul>
        <div>
          <button type="button"
                  class="btn btn-primary col-3 mt-3"
                  :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'"
                  @click="addProduto()">Adicionar produto
          </button>
        </div>
        <hr class="horizontal dark my-2"/>
      </div>


      <div class="row col-12">
        <div class="row mt-2">
          <h5 class="bold">Cálculo</h5>
        </div>
        <div class="col-3">
          <label class="form-label">Total dos Produtos (R$)</label>
          <CurrencyInput
              v-model="nfe.valor_produtos"
              disabled
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Valor do Frete (R$)</label>
          <CurrencyInput
              v-model="nfe.frete"
              :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Desconto (R$)</label>
          <CurrencyInput
              v-model="nfe.desconto"
              :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Total da Nota (R$)</label>
          <CurrencyInput
              v-model="nfe.valor_total"
              :disabled="true"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <hr class="horizontal dark my-2"/>
      </div>

      <div class="row col-12">

        <div class="row mt-2">
          <h5 class="bold">Informações adicionais</h5>
        </div>

        <div class="col-6">
          <label class="form-label">Informações complementares</label>
          <textarea v-model="nfe.observacao" :disabled="fiscal.status === 'EMITIDO' || fiscal.status === 'CANCELADO'" class="form-control"
                    rows="4"></textarea>
        </div>
        <hr class="horizontal dark my-2"/>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

import ModalItem from "@/views/ModalItem.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import CurrencyInput from "@/CurrencyInput.vue";
import TextModal from "@/views/TextModal.vue";

export default {
  name: "NewNota",
  components: {TextModal, CurrencyInput, ModalItem, Multiselect},
  data() {
    return {

      isModalVisible: false,
      selectedProduto: null,
      clientes: [],
      fiscal: {},
      cliente: {},
      nfe: {},
      modalCorrecao: false,
      modalCancelamento: false,
    };
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    }
  },
  created() {
    this.getClientes()
    this.getFiscal()
  },
  methods: {
    async showModalCorrecao() {
      this.modalCorrecao = true
    },
    async showModalCancelamento() {
      this.modalCancelamento = true
    },
    async correcao({id, texto}) {
      try {
        const dto = {
          correcao: texto
        }
        const response = await axios.post(`api/fiscal/nfe/${id}/carta_correcao`, dto);
        toast(`${response.data.mensagem_sefaz}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.message?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.getFiscal();
    },
    async cancelar({id, texto}) {
      try {
        const dto = {
          justificativa: texto
        };
        const config = {
          data: dto
        };
        const response = await axios.delete(`api/fiscal/nfe/${id}`, config);
        toast(`${response.data.mensagem_sefaz}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.codigo} - ${e.response?.data?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.getFiscal();
    },
    async verificarStatus(id) {
      const idToast = toast.loading(
          'Processando envio...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );

      let tentativas = 0;
      const maxTentativas = 10; // Define o número máximo de tentativas
      const intervalo = 2000; // Define o intervalo entre as tentativas em milissegundos (2000ms = 2 segundos)

      while (tentativas < maxTentativas) {
        try {
          const response = await axios.get(`api/fiscal/nfe/status/${id}`);

          // Verifica se o status é diferente de 'processando_autorizacao'
          if (response.data.status !== 'processando_autorizacao') {
            if (response.data.status === 'autorizado') {
              toast.update(idToast, {
                render: `NFe emitida com sucesso!`,
                autoClose: 2000,
                position: toast.POSITION.TOP_RIGHT,
                transition: "flip",
                theme: "dark",
                type: "success",
                isLoading: false,
              })
              return response.data
            }
            toast.update(idToast, {
              render: `${response.data.mensagem_sefaz} - ${response.data.status}`,
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
              transition: "flip",
              theme: "dark",
              type: "error",
              isLoading: false,
            })
            return response.data; // Retorna os dados da resposta quando o status for diferente
          }

          // Se o status ainda for 'processando_autorizacao', espera um pouco antes de tentar novamente
          console.log('Status ainda em processamento, aguardando para nova tentativa...');
          await this.delay(intervalo);
        } catch (e) {
          console.log('Erro ao tentar verificar o status:', e);
          break; // Interrompe o loop em caso de erro
        }

        tentativas++;
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async reenviar(id) {
      try {
        await axios.post(`api/fiscal/nfe/${id}`);
      } catch (e) {
        console.log(e);
        toast(`${e.response?.data?.codigo} - ${e.response?.data?.mensagem}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
        await this.getFiscal();
        return
      }
      try {
        await this.verificarStatus(id)
      } catch (e) {
        console.log(e);
      }
      await this.getFiscal();
    },
    async getStatus(id) {
      const response = await axios.get(`api/fiscal/nfe/status/${id}`);
      if (response.data.status === 'autorizado') {
        toast(`NFe emitida com sucesso!`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      }
      if (response.data.status === 'erro_autorizacao') {
        toast(`${response.data.mensagem_sefaz} - ${response.data.status}`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.getFiscal();
    },
    abrir(caminho) {
      const urlCompleta = `https://api.focusnfe.com.br/${caminho}`;
      window.open(urlCompleta, '_blank');
    },
    copiarValor() {
      const el = document.createElement('textarea');
      el.value = this.fiscal.chaveSefaz;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      toast("Chave NFe copiada!", {
        autoClose: 1000,
        position: toast.POSITION.TOP_RIGHT,
        transition: "flip",
        theme: "dark",
        type: "success"
      })
    },
    async setCliente(id) {
      await axios.post(`api/fiscal/cliente/${id}`);
    },
    async getFiscal() {
      const id = this.$route.params._id;
      const response = await axios.get(`api/fiscal/${id}`);
      this.fiscal = response.data
      this.nfe = this.fiscal.notaFiscal
    },
    async removeProduto(index, itemId) {
      if (isNaN(itemId)) {
        this.nfe.items.splice(index, 1);
        return
      }
      try {
        await axios.delete(`api/fiscal/nfe/item/${itemId}`);
        this.nfe.items.splice(index, 1);

        toast("Produto removido", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar NFe: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    customLabel({nome, sobrenome}) {
      return `${nome} ${sobrenome}`
    },
    async getClientes() {
      const response = await axios.get(`api/cliente`)
      this.clientes = response.data
      this.cliente = this.clientes.find(cliente => cliente.id === this.fiscal.clienteId)
    },
    async update(newProduto) {
      try {
        await axios.put(`api/fiscal/nfe/item/${this.fiscal.id}`, newProduto);

        toast("Produto adicionado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar NFe: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.getFiscal()
    },
    showModal(produto) {
      this.selectedProduto = produto;
      this.isModalVisible = true;
    },
    addProduto() {
      let item = {}
      if (this.nfe.tipo_documento === 0) { // COMPRA
        item = {
          valor_bruto: 0,
          cfop: 1102,
          codigo_ncm: 85176262,
          icms_situacao_tributaria: 102,
          cofins_situacao_tributaria: 49,
          pis_situacao_tributaria: 49,
          quantidade_comercial: 1
        }
      }

      if (this.nfe.tipo_documento === 1) { // COMPRA
        item = {
          valor_bruto: 0,
          cfop: 5102,
          codigo_ncm: 85176262,
          icms_situacao_tributaria: 102,
          cofins_situacao_tributaria: 49,
          pis_situacao_tributaria: 49,
          quantidade_comercial: 1
        }
      }

      this.nfe.items.push({...item})
    },
  }
}

</script>

<style scoped>
.dropdown-menu {
  min-width: 200px; /* Define a largura mínima do menu dropdown */
  background-color: #ffffff; /* Cor de fundo do menu dropdown */
  border: 1px solid #ddd; /* Borda do menu dropdown */
  border-radius: 0.25rem; /* Arredondamento das bordas */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para adicionar profundidade */
}

.dropdown-item {
  color: #495057; /* Cor do texto dos itens */
  padding: 10px 20px; /* Espaçamento interno para aumentar a área de clique e melhorar a legibilidade */
  transition: background-color 0.2s ease-in-out; /* Transição suave para o efeito de hover */
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse ou focar */
  color: #0056b3; /* Cor do texto ao passar o mouse ou focar */
  text-decoration: none; /* Remove o sublinhado do texto ao passar o mouse ou focar */
}

.dropdown-item:active {
  background-color: #e9ecef; /* Cor de fundo ao clicar */
  color: #004085; /* Cor do texto ao clicar */
}
</style>