<template>
  <Modal :show-modal="showModal"
         :title="title"
         label-button="Salvar mudanças"
         @update:showModal="$emit('update:showModal', $event)"
         @update="formatAndSave()">
    <div class="container">
      <form>
        <div class="row col-12">
          <label class="form-label">Produtos</label>
          <textarea v-model="texto"
                    class="form-control"
                    :placeholder="'2 - XR 64gb branco /preto R$ 1250,00 (cada)\n'+
                      '2 - 11 64gb  branco/lilas R$ 1620,00 (cada)\n'+
                      '1 - 12 128gb branco R$ 2.050,00 (cada)\n'+
                      '1 - 13 pro max 128gb gold R$ 4.080,00 (cada'"
                    rows="18">
          </textarea>
        </div>
      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

export default {
  name: "ModalText",
  components: {Modal},
  props: {
    showModal: Boolean,
    title: String,
  },
  emits: ['update', 'update:showModal'],
  data() {
    return {
      texto: null
    }
  },
  methods: {
    formatAndSave() {
      this.$emit('update', this.texto)
    }
  }
}
</script>