<template>
  <div v-if="showModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Fechar</button>
          <button type="button" class="btn btn-primary"  @click.prevent="update">{{ labelButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showModal: Boolean,
    title: String,
    labelButton: [String, () => 'Salvar mudanças'],
  },
  emits: ['update:showModal', 'update'],
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
    },
    update() {
      this.$emit('update');
      // this.closeModal()
    }
  }
}
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Define a largura do modal */
  max-width: 50%; /* Aumenta a largura máxima do modal */
  height: auto; /* Altura automática baseada no conteúdo */
  max-height: 90%; /* Aumenta a altura máxima do modal */
  overflow-y: auto; /* Ad */
}

.modal-content {
  max-height: calc(100vh - 50px); /* 100vh é a altura total da tela, 50px pode ser ajustado conforme necessário */
  overflow-y: auto; /* Muda para 'scroll' para sempre mostrar a barra de rolagem */
}

/* Estiliza a barra de rolagem */
.modal-content::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
}

/* Estiliza o track (caminho) da barra de rolagem */
.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo do track */
}

/* Estiliza o thumb (parte móvel) da barra de rolagem */
.modal-content::-webkit-scrollbar-thumb {
  background: #888; /* Cor do thumb */
}

/* Estiliza o thumb (parte móvel) da barra de rolagem ao passar o mouse */
.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor do thumb ao passar o mouse */
}

</style>
