<template>
  <div class="container mt-5 card">
    <div class="card-body">
      <div>
        <h5 class="bold">Nota Fiscal</h5>
      </div>
      <div class="row col-12">
        <div class="col-3">
          <label class="form-label">Tipo de Saida *</label>
          <input disabled type="text" class="form-control" placeholder="Emissão Propria">
        </div>
        <div class="col-3">
          <label class="form-label">Serie *</label>
          <input disabled type="text" class="form-control" required placeholder="Tipo de Saida">
        </div>
        <div class="col-3">
          <label class="form-label">Numero</label>
          <input disabled type="text" class="form-control" placeholder="11111">
        </div>
        <div class="col-3">
          <label class="form-label">Indicador de presença *</label>
          <input type="text" class="form-control" placeholder="9 - Operação não presencial, outros">
        </div>
      </div>

      <div class="row col-12">
        <div class="col-2">
          <label class="form-label">Data emissão</label>
          <input type="datetime-local" class="form-control">
        </div>
        <div class="col-2">
          <label class="form-label">Data saida</label>
          <input type="datetime-local" class="form-control">
        </div>
        <div class="col-3">
          <label class="form-label">Natureza de operação *</label>
          <input type="text" class="form-control" placeholder="Venda de mercadoria a não contribuinte">
        </div>
        <div class="col-3">
          <label class="form-label">Código do regime tributário *</label>
          <input type="text" class="form-control" placeholder="Simples nacional">
        </div>
        <hr class="horizontal dark my-2"/>
      </div>

      <div class="row col-12 mt-2">
        <div class="row">
          <h5 class="bold">Destinatário</h5>
        </div>
        <div class="col-6">
          <div class="form-group">
            <Multiselect v-model="cliente"
                         :options="clientes"
                         :custom-label="customLabel"
                         track-by="id"
                         :multiple="false"
                         @select="setCliente()">
            </Multiselect>

          </div>
        </div>

        <hr class="horizontal dark my-2"/>
      </div>

      <div class="row col-12">
        <div class="row mt-2">
          <h5 class="bold">Itens da nota fiscal</h5>
        </div>
        <ul v-if="nfe.items.length" class="list-group">
          <li v-for="(item, index) in nfe.items" :key="index"
              class="list-group-item d-flex justify-content-between align-items-center">

            <div class="d-flex align-items-center">
              <div v-if="item" class="m-lg-2">
                {{ item.descricao }} {{ currencyMixin.methods.formattedAmount(item.valorBruto) }} {{ item.cfop }} {{ item.quantidadaComercial }} {{ item.codigoNcm }}
              </div>
            </div>

            <!-------MODAL--------->
            <ModalProduto v-if="isModalVisible" title="Altere o produto"
                          :produto-dto="selectedProduto"
                          :show-modal="isModalVisible"
                          @update:showModal="isModalVisible = $event"
                          @update="update(item.produto, $event)">
            </ModalProduto>

            <div>
              <button v-tippy="'Editar esse produto'"
                      class="btn btn-primary m-auto"
                      @click.prevent="showModal(item.produto)">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </button>
              <button v-tippy="'Apagar esse produto'" type="button"
                      class="btn btn-danger m-auto m-sm-1"
                      @click.prevent="removeProdutoTroca(index, item.id)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </li>
        </ul>
        <div>
          <button type="button"
                  class="btn btn-primary col-3 mt-3"
                  @click="addProduto()">Adicionar troca
          </button>
        </div>
        <hr class="horizontal dark my-2"/>
      </div>


      <div class="row col-12">
        <div class="row mt-2">
          <h5 class="bold">Cálculo</h5>
        </div>
        <div class="col-3">
          <label class="form-label">Total dos Produtos (R$)</label>
          <CurrencyInput
              disabled
              v-model="nfe.valorProdutos"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Valor do Frete (R$)</label>
          <CurrencyInput
              v-model="nfe.frete"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Desconto (R$)</label>
          <CurrencyInput
              v-model="nfe.desconto"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <div class="col-3">
          <label class="form-label">Total da Nota (R$)</label>
          <CurrencyInput
              v-model="nfe.valorTotal"
              :disabled="true"
              class="form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
        <hr class="horizontal dark my-2"/>
      </div>

      <div class="row col-12">

        <div class="row mt-2">
          <h5 class="bold">Informações adicionais</h5>
        </div>

        <div class="col-6">
          <label class="form-label">Informações complementares</label>
          <textarea v-model="nfe.observacao" class="form-control" rows="4"></textarea>
        </div>
        <hr class="horizontal dark my-2"/>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

import ModalProduto from "@/views/ModalProduto.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import CurrencyInput from "@/CurrencyInput.vue";

export default {
  name: "NewNota",
  components: {CurrencyInput, ModalProduto, Multiselect},
  data() {
    return {

      isModalVisible: false,
      selectedProduto: null,
      clientes: [],
      nfe: {
        naturezaOperacao: '',
        numeroNFe: '',
        serieNFe: '',
        chaveAcesso: '',
        dataEmissao: '',
        cnpjEmitente: '',
        items: {}
        // Adicione mais propriedades conforme necessário
      }
    };
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    }
  },
  created() {
    this.getClientes()
  },
  methods: {
    async removeProdutoTroca(index, produtoId) {
      if (isNaN(produtoId)) {
        this.produtosTroca.splice(index, 1);
        return
      }
      try {
        await axios.delete(`api/produto/troca/${produtoId}`);
        this.produtosTroca.splice(index, 1);

        toast("Troca removida", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro ao atualizar pedido: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
    },
    customLabel({nome, sobrenome}) {
      return `${nome} ${sobrenome}`
    },
    async getClientes() {
      const response = await axios.get(`api/cliente`)
      this.clientes = response.data
    },
    update(oldProduto, newProduto) {
      this.salvar(newProduto)
    },
    showModal(produto) {
      this.selectedProduto = produto;
      this.isModalVisible = true;
    },
    addProduto() {
      const item = {
        produto: {precoCusto: 0}
      }
      this.produtosTroca.push({...item})
    },
  }
}

</script>