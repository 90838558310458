<template>
  <aside
      v-if="!isEcommerceListWithHash"
      id="sidenav-main"
      class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
      :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
    <div class="sidenav-header" style="display: flex; justify-content: center; align-items: center;">
      <i
          id="iconSidenav"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true"
      ></i>
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" class="navbar-brand-img" alt="main_logo"/>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark"/>
    <sidenav-list :card-bg="customClass"/>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
// import logo from "@/assets/logo.jpg";
import {mapState} from "vuex";

export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // logo,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    isEcommerceListWithHash() {
      return this.$route.path === '/ecommerce/list/hash=f911a465-fdbf-4975-8527-d54a35b7b547'
          || this.$route.path === '/ecommerce/list/hash=92a7500b-9eb1-4f45-8744-46c226754b12';
    },
  },
};
</script>

<style scoped>
.sidenav-header {
  display: flex;
  justify-content: center; /* Alinha horizontalmente */
  align-items: center; /* Alinha verticalmente */
}
</style>
