<template>
  <div />
</template>

<script>


import axios from "axios";

export default {
  created () {
    this.impersonate(this.$route.params)
  },
  methods: {
    async impersonate({login, token}) {
      console.log(login);
      console.log(token);
      try {
        const response = await axios.post(`api/auth/go`, {
          code: login,
          token: token
        });

        await localStorage.setItem('token', response.data.token);
        await localStorage.setItem('nome', response.data.nome);
        await localStorage.setItem('funcionarioId', response.data.funcionarioId);
        await localStorage.setItem('role', response.data.role);

        await this.$router.push(`/ecommerce/overview/`);
      } catch (e) {
        console.log("Erro ao impersonar: " , e)
      }

    }
  }
}
</script>