<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!-- Card header -->
        <div class="pb-0 card-header">
          <div class="d-lg-flex col">
            <div class="col-2">
              <label>Data:</label>
              <select v-model="dateSelected" class="form-control" @change.prevent="loadPedidosByDate">
                <option value="7">Ultimos 7 dias</option>
                <option value="14">Ultimos 14 dias</option>
                <option value="30">Ultimos 30 dias</option>
              </select>
            </div>
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="my-auto ms-auto">
                <a
                    class="mb-0 btn bg-gradient-success btn-sm"
                    target="_blank"
                    @click.prevent="addCotacao()"
                >+&nbsp; Criar nova cotacao</a
                >
              </div>
            </div>
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="dropdown">
                <soft-button
                    id="navbarDropdownMenuLink2"
                    color="dark"
                    variant="outline"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >Filtros
                </soft-button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'ABERTO' }"
                     @click.prevent="status = 'ABERTO'; loadCotacoes()">
                    Status: ABERTO
                  </a>
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'NO_CAMINHO' }"
                     @click.prevent="status = 'NO_CAMINHO'; loadCotacoes()">
                    Status: NO CAMINHO
                  </a>
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'FECHADO' }"
                     @click.prevent="status = 'FECHADO'; loadCotacoes()">
                    Status: FECHADO
                  </a>
                  <a class="dropdown-item" href="#" :class="{ selected: status === 'CANCELADO' }"
                     @click.prevent="status = 'CANCELADO'; loadCotacoes()">
                    Status: CANCELADO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table id="order-list" class="table table-flush">
          <tbody v-if="!isLoading && !cotacoes.length || cotacoes == null">
          <tr class="card">
            <td class="card-body row d-flex justify-content-between align-items-center">
              <span class="text-center">Sem resultados <i class="fa fa-inbox" aria-hidden="true"></i></span>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="isLoading">
          <tr class="card">
            <td class="card-body row d-flex justify-content-between align-items-center" role="status">
              <span class="spinner-grow text-center"></span>
            </td>
          </tr>
          </tbody>
          <!-- Navegação das abas -->

          <tbody v-else>
          <tr v-for="(cotacao, index) in cotacoes" :key="index" class="card" @click="cardSelecionado = cotacao">

            <!-------MODAL TEXT --------->
            <ModalText v-if="isModalTextVisible && cardSelecionado === cotacao"
                       title="Digite o texto"
                       :show-modal="isModalTextVisible"
                       @update:showModal="isModalTextVisible = $event"
                       @update="sendText($event, cotacao.id)">
            </ModalText>

            <td v-if="cardSelecionado === cotacao">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: abaAtiva === 'cotacao' }"
                     @click="abaAtiva = 'cotacao'">Cotação</a>
                </li>
                <li v-if="cotacao.id" class="nav-item">
                  <a class="nav-link" :class="{ active: abaAtiva === 'financeiro' }" @click="abaAtiva = 'financeiro'">Financeiro</a>
                </li>
                <li v-if="cotacao.id" class="nav-item">
                  <a class="nav-link" :class="{ active: abaAtiva === 'resumo' }" @click="abaAtiva = 'resumo'">Resumo</a>
                </li>
                <li v-if="cotacao.id && role === 'admin'" class="nav-item">
                  <a class="nav-link" :class="{ active: abaAtiva === 'lucro' }" @click="abaAtiva = 'lucro'">Lucro</a>
                </li>
                <li v-if="cotacao.id" class="nav-item">
                  <a class="nav-link" :class="{ active: abaAtiva === 'codigo' }"
                     @click.prevent="abaAtiva = 'codigo'; carregarRastreamento(cotacao)">
                    Codigo de rastreio
                  </a>
                </li>
              </ul>
            </td>
            <td class="d-flex justify-content-between align-items-center">
              <div class="col-1">
                <span>{{ cotacao.id }}</span>
              </div>
              <div class="col-4">
                <Multiselect v-if="fornecedoresOptions.length > 0"
                             v-model="cotacao.fornecedor"
                             :disabled="cotacao.status === 'FECHADO' || cotacao.status === 'CANCELADO'"
                             :options="fornecedoresOptions"
                             label="completedName"
                             :multiple="false"
                             @select="isChanged = true">
                </Multiselect>
              </div>

              <div class="col-2">
                <span v-if="!isEditable || cardSelecionado !== cotacao" class="form-control text-uppercase rounded-pill"
                      :class='cotacao.status === "FECHADO" ? "badge badge-success" : cotacao.status === "CANCELADO" ? "badge badge-danger" : "badge badge-info"'
                      @mouseover="isEditable = true">
                {{ cotacao.status === 'NO_CAMINHO' ? "NO CAMINHO" : cotacao.status }}
                </span>
                <select v-else-if="isEditable" v-model="cotacao.status"
                        :class='cotacao.status === "FECHADO" ? "badge badge-success" : cotacao.status === "CANCELADO" ? "badge badge-danger" : "badge badge-info"'
                        class="form-select rounded-pill"
                        @mouseleave="isEditable = false"
                        @change.prevent="changeCotacao(cotacao)">
                  <option value="ABERTO" class="badge badge-info">ABERTO</option>
                  <option v-if="cotacao.status !== 'CANCELADO' && cotacao.status !== 'FECHADO'" value="NO_CAMINHO"
                          class="badge badge-info">NO CAMINHO
                  </option>
                  <option v-if="cotacao.status !== 'CANCELADO'" value="FECHADO" class="badge badge-success">FECHADO
                  </option>
                  <option v-if="cotacao.status !== 'FECHADO'" value="CANCELADO" class="badge badge-danger">CANCELADO
                  </option>
                </select>
              </div>
              <div v-if="cardSelecionado === cotacao && cotacao.status !== 'FECHADO' && cotacao.status !== 'CANCELADO'">
                <i
                    :class="['btn', 'btn-success', 'm-1', { 'disabled': (!isChanged && cotacao.id || isLoadingCotacao) || !cotacao.fornecedor.nome }]"
                    @click.prevent="salvarCotacao(cotacao)">Salvar
                </i>
                <i class="btn btn-success fas fa-upload m-1"
                   @click.prevent="showModalText()"> Importar
                </i>
              </div>

            </td>

            <td class="d-flex justify-content-between">
              <span>Data da cotacao: {{ longToDateFormatted(cotacao.dataCriacao) }}</span>
            </td>

            <td>
              <div class="d-flex justify-content-between">
                <div>
                  <h4>Valor Total: {{ currencyMixin.methods.formattedAmount(valorTotalCotacao(cotacao)) }}</h4>
                  <h6>Valor dos produtos: {{ currencyMixin.methods.formattedAmount(valorTotalProdutos(cotacao)) }}</h6>
                  <h6>Sub total por produto: {{
                      currencyMixin.methods.formattedAmount(subTotalAMaisPorAparelho(cotacao))
                    }}
                    <i v-tippy="'Esse valor será adicionado ao custo dos produtos'" class="fa fa-info-circle"></i>
                  </h6>
                </div>
                <div>
                  <div>
                    <label>Frete
                      <CurrencyInput
                          v-model="cotacao.frete"
                          :disabled="cotacao.status === 'FECHADO' || cotacao.status === 'CANCELADO'"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                          @update="onCotacaoChange"
                      />
                    </label>
                  </div>
                  <div>
                    <label>Valor dos outros encargos:
                      <CurrencyInput
                          v-model="cotacao.encargos"
                          :disabled="cotacao.status === 'FECHADO' || cotacao.status === 'CANCELADO'"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                          @update="onCotacaoChange"
                      />
                    </label>
                  </div>
                  <div>
                    <label>Desconto:
                      <CurrencyInput
                          v-model="cotacao.desconto"
                          :disabled="cotacao.status === 'FECHADO' || cotacao.status === 'CANCELADO'"
                          class="form-control"
                          :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                          @update="onCotacaoChange"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <hr>
            </td>


            <td class="tab-content">
              <!-- Aba Cotação -->
              <div v-if="abaAtiva === 'cotacao' && cardSelecionado === cotacao" class="tab-pane fade"
                   :class="{ show: abaAtiva === 'cotacao', active: abaAtiva === 'cotacao' }">
                <div v-if="cardSelecionado === cotacao">
                  <span>Lista de produtos: </span>
                  <ul class="list-group">
                    <li v-for="(produtoCotacao, index) in cotacao.produtos" :key="index"
                        class="list-group-item d-flex justify-content-between align-items-center">

                      <div class="d-flex align-items-center">
                        <button v-tippy="'Replicar esse produto'"
                                type="button" :disabled="cotacao.status === 'FECHADO' || cotacao.status === 'CANCELADO'"
                                class="btn btn-info m-1" @click.prevent="replicar(produtoCotacao, cotacao)">
                          <i class="fa fa-copy fa-lg" aria-hidden="true"></i>
                        </button>

                        <div class="m-lg-2">
                          Modelo: {{ produtoCotacao?.produto?.modelo }}
                          {{ produtoCotacao?.produto?.cor }} {{ produtoCotacao?.produto?.armazenamento }}, Custo:
                          {{ currencyMixin.methods.formattedAmount(produtoCotacao.precoCusto) }}, Venda:
                          {{ currencyMixin.methods.formattedAmount(produtoCotacao.precoVenda) }}
                        </div>
                      </div>

                      <!-------MODAL--------->
                      <ModalProduto v-if="isModalVisible === index" title="Altere o produto"
                                    :is-search="true"
                                    :produto-dto="selectedProduto"
                                    :preco-custo="produtoCotacao.precoCusto"
                                    :preco-venda="produtoCotacao.precoVenda"
                                    :preco-minimo-venda="produtoCotacao.precoMinimoVenda"
                                    :quantidade="produtoCotacao.quantidade"
                                    :show-modal="isModalVisible === index"
                                    @update:showModal="isModalVisible = $event"
                                    @update="update(produtoCotacao, $event)">
                      </ModalProduto>

                      <div>
                        <button v-tippy="'Editar esse produto'"
                                :disabled="cotacao.status !== 'ABERTO'"
                                type="button"
                                class="btn btn-primary m-auto"
                                @click="showModal(produtoCotacao.produto, index)">
                          <i class="fa fa-edit" aria-hidden="true"></i> <!-- Outro ícone comumente usado para editar -->
                        </button>
                        <button v-tippy="'Apagar esse produto'" type="button"
                                :disabled="cotacao.status !== 'ABERTO'"
                                class="btn btn-danger m-auto m-sm-1"
                                @click="apagar(produtoCotacao.id, cotacao.produtos, index)">
                          <i class="fa fa-trash" aria-hidden="true"></i> <!-- Ícone de lixeira -->
                        </button>
                      </div>
                    </li>
                    <div>
                      <button type="button" :disabled="cotacao.status !== 'ABERTO' || !cotacao.id"
                              class="btn btn-primary col-3 mt-3"
                              @click="addProduto(cotacao)">Adicionar produto
                      </button>
                      <p v-if="!cotacao.id" class="text-sm" style="color: red">Salve a cotacao antes de adicionar algum
                        produto</p>
                    </div>
                  </ul>
                </div>
              </div>

              <!-- Aba Financeiro -->
              <div v-if="abaAtiva === 'financeiro' && cardSelecionado === cotacao" class="tab-pane fade"
                   :class="{ show: abaAtiva === 'financeiro', active: abaAtiva === 'financeiro' }">
                <ul class="list-group">
                  <div class="col-12 row">
                    <div class="col-2">
                      <label>
                        Valor
                      </label>
                    </div>
                    <div class="col-2">
                      <label>
                        Método
                      </label>
                    </div>
                    <div class="col-2">
                      <label>
                        Data
                      </label>
                    </div>
                    <div class="col-4">
                      <label>
                        Banco
                      </label>
                    </div>
                  </div>
                  <li v-for="(transacao, index) in cotacao.contasAPagar.transacoes" :key="index"
                      class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="input-group row col-12  d-flex">
                      <div class="col-2">
                        <CurrencyInput
                            v-model="transacao.valor"
                            :disabled="transacao.id"
                            class="form-control"
                            :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
                        />
                      </div>
                      <div class="col-2">
                        <select v-model="transacao.metodoPagamento" :disabled="transacao.id" class="form-control">
                          <option value="CARTAO">Cartão</option>
                          <option value="DINHEIRO">Dinheiro</option>
                          <option value="PIX">Pix</option>
                        </select>
                      </div>
                      <div class="col-2">
                        <input v-model="transacao.dataPagamento" :disabled="transacao.id" type="date"
                               class="form-control">
                      </div>
                      <div class="col-4">
                        <select v-if="!transacao.id" v-model="transacao.banco" class="form-control">
                          <option v-for="banco in bancos" :key="banco.id" :value="banco.id">
                            {{ banco?.nome }} - {{ banco?.razaoSocialOrName }} | Conta: {{ banco?.conta }} Agência:
                            {{ banco?.agencia }}
                          </option>
                        </select>
                        <select v-else disabled class="form-control">
                          <option value="" selected>
                            {{ transacao?.banco?.nome }} - {{ transacao?.banco?.razaoSocialOrName }} | Conta:
                            {{ transacao?.banco?.conta }} Agência:
                            {{ transacao?.banco?.agencia }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2 d-flex align-items-end align-content-end">
                        <div>
                          <button :disabled="isLoadingTransacao"
                                  class="btn btn-sm btn-danger m-1"
                                  @click.prevent="excluirTransacao(transacao.id, index, cotacao.contasAPagar)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                        <div>
                          <button :disabled="isLoadingTransacao || isDisabled(transacao) || transacao.id"
                                  class="btn btn-sm btn-success m-1"
                                  @click.prevent="changeTransacao(transacao, cotacao)">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="row">
                  <div class="col-6 d-flex justify-content-start mt-3">
                    <button :disabled="isLoading " class="btn btn-primary btn-sm"
                            @click.prevent="cotacao.contasAPagar.transacoes.push({valor:0})">Adicionar
                      transacao
                    </button>
                  </div>
                </div>
              </div>

              <!-- Aba Resumo -->
              <div v-if="abaAtiva === 'resumo'" class="tab-pane fade"
                   :class="{ show: abaAtiva === 'resumo', active: abaAtiva === 'resumo' }">
                <div v-if="cardSelecionado === cotacao" class="mt-3">
                  <label>Soma dos produtos iguais:</label>
                  <a v-html="agrupaProdutosPortTotal(cotacao.produtos)"></a>
                </div>
              </div>

              <!-- Aba Lucro -->
              <div v-if="role === 'admin' && abaAtiva === 'lucro'" class="tab-pane fade"
                   :class="{ show: abaAtiva === 'lucro', active: abaAtiva === 'lucro' }">
                <div v-if="cardSelecionado === cotacao" class="mt-3">
                  <ul>
                    <li v-for="(produto, index) in cotacao.produtos" :key="index">
                      Modelo: {{ produto.modelo }}
                      <ul>
                        <li>Lucro (Preço Mínimo de Venda): {{
                            isNaN(calcularLucro(produto).lucroMinimo) ? calcularLucro(produto).lucroMinimo : currencyMixin.methods.formattedAmount(calcularLucro(produto).lucroMinimo)
                          }}
                        </li>
                        <li>Lucro (Preço de Venda): {{
                            isNaN(calcularLucro(produto).lucroVenda) ? calcularLucro(produto).lucroVenda : currencyMixin.methods.formattedAmount(calcularLucro(produto).lucroVenda)
                          }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h5>Lucro Total (Preço Mínimo de Venda):
                    {{ currencyMixin.methods.formattedAmount(calcularLucroTotalMinimo(cotacao)) }}</h5>
                  <h5>Lucro Total (Preço de Venda):
                    {{ currencyMixin.methods.formattedAmount(calcularLucroTotalVenda(cotacao)) }}</h5>
                </div>
              </div>

              <!-- Aba Codigo de rastreio -->
              <div v-if="abaAtiva === 'codigo'" class="tab-pane fade"
                   :class="{ show: abaAtiva === 'codigo', active: abaAtiva === 'codigo' }">
                <div v-if="cardSelecionado === cotacao" class="mt-3">
                  <div class="row col-12">
                    <div class="col">
                      <label>Adicione um novo codigo de rastreio: </label>
                      <input v-model="newCodigoRastreio" type="text" class="input-group-text"/>
                    </div>
                    <div class="mt-2">
                      <button class="btn btn-info btn-sm"
                              :disabled="!validarCodigo(newCodigoRastreio)"
                              @click.prevent="addCodigoRastreio(cotacao, newCodigoRastreio)">
                        Adicionar codigo de rastreio
                      </button>
                    </div>
                  </div>

                  <div v-for="(grupo, indiceGrupo) in gruposDeObjetos" :key="indiceGrupo" class="row mb-3">
                    <div v-for="(objeto, index) in grupo" :key="index" class="col-md-4">
                      <div class="card m-auto">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-6 text-start">
                              <h5 class="small">{{ objeto.codObjeto }}</h5>
                              <h5 class="small">
                                {{ objeto.tipoPostal == null ? 'Sem tipo postal' : objeto.tipoPostal.descricao }}</h5>
                            </div>
                            <div class="col-6 text-end">
                              <button class="btn btn-danger"
                                      @click.prevent="deletarRastreamento(cotacao, objeto.codObjeto)">
                                <i class="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                          <hr>
                          <div v-if="objeto.eventos == null || !objeto.eventos.length">
                            <span class="small">Sem eventos ainda</span>
                          </div>
                          <timeline-list v-else>
                            <timeline-item
                                v-for="evento in objeto.eventos" :key="evento.dtHrCriado"
                                color="success"
                                :icon="getIcon(evento.descricao)"
                                :title=evento.descricao
                                :description=formatarEndereco(evento)
                                :date-time=formatarData(evento.dtHrCriado)
                            />
                          </timeline-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination v-model="page" :records="totalContents" :per-page="pageSize" @paginate="loadPedidosByDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from "@/components/SoftButton.vue";

import ModalProduto from "@/views/ModalProduto.vue";
import CurrencyInput from "@/CurrencyInput.vue";
import Pagination from 'v-pagination-3';
import TimelineList from "@/views/pages/projects/components/TimelineList.vue";
import TimelineItem from "@/views/pages/projects/components/TimelineItem.vue";
import ModalText from "@/views/ModalText.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import {mapState} from "vuex";
import Multiselect from "vue-multiselect";


export default {
  name: "ProductsList",
  components: {Multiselect, ModalText, TimelineItem, TimelineList, Pagination, CurrencyInput, ModalProduto, SoftButton},
  beforeRouteLeave(to, from, next) {
    if (this.isChanged) {
      this.$swal({
        title: 'Alterações não salvas!',
        text: 'Você tem alterações não salvas nesta cotação. Tem certeza de que deseja sair?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sair',
        cancelButtonText: 'Voltar a tela',
      }).then((result) => {
        if (result.value) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  data() {
    return {
      pedidos: [],
      status: null,
      cardSelecionado: null,
      dateSelected: 7,
      startDate: null,
      endDate: null,
      cotacoes: {
        id: null,
        accountId: null,
        fornecedor: {},
        produtos: [
          {
            id: null,
            quantidade: 0,
            precoCusto: null,
            precoMinimoVenda: null,
            precoVenda: null,
            produto: {
              id: null,
              accountId: null,
              quantidade: 0,
              modelo: null,
              imei: null,
              serie: null,
              precoCusto: null,
              precoMinimoVenda: null,
              precoVenda: null,
              isVisibleInPriceList: true,
              isNovo: false,
              grade: null,
              descricao: null,
              cor: null,
              armazenamento: null,
              bateria: null,
              garantiaApple: null,
              garantia: null,
              contents: [],
              observacao: '',
              dataEntrada: null,
              dataSaida: null,
              status: null, // Enumerado, definir valores padrão ou transformar em String
              tipoProduto: null, // Enumerado, definir valores padrão ou transformar em String
              ncm: null,
              cfop: null,
              cst: null,
            }
          }
        ],
        status: null,
        frete: null,
        encargos: null,
        nfe: null,
        subTotal: null,
        contasAPagar: {
          id: null,
          valor: 0,
          desconto: 0,
          juros: 0,
          dataVencimento: null,
          recebedorId: null,
          nomeRecebedor: '',
          transacoes: [],
          status: '',
          tipoDespesa: {},
          accountId: null,
          observacao: ''
        },
        total: null
      },
      cotacoesOriginal: [],
      page: 1,
      fornecedoresOptions: [],
      isModelValid: true,
      selectedProduto: null,
      isEditable: false,
      abaAtiva: 'cotacao',
      isLoading: false,
      isLoadingTransacao: false,
      isChanged: false,
      isModalVisible: false,
      pageSize: 20,
      totalContents: 0,
      clickOutsideEventListener: null,
      newCodigoRastreio: null,
      dadosRastreamento: {},
      gruposDeObjetos: [],
      isModalTextVisible: null,
      isLoadingCotacao: false
    }
  },
  computed: {
    currencyMixin() {
      return currencyMixin
    },
    ...mapState(['role']),
  },
  watch: {
    dadosRastreamento(newValue) {
      const key = Object.keys(newValue)[0];
      const objetos = newValue[key]?.objetos || [];
      this.gruposDeObjetos = this.agruparObjetos(objetos);
    }
  },
  created() {
    this.loadPedidosByDate()
    this.getFornecedores()
    this.getBancos()
  },
  methods: {
    async getBancos() {
      const response = await axios.get(`api/financeiro/banco`);
      this.bancos = await response.data.map(m => ({
        id: m.id,
        nome: m.nome,
        razaoSocialOrName: m.razaoSocialOrName ? m.razaoSocialOrName : 'Sem Razao Social',
        conta: m.conta,
        agencia: m.agencia
      }));
    },
    calcularLucro(produto) {
      let lucroMinimo, lucroVenda;

      if (!produto.precoMinimoVenda || produto.precoMinimoVenda === 0) {
        lucroMinimo = 'Sem preço mínimo de venda';
      } else {
        lucroMinimo = produto.precoMinimoVenda - produto.precoCusto;
      }

      if (!produto.precoVenda || produto.precoVenda === 0) {
        lucroVenda = 'Sem preço de venda';
      } else {
        lucroVenda = produto.precoVenda - produto.precoCusto;
      }

      return {
        lucroMinimo,
        lucroVenda
      };
    },

    calcularLucroTotalVenda(cotacao) {
      let lucroTotal = 0;
      cotacao.produtos.forEach(produto => {
        if (produto.precoVenda > 0) {
          lucroTotal += produto.precoVenda - produto.precoCusto;
        }
      });
      return lucroTotal;
    },

    calcularLucroTotalMinimo(cotacao) {
      let lucroTotal = 0;
      cotacao.produtos.forEach(produto => {
        if (produto.precoMinimoVenda > 0) {
          lucroTotal += produto.precoMinimoVenda - produto.precoCusto;
        }
      });
      return lucroTotal;
    },
    async sendText(text, id) {
      const dto = {
        content: text
      }
      const idToast = toast.loading(
          'Processando os produtos...',
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: "flip",
            theme: "dark"
          },
      );
      try {
        await axios.post(`api/cotacao/format/${id}`, dto);

        this.loadPedidosByDate()

        toast.update(idToast, {
          render: 'Cotação foi atualizada!',
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          type: 'success',
          isLoading: false,
        });
      } catch (e) {
        console.log(e)
        toast.update(idToast, {
          render: 'Erro ao atualizar a cotação!',
          autoClose: true,
          closeOnClick: true,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      }

    },
    getIcon(descricao) {
      if (!descricao) return 'fa fa-truck';

      const descricaoMin = descricao.toLowerCase();
      if (descricaoMin.includes("objeto postado")) {
        return 'fa fa-check-circle'
      } else if (descricaoMin.includes("objeto entregue")) {
        return 'fa fa-check-circle'
      } else {
        return 'fa fa-truck'
      }
    },
    validarCodigo(codigo) {
      const regex = /^[A-Za-z]{2}\d{9}[A-Za-z]{2}$/;
      return regex.test(codigo);
    },
    async carregarRastreamento(cotacao) {
      const data = await this.getCodigoRastreio(cotacao.id);
      this.dadosRastreamento = {...this.dadosRastreamento, [cotacao.id]: data};
    },
    agruparObjetos(dados) {
      const grupos = [];
      let grupoAtual = [];

      dados.forEach((objeto, index) => {
        grupoAtual.push(objeto);
        if (grupoAtual.length === 3 || index === dados.length - 1) {
          grupos.push(grupoAtual);
          grupoAtual = [];
        }
      });

      return grupos;
    },
    formatarEndereco(evento) {
      if (!evento.unidade || !evento.unidade.endereco) return '';

      const enderecoFormatado = `${evento.unidade.endereco.cidade} - ${evento.unidade.endereco.uf}`;
      if (evento.unidadeDestino && evento.unidadeDestino.endereco) {
        return `${enderecoFormatado} para ${evento.unidadeDestino.endereco.cidade} - ${evento.unidadeDestino.endereco.uf}`;
      }
      return enderecoFormatado;
    },
    formatarData(data) {
      const date = new Date(data);
      return date.toLocaleDateString('pt-BR') + ' ' + date.toLocaleTimeString('pt-BR');
    },
    replicar(produto, cotacao) {
      let newProduto = JSON.parse(JSON.stringify(produto));
      newProduto.imei = null
      newProduto.id = null
      cotacao.produtos.push(newProduto)
      this.isChanged = true
    },
    agrupaProdutosPortTotal(produtos) {
      const contador = {};
      if (!produtos) {
        return []
      }
      produtos.forEach(produto => {
        // Tratando valores nulos e convertendo para minúsculas
        const modelo = produto.modelo ? produto.modelo.toLowerCase() : 'null';
        // const cor = produto.cor ? produto.cor.toLowerCase() : 'null';
        const armazenamento = produto.armazenamento ? produto.armazenamento.toLowerCase() : 'null';

        // Criando chave única
        // const chave = `${modelo}|${cor}|${armazenamento}`;
        const chave = `${modelo}|${armazenamento}`;

        // Contagem de produtos
        contador[chave] = (contador[chave] || 0) + 1;
      });

      const resultado = Object.keys(contador).map(chave => {
        // const [modelo, cor, armazenamento] = chave.split('|');
        const [modelo, armazenamento] = chave.split('|');
        return {
          modelo: modelo === 'null' ? null : modelo,
          // cor: cor === 'null' ? null : cor,
          armazenamento: armazenamento === 'null' ? null : armazenamento,
          total: contador[chave]
        };
      });

      // Ordenando os resultados por modelo e armazenamento
      resultado.sort((a, b) => {
        if (a.modelo !== b.modelo) {
          return a.modelo.localeCompare(b.modelo);
        }
        if (a.armazenamento !== b.armazenamento) {
          return a.armazenamento.localeCompare(b.armazenamento);
        }
        return 0;
      });

      // Convertendo o resultado para HTML
      const listaHtml = '<ul>' + resultado.map(item => {
        // return `<li>${item.modelo || 'Sem modelo'}, ${item.cor || 'Sem cor'}, ${item.armazenamento || 'Sem armazenamento'}: ${item.total}</li>`;
        return `<li><h6>${item.modelo || 'Sem modelo'} ${item.armazenamento || ''}: ${item.total}</h6></li>`;
      }).join('') + '</ul>';

      return listaHtml;
    },
    async deletarRastreamento(cotacao, idRastreamento) {
      try {
        await axios.delete(`api/cotacao/${cotacao.id}/rastreio?codigo=${idRastreamento}`);
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao deletar: ${e}!`,
        });
      } finally {
        await this.carregarRastreamento(cotacao)
      }

    },
    async excluirTransacao(id, index, conta) {
      if (!id) {
        conta.transacoes.splice(index, 1);
        return
      }
      try {
        const axiosResponse = await axios.delete(`api/contasAPagar/transacao/${conta.id}/${id}`);
        const pedido = axiosResponse.data;
        conta.transacoes.splice(index, 1);
        await this.$nextTick(() => {
          conta.status = pedido.status
        });
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao deletar: ${e}!`,
        });
      }

    },
    isDisabled(transacao) {
      return !transacao.valor || !transacao.metodoPagamento || !transacao.banco
    },
    async changeTransacao(transacao, cotacao) {
      if (this.isDisabled(transacao)) {
        return
      }
      this.isLoadingTransacao = true
      const data = {
        confirmado: true,
        valor: transacao.valor,
        metodoPagamento: transacao.metodoPagamento,
        dataPagamento: currencyMixin.methods.converterDataAndHoraParaLong(transacao.dataPagamento),
        id: transacao.id,
        bancoId: transacao.banco?.id ? transacao.banco?.id : transacao.banco
      }
      try {
        const axiosResponse = await axios.post(`api/contasAPagar/transacao/${cotacao.contasAPagar.id}`, data);
        const conta = await axiosResponse.data;
        await this.$nextTick(() => {
          cotacao.contasAPagar.transacoes = []
          conta.transacoes.forEach(t => {
            t.dataPagamento = currencyMixin.methods.longToDateSimples(t.dataPagamento);
          });
          cotacao.contasAPagar.transacoes.push(...conta.transacoes);
        });
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro ao savlar: ${e}!`,
        });
      }
      this.isLoadingTransacao = false
    },
    showModal(produto, index) {
      this.selectedProduto = produto;
      this.isModalVisible = index;
    },
    showModalText() {
      this.isModalTextVisible = true;
    },
    closeModal() {
      this.isModalVisible = null;
      this.selectedProduto = null;
    },
    async apagar(id, produtos, index) {
      if (!id) {
        produtos.splice(index, 1)
        return
      }
      try {
        await axios.delete(`api/cotacao/${id}/produto`);
      } catch (e) {
        toast(`Erro ao deletar produto`, {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      produtos.splice(index, 1)
      await this.loadPedidosByDate()
      // this.isChanged = true
    },
    update(oldProdutoCotacao, newProduto) {
      console.log("asddasadsasdsa")
      if (!oldProdutoCotacao || typeof oldProdutoCotacao !== 'object') {
        oldProdutoCotacao = {
          id: null,
          quantidade: 0,
          precoCusto: null,
          precoMinimoVenda: null,
          precoVenda: null,
        };
      }

      if (oldProdutoCotacao.produto == null) {
        oldProdutoCotacao.produto = {
          id: null,
          accountId: null,
          quantidade: 0,
          modelo: null,
          imei: null,
          serie: null,
          precoMinimoVenda: null,
          precoVenda: null,
          isVisibleInPriceList: true,
          isNovo: false,
          grade: null,
          descricao: null,
          cor: null,
          armazenamento: null,
          bateria: null,
          garantiaApple: null,
          garantia: null,
          contents: [],
          observacao: '',
          dataEntrada: null,
          dataSaida: null,
          tipoProduto: null,
          ncm: null,
          cfop: null,
          cst: null,
          modelosAplicadosTransient: null,
          modelosAplicados: null,
        }
      }

      oldProdutoCotacao.precoVenda = newProduto.precoVenda
      oldProdutoCotacao.quantidade = newProduto.quantidade
      oldProdutoCotacao.precoCusto = newProduto.precoCusto
      oldProdutoCotacao.precoMinimoVenda = newProduto.precoMinimoVenda
      let oldProduto = oldProdutoCotacao.produto

      const defaultProduto = {
        id: oldProduto.id,
        accountId: oldProduto.accountId,
        quantidade: oldProduto.quantidade,
        modelo: oldProduto.modelo,
        imei: oldProduto.imei,
        serie: oldProduto.serie,
        isVisibleInPriceList: oldProduto.isVisibleInPriceList,
        isNovo: oldProduto.isNovo,
        grade: oldProduto.grade,
        descricao: oldProduto.descricao,
        cor: oldProduto.cor,
        armazenamento: oldProduto.armazenamento,
        bateria: oldProduto.bateria,
        garantiaApple: oldProduto.garantiaApple,
        garantia: oldProduto.garantia,
        contents: oldProduto.contents,
        observacao: oldProduto.observacao,
        dataEntrada: oldProduto.dataEntrada,
        dataSaida: oldProduto.dataSaida,
        status: oldProduto.status,
        tipoProduto: oldProduto.tipoProduto,
        modelosAplicadosTransient: oldProduto.modelosAplicadosTransient,
        modelosAplicados: oldProduto.modelosAplicados,
        ncm: oldProduto.ncm,
        cfop: oldProduto.cfop,
        cst: oldProduto.cst
      };

      Object.keys(defaultProduto).forEach(key => {
        if (Object.hasOwn(newProduto, key)) {
          oldProdutoCotacao.produto[key] = newProduto[key];
        } else if (!Object.hasOwn(oldProdutoCotacao.produto, key)) {
          oldProdutoCotacao.produto[key] = defaultProduto[key];
        }
      });

      this.isChanged = true;
    },
    onCotacaoChange() {
      this.verificarMudancas();
    },
    async changeCotacao(cotacao) {
      this.isLoadingCotacao = true
      if (cotacao.status === 'FECHADO') {
        const result = await this.$swal({
          title: "Mudar status para FECHADO?",
          text: "Ao mudar, todos os produtos irao para o estoque com status DISPONIVEL",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Sair",
          confirmButtonText: "Sim!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        })
        if (!result.isConfirmed) {
          return
        }
      }
      if (cotacao.status === 'CANCELADO') {
        const result = await this.$swal({
          title: "Mudar status para CANCELADO?",
          text: "Ao mudar, todos os produtos irão ser EXCLUIDOS",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Sair",
          confirmButtonText: "Sim!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        })
        if (!result.isConfirmed) {
          return
        }
      }

      try {
        this.isLoadingCotacao = true
        await axios.put(`api/cotacao/change-status/${cotacao.id}`, {},{
          params: {
            newStatus: cotacao.status,
          }
        });
        await this.loadCotacoes()
        this.$swal({
          title: "Cotação salva!",
          icon: "success",
        });
      } catch (e) {
        this.$swal({
          title: "Erro ao salvar cotação!",
          text: `Erro: ${e}`,
          icon: "error",
        });
      } finally {
        this.cardSelecionado = cotacao
      }
      this.isLoadingCotacao = false
    },
    async salvarCotacao(cotacao) {
      this.isLoadingCotacao = true
      try {
        this.isLoadingCotacao = true
        await this.updateCotacao(cotacao);
        await this.loadCotacoes()
        this.$swal({
          title: "Cotação salva!",
          icon: "success",
        });
      } catch (e) {
        this.$swal({
          title: "Erro ao salvar cotação!",
          text: `Erro: ${e}`,
          icon: "error",
        });
      } finally {
        this.cardSelecionado = cotacao
      }
      this.isLoadingCotacao = false
    },
    async updateCotacao(cotacao) {
      if (cotacao.id) {
        this.cotacoes.forEach(cotacao => cotacao
            .produtos.forEach(produto => produto.produto.garantiaApple = currencyMixin.methods.converterDataAndHoraParaLong(produto.garantiaApple)))
      }
      await axios.put(`api/cotacao`, cotacao);
      this.isChanged = false
    },
    async getCodigoRastreio(id) {
      try {
        const response = await axios.get(`api/cotacao/${id}/rastreio`);
        return response.data
      } catch (e) {
        console.log("erro ao buscar codigo de rastreio")
      }
    },
    async addCodigoRastreio(cotacao, newCodigo) {
      try {
        await axios.post(`api/cotacao/${cotacao.id}/rastreio?codigo=${newCodigo}`);
        this.newCodigoRastreio = null
      } catch (e) {
        console.log("erro ao adicionar codigo de rastreio")
      } finally {
        await this.carregarRastreamento(cotacao)
      }
    },
    async getFornecedores() {
      let res = await axios.get(`api/fornecedor`);
      this.fornecedoresOptions = res.data
    },
    addProduto(cotacao) {
      if (!cotacao.produtos) {
        cotacao.produtos = []
      }
      cotacao.produtos.push({})
      const newProductIndex = cotacao.produtos.length - 1;

      this.showModal({}, newProductIndex)
    },
    valorTotalProdutos(cotacao) {
      if (!cotacao.produtos) return 0
      return cotacao.produtos.reduce((acc, produto) => acc + produto.precoCusto * produto.quantidade, 0);
    },
    valorTotalCotacao(cotacao) {
      if (!cotacao.produtos) return 0
      return cotacao.produtos.reduce((acc, produto) => acc + produto.precoCusto * produto.quantidade, 0) + cotacao.total;
    },
    subTotalAMaisPorAparelho(cotacao) {
      if (!cotacao.produtos) return 0
      if (cotacao.status === 'FECHADO') {
        return 0;
      }
      return cotacao.total / cotacao.produtos.length;
    },
    longToDateFormatted(timestamp) {
      if (!timestamp) {
        return 'Sem data'
      }
      return currencyMixin.methods.longToDateBrasil(timestamp)
    },
    loadPedidosByDate(page) {
      this.page = page == null ? 1 : page
      this.pageSize = 20
      this.loadCotacoes()
    },
    verificarMudancas() {
      this.isChanged = JSON.stringify(this.cotacoes) !== JSON.stringify(this.cotacoesOriginal);
    },
    async loadCotacoes() {
      this.isLoading = true
      try {
        const response = await axios.get(`api/cotacao`, {
          params: {
            status: this.status == null ? 'ABERTO' : this.status,
            page: this.page - 1,
            size: this.pageSize
          }
        })
        const responseData = response.data;
        this.cotacoes = responseData.content;
        this.totalContents = responseData.totalElements
        await this.cotacoes.forEach(cotacao => {
          cotacao.contasAPagar.transacoes.forEach(transacao => {
            transacao.dataPagamento = currencyMixin.methods.longToDateSimples(transacao.dataPagamento);
          });
        })
        this.cotacoesOriginal = JSON.parse(JSON.stringify(this.cotacoes));
      } catch (error) {
        console.error("Erro ao carregar Produtos:", error);
      }
      this.isLoading = false
    },
    removeFilters() {
      this.status = null
      this.clienteName = null
      this.loadCotacoes()
    },
    addCotacao() {
      this.cotacoes.unshift({fornecedor: {}});
    },
  }
}
;
</script>

<style scoped>
.card {
  border: 1px solid;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card:hover {
  background-color: #f5f5f5;
}

.dropdown-menu {
  min-width: 200px; /* Define a largura mínima do menu dropdown */
  background-color: #ffffff; /* Cor de fundo do menu dropdown */
  border: 1px solid #ddd; /* Borda do menu dropdown */
  border-radius: 0.25rem; /* Arredondamento das bordas */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para adicionar profundidade */
}

.dropdown-item {
  color: #495057; /* Cor do texto dos itens */
  padding: 10px 20px; /* Espaçamento interno para aumentar a área de clique e melhorar a legibilidade */
  transition: background-color 0.2s ease-in-out; /* Transição suave para o efeito de hover */
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse ou focar */
  color: #0056b3; /* Cor do texto ao passar o mouse ou focar */
  text-decoration: none; /* Remove o sublinhado do texto ao passar o mouse ou focar */
}

.dropdown-item:active {
  background-color: #e9ecef; /* Cor de fundo ao clicar */
  color: #004085; /* Cor do texto ao clicar */
}
</style>
