<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <!-- Card header -->
        <div class="pb-0 card-header">
          <div class="d-lg-flex col">
            <!--              <div class="col-4 form-group custom-select-container">-->
            <!--                <label><i class="bi bi-person-fill me-2"></i>Cliente:</label>-->
            <!--                <input-->
            <!--                    v-model="clienteName"-->
            <!--                    class="form-control"-->
            <!--                    type="text"-->
            <!--                    @input="searchCliente"-->
            <!--                />-->
            <!--                <ul v-if="clientes.length" class="custom-select">-->
            <!--                  <li v-for="result in clientes" :key="result.id" @click="selectResult(result)">-->
            <!--                    {{ result.nome }} {{ result.sobrenome }}-->
            <!--                  </li>-->
            <!--                </ul>-->
            <!--              </div>-->
            <div class="col-2">
              <label>Data:</label>
              <select v-model="dateSelected" class="form-control" @change.prevent="loadPedidosByDate">
                <option value="7">Ultimos 7 dias</option>
                <option value="14">Ultimos 14 dias</option>
                <option value="30">Ultimos 30 dias</option>
              </select>
            </div>
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="dropdown d-inline">
                <soft-button
                    id="navbarDropdownMenuLink2"
                    color="dark"
                    variant="outline"
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >Filtros
                </soft-button
                >
                <ul
                    class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                    aria-labelledby="navbarDropdownMenuLink2"
                    style
                >
                  <li>
                    <a class="dropdown-item border-radius-md"
                       :class="{ selected: status === 'ABERTO' }"
                       @click.stop="status = 'ABERTO'"
                    >Status: ABERTO</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item border-radius-md"
                       :class="{ selected: status === 'FECHADO' }"
                       @click.stop="status = 'FECHADO'"
                    >Status: FECHADO</a
                    >
                  </li>
                  <li>
                    <hr class="horizontal dark my-2"/>
                  </li>
                  <li class="px-4 mt-2">
                    <button class="btn btn-sm btn-primary" @click.prevent="loadFinanceiros()">Filtrar</button>
                  </li>
                  <li>
                    <hr class="horizontal dark my-2"/>
                  </li>
                  <li>
                    <a
                        class="dropdown-item px-4 mt-2 border-radius-md text-danger"
                        @click.prevent="removeFilters()"
                    >Remove Filter</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <table id="order-list" class="table table-flush">
          <tbody>

          <div class="card-body">
            <div class="col-sm-4">
              <div class="card border">
                <div class="card-body">
                  <h6 class="card-title">Total em estoque:</h6>
                  <strong>Quantidade: </strong>
                </div>
              </div>
            </div>
          </div>
          <tr v-for="(pedido, index) in pedidos" :key="index" class="card" @click="cardSelecionado = pedido">
            <td class="d-flex justify-content-between align-items-center">
              <h3>{{ labelProduto(pedido.produtos) }} </h3>
              <h4>{{ pedido.cliente.nome }} {{ pedido.cliente.sobrenome }}</h4>
              <span
                  :class='pedido.financeiro.status === "FECHADO" ? "badge badge-success badge-sm" : "badge badge-danger badge-sm"'
              >{{ pedido.financeiro.status }}</span
              >
              <button class="btn btn-sm btn-primary" @click.stop="editItem(pedido)">Editar</button>
            </td>

            <td class="d-flex justify-content-between">
              <span>Finalizado: {{ longToDateFormatted(pedido.dataFinalizado) }}</span>
            </td>

            <td>
              <h4>Valor Pago: {{ totalPago(pedido.financeiro.transacoes) }}</h4>
              <h5>Valor Total: {{ pedido.financeiro.valorTotal }}</h5>
            </td>

            <td v-if="cardSelecionado === pedido">
              <ul class="list-group">
                <li v-for="(transacao, index) in pedido.financeiro.transacoes" :key="index"
                    class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="input-group">
                    <!-- Envolve o input e o label em um div com classe 'input-group-prepend' para alinhamento -->
                    <div class="input-group-prepend">
                      <input v-model="transacao.valor" class="form-control" type="number"
                             @change.prevent="changeTransacao(transacao)">
                    </div>
                    <!-- Usa 'input-group-text' para alinhar os textos -->
                    <span class="input-group-text"> {{ transacao.metodoPagamento }}</span>
                    <span class="input-group-text"> {{ formatDateP(transacao.dataPagamento) }}</span>
                    <!-- Utiliza a classe 'col' para definir a largura e alinhamento do elemento -->
                    <div class="col-3">
                      <label>Confirmado?
                        <input v-model="transacao.confirmado" type="checkbox"
                               @change.prevent="changeTransacao(transacao)">
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SoftButton from "@/components/SoftButton.vue";

import {DateTime} from "luxon";

export default {
  name: "ProductsList",
  components: {SoftButton},
  data() {
    return {
      pedidos: [],
      status: null,

      cardSelecionado: null,
      clienteName: null,
      clientes: [],
      dateSelected: 7,
      startDate: null,
      endDate: null,
    }
  },
  created() {
    this.loadPedidosByDate()
  },
  methods: {
    async changeTransacao(transacao) {
      await axios.post(`api/financeiro/transacao/confirm/${transacao.id}`, {},
          {
            params: {confirmado: transacao.confirmado, valor: transacao.valor}
          });

      await this.loadFinanceiros()
    },
    formatDateP(longValue) {
      if (longValue == null) {
        return 'Sem data';
      }
      const dt = DateTime.fromMillis(longValue, {zone: 'America/Sao_Paulo'});
      return dt.toFormat('dd/MM/yyyy HH:mm');
    },
    stringDateToLong(dateString) {
      if (!dateString) {
        return null
      }
      const date = new Date(dateString);
      return date.getTime();
    },
    labelProduto(produto) {
      return produto.map(p => p.modelo).join(', ');
    },
    totalPago(transacoes) {
      return transacoes.reduce((acc, transacao) => acc + transacao.valor, 0);
    },
    longToDateFormatted(timestamp) {
      if (!timestamp) {
        return 'sem data final'
      }
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // adicione +1 porque getMonth() começa de 0
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },
    editItem(aluguel) {
      this.$router.push(`/ecommerce/pedido/${aluguel.id}`);
    },
    loadPedidosByDate() {
      let dataAtual = new Date();
      let seteDiasAtras = new Date(dataAtual.getTime() - this.dateSelected * 24 * 60 * 60 * 1000);
      this.startDate = seteDiasAtras;
      this.endDate = dataAtual;
      this.loadFinanceiros()
    },
    async loadFinanceiros() {
      const queryParams = {
        financeiroStatus: this.status == null ? 'ABERTO' : this.status,
        dataInicial: this.stringDateToLong(this.startDate),
        dataFinal: this.stringDateToLong(this.endDate)
      }
      try {
        const response = await axios.get(`api/pedido/aberto`, {
          params: queryParams
        });
        this.pedidos = response.data.content;
        this.pedidos = this.pedidos.filter(al => al.cliente != null)
      } catch (error) {
        console.error("Erro ao carregar Produtos:", error);
      }
    },
    searchCliente() {
      if (this.clienteName.length > 3) {
        axios.get(`api/cliente`, {
          params: {
            nome: this.clienteName
          }
        })
            .then(response => {
              this.clientes = response.data
            })
            .catch(error => {
              console.log(error)
            });
      }
    },
    removeFilters() {
      this.status = null
      this.clienteName = null
      this.loadFinanceiros()
    },
    async selectResult(cliente) {
      try {
        const response = await axios.get(`api/pedido`, {
          params: {
            financeiroStatus: this.status,
            clienteId: cliente.id
          }
        });
        this.pedidos = response.data;
      } catch (error) {
        console.error("Erro ao carregar Produtos:", error);
      }
      this.clientes = []
    },
  }
};
</script>

<style scoped>
.card {
  border: 1px solid;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card:hover {
  background-color: #f5f5f5;
}

.custom-select-container {
  position: relative;
}
</style>
