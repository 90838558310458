<template>
  <li class="nav-item">
    <router-link v-if="!isHttpsUrl(to)" class="nav-link" :to="to">
      <span class="sidenav-normal"> {{ text }} </span>
    </router-link>
    <div v-else class="nav-link">
      <a class="sidenav-normal" @click.prevent="openUrlInNewTab(to)"> {{ text }} </a>
    </div>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    isHttpsUrl(str) {
      if (typeof str === 'object') {
        return str.name.startsWith('https');
      }
      return str.startsWith('https');
    },
    openUrlInNewTab(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.nav-link {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  padding: 10px 15px; /* Ajuste o padding conforme necessário */
}

.nav-link:hover {
  background-color: #f8f9fa; /* Cor de fundo ao passar o mouse */
  border-radius: 5px; /* Borda arredondada */
}

.sidenav-mini-icon {
  margin-right: 10px; /* Espaçamento entre o ícone e o texto */
  font-size: 1.2em; /* Tamanho do ícone */
}

</style>