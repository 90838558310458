<template>
  <div
      class="card multisteps-form__panel p-3 border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Cliente</h5>
    <div class="form-group col-6 mt-3 mb-3">
      <label>Procure um cliente caso exista:</label>
      <Multiselect  v-model="nomeSobrenome"
                    :options="clientes"
                    :custom-label="customLabel"
                    track-by="id"
                    :multiple="false"
                    @select="setCliente()">
      </Multiselect>

    </div>
    <div class="multisteps-form__content">

      <div class="form-group col-3 mt-3">
        <label>Tipo de cliente:</label>
        <select v-model="cliente.tipo" class="form-control">
          <option selected value="PF">Pessoa Fisica</option>
          <option value="PJ">Pessoa Juridica</option>
        </select>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 form-group">
          <label for="inputNome"><i class="bi bi-person-fill me-2"></i>{{ cliente.tipo === 'PF' ? 'Nome:' : 'Razão social:' }}</label>
          <input
              v-model="cliente.nome"
              class="multisteps-form__input form-control "
              type="text"
              :disabled="searchClient"
          />
        </div>
        <div class="col-md-6 form-group">
          <label for="inputSobrenome">{{ cliente.tipo === 'PF' ? 'Sobrenome:' : 'Fantasia:' }}</label>
          <input id="inputSobrenome" v-model="cliente.sobrenome" class="multisteps-form__input form-control"
                 type="text"
                 :disabled="searchClient"/>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label for="inputCEP"><i class="bi bi-house-door-fill me-2"></i>CEP:</label>
          <input id="inputCEP" v-model="cliente.cep" class="form-control" placeholder="Digite o CEP"
                 @input="formatCep()">
        </div>
        <div class="col-md-2 form-group mt-4">
          <button class="btn btn-sm btn-success" @click.prevent="fetchAddress">
            <i v-if="!searchAddress" class="fas fa-search"></i>
            <i v-else class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
        <div class="col-md-7 form-group">
          <label for="inputEndereco">Endereço:</label>
          <input id="inputEndereco" v-model="cliente.endereco" class="form-control" type="text"/>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label>Bairro:</label>
          <input v-model="cliente.bairro" class="form-control" type="text"/>
        </div>
        <div class="col-md-1 form-group">
          <label>UF:</label>
          <input v-model="cliente.uf" class="form-control" type="text"/>
        </div>
        <div class="col-md-1 form-group">
          <label for="inputNumero">Numero:</label>
          <input id="inputNumero" v-model="cliente.numero" class="form-control" type="text"/>
        </div>
        <div class="col-md-4 form-group">
          <label for="inputCelular"><i class="bi bi-telephone-fill me-2"></i>Numero do celular:</label>
          <input id="inputCelular" v-model="cliente.cellphone" class="form-control" type="text"
                 placeholder="(51) 99999-4847"
                 @input="formatarCelular"/>
<!--          <span v-if="validarCelular" style="color: red">Celular não está formatado corretamente</span>-->
        </div>
        <div class="col-md-2 form-group">
          <label>Sexo:</label>
          <select v-model="cliente.sexo" class="form-control">
            <option selected value="M">Masculino</option>
            <option value="F">Feminino</option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3 form-group">
          <label>Instagram:</label>
          <input v-model="cliente.instagram" class="form-control" type="text"/>
        </div>
        <div class="col-md-3 form-group">
          <label for="inputCPF"><i class="bi bi-card-text me-2"></i> {{ cliente.tipo === 'PF' ? 'CPF:' : 'CNPJ:' }}</label>
          <input v-if="cliente.tipo === 'PF'" id="inputCPF" v-model="cliente.cpf" class="form-control"
                 type="text" :disabled="searchClient"
                 @input="formatCpf">
          <input v-else id="inputCPF" v-model="cliente.cpf" class="form-control"
                 type="text" :disabled="searchClient"
                 @input="formatCnpj">
          <span v-if="cliente.tipo === 'PF' && !isValidCpf()" class="invalid-feedback">CPF inválido!</span>
          <span v-if="cliente.tipo === 'PJ' && !isValidCnpj()" class="invalid-feedback">CNPJ inválido!</span>
        </div>
        <div class="col-md-2 mt-4">
          <button :disabled="!isValidCpf()"
                  v-tippy="'Pesquise o nome completo e data de nascimento pelo CPF'"
                  class="btn btn-success" @click.prevent="searchCpf">
            <i v-if="!searchClient" class="fas fa-search"></i>
            <i v-else class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
        <div class="col-md-2 form-group">
          <label for="inputRG">{{ cliente.tipo === 'PF' ? 'RG:' : 'IE:' }}</label>
          <input id="inputRG" v-model="cliente.rg" class="form-control" type="text"/>
        </div>
        <div class="col-md-2 form-group">
          <label>Data nascimento:</label>
          <input v-model="cliente.dataNascimento" class="form-control" type="date" :disabled="searchClient"/>
        </div>
      </div>

      <div class="form-group">
        <label for="inputEmail"><i class="bi bi-envelope-fill me-2"></i>EMAIL:</label>
        <input id="inputEmail" v-model="cliente.email" class="form-control" type="email"
               placeholder="seuemail@example.com"
               list="emailSuggestions" @input="sugerirDominio"/>
        <datalist id="emailSuggestions">
          <option v-for="(dominio, index) in dominiosPopulares" :key="index"
                  :value="cliente.email + dominio"></option>
        </datalist>
      </div>
      <div class="button-row d-flex mt-4">
        <soft-button
            type="button"
            color="light"
            variant="gradient"
            class="js-btn-prev"
            @click.prevent="this.$parent.prevStep"
        >Anterior
        </soft-button
        >
        <soft-button
            :disabled="check"
            type="button"
            color="dark"
            variant="gradient"
            class="ms-auto js-btn-next"
            @click.prevent="save"
        >Salvar e ir pro proximo
        </soft-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";

import {currencyMixin} from "@/mixins/currencyMixin";
import Multiselect from "vue-multiselect";
import {toast} from "vue3-toastify";

export default {
  name: "Cliente",
  components: {
    Multiselect,
    SoftButton,
  },
  props: {
    pedido: {
      type: [Object, Object],
      required: true
    },
  },
  emits: ['fetch-cliente'],
  data() {
    return {
      cliente: {
        nome: null,
        sobrenome: null,
        cep: null,
        endereco: null,
        cidade: null,
        bairro: null,
        numero: null,
        cellphone: null,
        rg: null,
        cpf: null,
        email: null,
        uf: null,
      },
      dominiosPopulares: ['gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com'],
      nomeSobrenome: null,

      clientes: [],
      searchClient: false,
      searchAddress: false,
      originalClient: null
    }
  },
  computed: {
    check() {
      return !this.cliente.nome || !this.cliente.sobrenome
    },
    validarCelular() {
      if (this.cliente.cellphone?.length > 7) {
        const regex = /^\d{11}$/;
        return regex.test(this.cliente.cellphone);
      }
      return false;
    }
  },
  created() {
    this.getClientes();
    if (this.pedido.cliente) {
      this.cliente = this.pedido.cliente
      this.originalClient = JSON.stringify(this.pedido.cliente)

      if (this.pedido.cliente.tipo === 'PF') {
        this.formatCpf()
      } else {
        this.formatCnpj()
      }
      this.formatarCelular()
      this.formatCep()
      this.formatarDataNascimento()

      this.cliente.dataNascimento = currencyMixin.methods.longToDateSimples(this.cliente.dataNascimento)
    } else {
      this.cliente.tipo = 'PF'
    }
  },
  methods: {
    customLabel ({ nome, sobrenome }) {
      return `${nome} ${sobrenome}`
    },
    isValidCpf() {
      return this.validateCPF(this.cliente.cpf);
    },
    isValidCnpj() {
      return this.validateCPF(this.cliente.cpf);
    },
    formatCpf() {
      this.cliente.cpf = currencyMixin.methods.formatarCPF(this.cliente.cpf)
    },
    formatCnpj() {
      this.cliente.cpf = currencyMixin.methods.formatarCNPJ(this.cliente.cpf)
    },
    formatCep() {
      this.cliente.cep = currencyMixin.methods.formatCep(this.cliente.cep)
    },
    formatarCelular() {
      this.cliente.cellphone = currencyMixin.methods.formatarCelular(this.cliente.cellphone)
    },
    formatarDataNascimento() {
      this.cliente.dataNascimento = currencyMixin.methods.longToDateSimples(this.cliente.dataNascimento)
    },
    isClientChanged(client) {
      return this.originalClient !== JSON.stringify(client);
    },
    async save() {
      const clienteDto = this.cliente

      clienteDto.cpf = currencyMixin.methods.limparStrings(this.cliente.cpf)
      clienteDto.cellphone = currencyMixin.methods.limparStrings(this.cliente.cellphone)
      clienteDto.cep = currencyMixin.methods.limparStrings(this.cliente.cep)
      clienteDto.dataNascimento = currencyMixin.methods.converterDataParaLong(this.cliente.dataNascimento)

      if (!this.isClientChanged(clienteDto)) {
        this.$parent.nextStep()

        if (this.pedido.cliente.tipo === 'PF') {
          this.formatCpf()
        } else {
          this.formatCnpj()
        }
        this.formatarCelular()
        this.formatCep()
        this.formatarDataNascimento()

        return
      }

      try {
        const response = await axios.post(`api/cliente`, this.cliente)
        const cliente = response.data
        this.cliente.id = cliente.id

        await axios.put(`api/pedido/cliente/${cliente.id}/${this.pedido.id}`)
        toast("Cliente atualizado ou criado", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast(`Erro a salvar cliente: ${e}`, {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      this.$parent.nextStep()
      this.$emit("fetch-cliente", this.cliente)

      if (this.pedido.cliente.tipo === 'PF') {
        this.formatCpf()
      } else {
        this.formatCnpj()
      }
      this.formatarCelular()
      this.formatCep()
      this.formatarDataNascimento()
    },
    async searchCpf() {
      try {
        this.searchClient = true
        const response = await axios.get(`api/cliente/search`, {
          params: {
            cpfOrCnpj: currencyMixin.methods.limparStrings(this.cliente.cpf),
            tipo: this.cliente.tipo
          }
        });

        const cliente = await response.data
        this.cliente.nome = cliente.nome
        this.cliente.sobrenome = cliente.sobrenome
        this.cliente.sexo = cliente.sexo
        this.cliente.dataNascimento = currencyMixin.methods.longToDateSimples(cliente.dataNascimento)
      } catch (e) {
        this.$swal({
          title: "Erro ao buscar CPF!",
          text: `${e}`,
          icon: "error",
        });
      }
      this.searchClient = false
    },
    async getClientes() {
      const response = await axios.get(`api/cliente`)
      this.clientes = response.data
    },
    sugerirDominio() {
      // Sugere domínios apenas se "@" foi digitado e não há outro ponto após o "@"
      if (this.cliente.email.includes('@') && !this.cliente.email.split('@')[1].includes('.')) {
        this.dominiosPopulares = ['gmail.com', 'outlook.com', 'yahoo.com.br'];
      } else {
        this.dominiosPopulares = [];
      }
    },
    setCliente() {
      this.cliente = this.nomeSobrenome
    },
    validateCPF(cpf) {
      if (!cpf || cpf.length < 11) return false
      cpf = cpf.replace(/[^\d]+/g, '');
      if (!cpf) return false;

      if (cpf.length !== 11 ||
          cpf === "00000000000" ||
          cpf === "11111111111" ||
          cpf === "22222222222" ||
          cpf === "33333333333" ||
          cpf === "44444444444" ||
          cpf === "55555555555" ||
          cpf === "66666666666" ||
          cpf === "77777777777" ||
          cpf === "88888888888" ||
          cpf === "99999999999")
        return false;

      let add = 0;
      for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      if (rev !== parseInt(cpf.charAt(9)))
        return false;

      add = 0;
      for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11)
        rev = 0;
      return rev === parseInt(cpf.charAt(10));
    },
    async fetchAddress() {
      let cep = currencyMixin.methods.formatCep(this.cliente.cep)
      if (cep && cep.length >= 8) {
        this.searchAddress = true
        try {
          this.cliente.cep = this.cliente.cep.replace(/\D/g, '');
          const response = await fetch(`https://viacep.com.br/ws/${this.cliente.cep}/json/`);
          const data = await response.json();
          this.cliente.endereco = data.logradouro
          this.cliente.bairro = data.bairro
          this.cliente.cidade = data.localidade
          this.cliente.uf = data.uf
        } catch (error) {
          console.error("Erro ao buscar o endereço:", error);
        }
        this.searchAddress = false
      }
    },
  },
};
</script>
