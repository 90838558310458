/**
 =========================================================
 * Vue Soft UI Dashboard PRO - v3.0.0
 =========================================================
 * Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 Coded by www.creative-tim.com
 =========================================================
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import axios from "axios";
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import 'tippy.js/dist/tippy.css'; // CSS padrão
import 'tippy.js/themes/light.css'
import Tippy from 'vue-tippy';
import ToastPlugin from './toastPlugin';
import {setupCalendar, Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.use(Tooltip);
// appInstance.use(BootstrapVue);
// appInstance.use(IconsPlugin);
appInstance.use(Tippy, {
    theme: 'light',
});
appInstance.use(ToastPlugin);
appInstance.use(Vue3Toastify, {
    autoClose: 3000,
});
// Use plugin defaults (optional)
appInstance.use(setupCalendar, {})

// Use the components
appInstance.component('VCalendar', Calendar)
appInstance.component('VDatePicker', DatePicker)
appInstance.component('Multiselect', Multiselect)
appInstance.mount("#app");

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Obtém o hostname atual
    const host = window.location.hostname;

    // Verifica se está em localhost
    if (host === 'localhost') {
        // Configura o baseURL para localhost na porta 8090
        config.baseURL = 'http://localhost:8090/';
    } else if (!host.endsWith('.local')) {
        // Aqui, substituímos o subdomínio pelo domínio principal se estiver presente
        // Ajuste "seudominio.com" para o seu domínio principal
        const domainParts = host.split('.');
        while (domainParts.length > 2) domainParts.shift(); // Remove subdomínios
        const mainDomain = domainParts.join('.');

        // Configura o baseURL para o domínio principal.
        // Ajuste o protocolo (http ou https) conforme necessário
        config.baseURL = `https://${mainDomain}/`;
    }
    // Se o host termina com .local, não modifica o baseURL, mantendo o comportamento padrão
    return config;
}, async error => {
    if (error.response && error.response.status === 403) {
        await router.push('/authentication/signin/basic');
    }
    return Promise.reject(error);
});

axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 403) {
            await router.push('/authentication/signin/basic');
        }
        return Promise.reject(error);
    }
);

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");

    // Verificar se a rota é /go/ com os parâmetros de consulta específicos
    const isAuthorizedGoRoute = to.path === '/go/' && to.query.param1 && to.query.param2;
    if (!token && isAuthorizedGoRoute) {
        next('/authentication/signin/basic');
    } else {
        next();
    }
});