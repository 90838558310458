<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="row col-12">
        <div class="card-body">
          <div class="row align-items-end"> <!-- Adiciona alinhamento vertical -->
            <div class="col-2">
              <label>Data inicial</label>
              <input v-model="startDate" type="date" class="form-control form-control-sm">
            </div>
            <div class="col-2">
              <label>Data final</label>
              <input v-model="endDate" type="date" class="form-control form-control-sm">
            </div>
            <div class="col-3 d-flex align-items-center">
              <div>
                <span class="badge btn btn-info" @click.prevent="getOverview()">Buscar</span>
                <span class="badge btn btn-danger"
                      @click.prevent="startDate = null; endDate = null; getOverview()">Limpar filtros
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 col-12">
        <default-statistics-card
            :loading="loading"
            title="Total de receitas"
            :count='formatValue(overviewData.totalTransacionadoEmReceita)'
            :icon="'fas fa-arrow-alt-circle-up'"
            :menu="'(Fluxo de caixa)'"
        />
        <default-statistics-card
            :loading="loading"
            title="Total de debitos"
            :count='formatValue(overviewData.totalTransacionadoEmDespesas)'
            :icon="'fas fa-arrow-alt-circle-down'"
        />
        <default-statistics-card
            :loading="loading"
            title="Total em caixa + estoque"
            :count='formatValue(overviewData.totalCaixa)'
            :icon="'fa fa-money'"
        />
      </div>
    </div>
    <div class="row mt-2 col-12">
      <default-statistics-card
          :loading="loading"
          title="Lucro bruto"
          :count='formatValue(overviewData.lucroBruto)'
          :badge="'badge bg-info'"
          :icon="'fas fa-arrow-alt-circle-up'"
      />
      <default-statistics-card
          :loading="loading"
          title="Total de despesas"
          :count='formatValue(overviewData.totalPago)'
          :badge="'badge bg-danger'"
          :icon="'fas fa-arrow-alt-circle-down'"
      />
      <default-statistics-card
          :loading="loading"
          title="Lucro"
          :count='formatValue(overviewData.lucro)'
          :badge="'badge bg-success'"
          :icon="'fa fa-dollar-sign'"
      />
    </div>

    <div class="row mt-2 col-12">
      <default-statistics-card
          :loading="loading"
          title="Contas abertas a vencer nos próximos 7 dias"
          :count='formatValue(overviewData.somaTotalProximo7diasAVencer)'
          :icon="'fa fa-money'"
      />
      <default-statistics-card
          :loading="loading"
          title="Total de contas vencidas"
          :count='formatValue(overviewData.somaTotalAtrasado)'
          :icon="'fa fa-money'"
          :badge="'badge bg-danger'"
      />
    </div>

    <div class="row mt-4">
      <!-- Coluna para as Despesas -->
      <div class="col-lg-5">
        <div class="row">
          <div v-for="despesa in despesasFormatadas" :key="despesa.nome" class="col-lg-6 mb-2">
            <div class="card border">
              <div class="card-body">
                <h6 class="card-title text-capitalize">
                  <i :class="['fas', getIconePorNome(despesa.nome)]"></i> {{ despesa.label }}
                  <i v-if="despesa.nome === 'fornecedor'"
                     v-tippy="'O valor do fornecedor NÃO é somado no total de despesas, o valor é abatido diretamente do lucro bruto'"
                     class="fa fa-exclamation-triangle small">
                  </i>
                  <i v-else-if="despesa.nome === 'distribuicao'"
                     v-tippy="'O valor de distribuição entre sócios NÃO é somado no total de despesas'"
                     class="fa fa-exclamation-triangle small">
                  </i>
                </h6>
                <p class="card-text">{{ formatValue(despesa.valor) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7">
        <default-doughnut-chart
            v-if="!loading"
            title="Bancos"
            :chart="bancosFormatadosGrafico"
            :bancos="bancosFormatados"
            @update="getOverview"
            :actions="{
          route: '',
          label: '',
          color: ''
        }"
        />
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header col-5">
        <div class="row align-items-end"> <!-- Adiciona alinhamento vertical -->
          <div class="col">
            <label>Data inicial</label>
            <input v-model="startDate" type="date" class="form-control form-control-sm">
          </div>
          <div class="col">
            <label>Data final</label>
            <input v-model="endDate" type="date" class="form-control form-control-sm">
          </div>
          <div class="col d-flex align-items-center"> <!-- Adiciona alinhamento vertical ao botão -->
            <span class="badge btn btn-info" @click.prevent="getPedidos()">Buscar</span>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table id="order-list" class="table table-flush">
          <tbody>
          <tr v-for="pedido in pedidos" :key="pedido.id">

            <!-- Conteúdo do Pedido -->
            <td v-if="pedido.financeiro.transacoes && pedido.financeiro.transacoes.length > 0"
                class="font-weight-bold text-center col-2">
              <!-- Loop Aninhado para Transações -->

              <ul class="list-group">
                <span class="d-flex flex-column align-items-start">Pedido ID: {{ pedido.id }}</span>

                <li v-for="(transacao, index) in pedido.financeiro.transacoes" :key="index"
                    class="list-group-item d-flex flex-column align-items-start">
                  <div class="row col-12">
                    <div class="col-11 d-flex flex-column justify-content-center">
                      <div class="mb-2">
                        <span class="font-weight-bold">{{ formatValue(transacao.valor) }}</span> -
                        <span
                            :class="{'text-primary': transacao.metodoPagamento === 'CARTAO', 'text-success': transacao.metodoPagamento === 'PIX'}">
                    {{ transacao.metodoPagamento }}
                  </span>
                      </div>
                      <div v-if="transacao.metodoPagamento === 'CARTAO'" class="mb-2">
                        {{ formatarTransacao(transacao.transacaoTaxa) }}
                      </div>
                      <div v-else-if="transacao.metodoPagamento === 'PIX'" class="mb-2">
                        {{ formatarTransacaoPix(transacao) }}
                      </div>
                      <div class="mb-2">
                        {{ formatDate(transacao.dataPagamento) }}
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center align-content-end">
                      <button class="btn btn-info" @click.prevent="toPedido(pedido.id)">
                        <i class="fa fa-arrow-up"></i>
                      </button>
                    </div>
                  </div>
                </li>

              </ul>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination v-model="page" :records="totalContents" :per-page="pageSize" @paginate="getPedidos($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";

import DefaultDoughnutChart from "@/views/applications/analytics/components/DefaultDoughnutChart.vue";
import DefaultStatisticsCard from "@/views/ecommerce/overview/components/DefaultStatisticsCard.vue";
import {currencyMixin} from "@/mixins/currencyMixin";
import Pagination from "v-pagination-3";


export default {
  name: "Dashbord",
  components: {
    Pagination,
    DefaultStatisticsCard,
    DefaultDoughnutChart,
  },
  data() {
    return {

      overviewData: {},
      vendas: [],
      vendasDeProdutosPorData: [],
      startDate: null,
      endDate: null,
      page: 1,
      totalContents: 0,
      pageSize: 50,
      loading: true,
      labels: [],
      data: [],
      pedidos: [],
      dataInicial: null,
      dataFinal: null,
    };
  },
  computed: {
    despesasFormatadas() {
      return Object.entries(this.overviewData.totalPagoPorTipo).map(([key, value]) => {
        const nome = key.match(/nome=(\w+)/)[1]; // Extrai o nome do tipo de despesa
        const labelMatch = key.match(/label=([^(,)]+)/);
        const label = labelMatch ? labelMatch[1] : '';
        return { nome, valor: value, label };
      });
    },
    bancosFormatadosGrafico() {
      Object.entries(this.overviewData.bancoEValorEmCaixa).forEach(([key, value]) => {
        const nomeBanco = key.match(/nome=([^,]+)/)[1]; // Extrai o nome do banco
        this.labels.push(nomeBanco);
        this.data.push(value);
      });
      return {
        labels: this.labels,
        datasets: [{label: 'Bancos', data: this.data}]
      };
    },
    bancosFormatados() {
      return Object.entries(this.overviewData.bancoEValorEmCaixa).map(([key, valor]) => {
        const id = key.match(/Banco\(id=(\d+),/)[1]; // Extrai o ID
        const nomeBanco = key.match(/nome=([^,]+)/)[1];
        const razaoSocialOrName = key.match(/razaoSocialOrName=([^,]+)/)[1];
        const cpfOrCnpj = key.match(/cpfOrCnpj=([^,]+)/)[1];
        const conta = key.match(/conta=([^,]+)/)[1];
        const agencia = key.match(/agencia=([^)]+)/)[1];

        return {
          id: id,
          nome: nomeBanco,
          valor: valor,
          razaoSocialOrName: razaoSocialOrName,
          cpfOrCnpj: cpfOrCnpj,
          conta: conta,
          agencia: agencia
        };
      });
    }
  },
  created() {
    setTooltip(this.$store.state.bootstrap);
    this.startDate = currencyMixin.methods.getFirstDayOfMonthUTC();
    this.endDate = currencyMixin.methods.getLastDayOfMonthUTC();
    this.getOverview();
    this.getPedidos();
  },
  methods: {
    async toPedido(pedidoId) {
      await this.$router.push(`/ecommerce/pedido/${pedidoId}`);
    },
    formatValue(valor) {
      return currencyMixin.methods.formattedAmount(valor)
    },
    formatDate(date) {
      return currencyMixin.methods.longToDateAndHoraBrasil(date)
    },
    formatarTransacaoPix(transacao) {
      if (!transacao?.banco) {
        return '';
      }
      return `${transacao?.banco?.nome} - ${transacao?.banco?.razaoSocialOrName} | Agência ${transacao?.banco?.agencia}, Conta: ${transacao?.banco?.conta}`;
    },
    formatarTransacao(transacao) {
      if (!transacao?.maquinaCartao) {
        return '';
      }
      return `${transacao.bandeira}, Juros: ${transacao.jurosPorParte}, Parcelas: ${transacao.parcela === 0 ? 'Débito' : transacao.parcela}, ${transacao.taxa}%, Maquina: ${transacao.maquinaCartao.nome} - Banco: ${transacao.maquinaCartao.banco.razaoSocialOrName}`;
    },
    getIconePorNome(nome) {
      const mapeamentoIcones = {
        'fornecedor': 'fa-truck',
        'funcionario': 'fa-user-tie',
        'loja': 'fa-store',
        'aluguel': 'fa-home',
        'comissao': 'fa-hand-holding-usd',
        'assistencia': 'fa-tools',
        'marketing': 'fa-bullhorn',
        'emprestimo': 'fa-handshake',
        'imposto': 'fa-file-invoice-dollar'
      };

      return mapeamentoIcones[nome] || 'fa-question-circle'; // Retorna um ícone padrão caso o nome não esteja no mapeamento
    },
    async getOverview() {
      this.loading = true
      const params = {
        dataInicial: currencyMixin.methods.converterDataParaLong(this.startDate),
        dataFinal: currencyMixin.methods.converterDataParaLong(this.endDate)
      }
      let overviewData = await axios.get(`api/financeiro/banco/overview`, {
        params: params
      });
      this.overviewData = overviewData.data;
      this.loading = false
    },
    async getPedidos(page) {
      const queryParams = {
        dataInicial: currencyMixin.methods.converterDataParaLong(this.startDate),
        dataFinal: currencyMixin.methods.converterDataParaLong(this.endDate),
        page: page == null ? 0 : page - 1,
        size: this.pageSize
      }
      let response = await axios.get(`api/pedido`, {params: queryParams});
      this.pedidos = response.data.content
      this.totalContents = response.data.totalElements
    }
  }
};
</script>
