<template>
  <Modal :show-modal="showModal" :title="title"
         :label-button="'Salvar'"
         @update="save"
         @update:showModal="updateModal($event)">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-4">
          <label>Data</label>
          <input v-model="dataPagamento" type="datetime-local" class="form-control form-control-sm">
        </div>
      </div>
      <div v-if="contas.length" class="row col-lg-12">
        <div class="col-6">
          <label>Conta origem</label>
          <Multiselect v-model="contaOrigem"
                       :options="contas"
                       :custom-label="customLabel"
                       :multiple="false">
          </Multiselect>
        </div>
        <div class="col-6">
          <label>Conta destino</label>
          <Multiselect v-model="contaDestino"
                       :options="contas"
                       :custom-label="customLabel"
                       :multiple="false">
          </Multiselect>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label>Método de pagamento</label>
          <select v-model="metodoPagamento" class="form-control">
            <option value="CARTAO">Cartão</option>
            <option value="DINHEIRO">Dinheiro</option>
            <option value="PIX">Pix</option>
          </select>
        </div>
        <div class="col-5">
          <label>Valor</label>
          <CurrencyInput
              v-model="valor"
              class="multisteps-form__input form-control"
              :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
          />
        </div>
      </div>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

import axios from "axios";
import {currencyMixin} from "@/mixins/currencyMixin";
import {toast} from "vue3-toastify";
import Multiselect from "vue-multiselect";
import CurrencyInput from "@/CurrencyInput.vue";


export default {
  name: "ModalTransferencia",
  components: {CurrencyInput, Multiselect, Modal},
  props: {
    showModal: Boolean,
    title: String,
  },
  emits: ['update:showModal', 'update'],
  data() {
    return {

      disabled: false,
      metodoPagamento: null,
      valor: 0,
      dataPagamento: null,
      contas: [],
      contaDestino: null,
      contaOrigem: null
    }
  },
  created() {
    this.getBancos();
  },
  methods: {
    async getBancos() {
      const response = await axios.get(`api/financeiro/banco`);
      this.contas = response.data;
    },
    customLabel(banco) {
      return `${banco.nome} - AG: ${banco.conta}, CC: ${banco.agencia}`;
    },
    async save() {
      const data = {
        valor: this.valor,
        metodoPagamento: this.metodoPagamento,
        data: currencyMixin.methods.converterDataAndHoraParaLong(this.dataPagamento),
        contaDestinoId: this.contaDestino.id,
        contaOrigemId: this.contaOrigem.id
      }
      try {
        await axios.post(`api/financeiro/transacao`, data);
        toast("Transação salva!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "success"
        })
      } catch (e) {
        toast("Erro ao salvar!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark",
          type: "error"
        })
      }
      await this.updateModal(false)
    },
    async updateModal(event) {
      await this.$emit('update:showModal', event)
      await this.$emit('update')
    },
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>