<template>
  <Modal :show-modal="showModal" :title="title"
         :label-button="'Salvar'"
         @update:showModal="updateModal($event)"
         @update="save()">
    <div class="container">
      <form>
        <div class="form-group row">
          <div class="col-md-12">
            <div class="mb-3">
              <label>Banco</label>
              <input v-model="nome" type="text" class="form-control">
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-5">
            <div class="mb-3">
              <label>Agência</label>
              <input v-model="agencia" type="text" class="form-control">
            </div>
          </div>
          <div class="col-md-7">
            <div class="mb-3">
              <label>Conta</label>
              <input v-model="conta" type="text" class="form-control">
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-5">
            <div class="mb-3">
              <label>Nome ou Razão Social</label>
              <input v-model="razaoSocialOrName" type="text" class="form-control">
            </div>
          </div>
          <div class="col-md-7">
            <div class="mb-3">
              <label>CPF ou CNPJ</label>
              <input v-model="cpfOrCnpj" type="text" class="form-control">
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-6">
            <label>Valor:</label>
            <CurrencyInput
                v-model="valor"
                class="form-control"
                :options="{ currency: 'BRL', autoDecimalDigits: true, hideCurrencySymbolOnFocus: false, hideGroupingSeparatorOnFocus: false, useGrouping: true }"
            />
          </div>
        </div>

      </form>
    </div>
  </Modal>

</template>

<script>
import Modal from "@/views/Modal.vue";

import axios from "axios";
import CurrencyInput from "@/CurrencyInput.vue";
import {toast} from "vue3-toastify";

export default {
  name: "ModalBanco",
  components: {CurrencyInput, Modal},
  props: {
    showModal: Boolean,
    title: String,
    banco: Object
  },
  emits: ['update:showModal', 'update'],
  data() {
    return {
      nome: null,
      agencia: null,
      conta: null,
      razaoSocialOrName: null,
      cpfOrCnpj: null,
      valor: 0,

      disabled: false,
    }
  },
  created() {
    if (this.banco) {
      this.nome = this.banco.nome;
      this.agencia = this.banco.agencia;
      this.conta = this.banco.conta;
      this.razaoSocialOrName = this.banco.razaoSocialOrName;
      this.cpfOrCnpj = this.banco.cpfOrCnpj;
      this.valor = this.banco.saldo;
    }
  },
  methods: {
    async updateModal(event) {
      await this.$emit('update:showModal', event)
      await this.$emit('update')
    },
    async save() {
      const data = {
        conta: this.conta,
        agencia: this.agencia,
        saldo: this.valor,
        nome: this.nome,
        razaoSocialOrName: this.razaoSocialOrName,
        cpfOrCnpj: this.cpfOrCnpj,
      }

      try {
        this.banco?.id
            ? await axios.put(`api/financeiro/banco/${this.banco.id}`, data)
            : await axios.post(`api/financeiro/banco`, data);

        toast("Banco salvo!", {
          autoClose: 1000,
          position: toast.POSITION.TOP_RIGHT,
          transition: "flip",
          theme: "dark"
        })
      } catch (e) {
        this.$swal({
          icon: "error",
          title: `Erro salvar: ${e}!`,
        });
      } finally {
        await this.updateModal(false)
      }

    },
  }
}
</script>