<template>
  <navbar btn-background="bg-gradient-success"/>
  <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved9.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Bem vindo!</h1>
          <p class="text-white text-lead">
            Coloque suas credencias
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="card-body">
            <form role="form">
              <div class="mb-2">
                <input
                    v-model="username"
                    class="form-control"
                    placeholder="User"
                    name="user"
                />
              </div>
              <div class="mb-2">
                <input
                    v-model="password"
                    class="form-control"
                    type="password"
                    placeholder="Password"
                />
              </div>
              <div class="text-center">
                <soft-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    full-width
                    @click.prevent="login"
                >Sign in
                </soft-button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import SoftButton from "@/components/SoftButton.vue";

import {mapMutations} from "vuex";
import axios from "axios";


export default {
  name: "SigninBasic",
  components: {
    Navbar,
    SoftButton,
  },
  data() {
    return {

      password: null,
      username: null,
      code: null,
      showModal: false
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async login() {
      const dto = {
        password: this.password,
        login: this.username,
      }
      try {
        const response = await axios.post(`api/auth/login`, dto);
        if (!response.data.token) {
          await this.$router.push({
            path: '/authentication/verification/illustration',
            query: {
              query: response.data,
            }
          });
          return
        }
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('nome', response.data.nome);
        localStorage.setItem('funcionarioId', response.data.funcionarioId);
        localStorage.setItem('role', response.data.role);
        await this.$router.push(`/ecommerce/overview/`);
      } catch (e) {
        this.$swal({
          title: "Credenciais invalidas!",
          text: `Confirme os campos de user e password ou entre em contato com o administrador`,
          icon: "error",
        });
      }
    },
    async check(codigo) {
      this.showModal = false;
      const dto = {
        password: this.password,
        login: this.username,
        code: codigo
      }
      try {
        const response = await axios.post(`api/auth/mfa`, dto);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('nome', response.data.nome);
        localStorage.setItem('funcionarioId', response.data.funcionarioId);
        localStorage.setItem('role', response.data.role);
        await this.$router.push(`/ecommerce/overview/`);
      } catch (e) {
        this.$swal({
          title: "Credenciais invalidas!",
          text: `Confirme os campos de user e password ou entre em contato com o administrador`,
          icon: "error",
        });
      }
      this.showModal = false
    }
  },
};
</script>
