<template>
  <div class="container-fluid py-4">
    <div v-if="metricas" class="card-body">
      <div v-if="role === 'admin'" class="row">
        <div class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title"><i class="fa fa-dollar-sign" aria-hidden="true"></i> Lucro hoje</h6>
              <p class="card-text">{{ formattedAmount(metricas.lucro) }}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title"><i class="fa fa-chart-line" aria-hidden="true"></i> Margem bruta</h6>
              <p class="card-text">{{ metricas.margemLucro }}%</p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title"><i class="fa fa-briefcase" aria-hidden="true"></i> Faturamento hoje</h6>
              <p class="card-text">{{ formattedAmount(metricas.totalFaturadoMes) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title badge bg-info"><i class="fa fa-folder-open" aria-hidden="true"></i> ABERTOS</h6>
              <p class="card-text">Quantidade:
                {{
                  metricas.pedidosPorStatus.ABERTO == null ? 0 : metricas.pedidosPorStatus.ABERTO.quantidade
                }}
              </p>
              <p class="card-text">
                {{
                  formattedAmount(metricas.pedidosPorStatus.ABERTO == null ? 0 : metricas.pedidosPorStatus.ABERTO.precoCustoTotal)
                }}</p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 mb-4">
          <div class="card border">
            <div class="card-body">
              <h6 class="card-title badge bg-success"><i class="fa fa-check" aria-hidden="true"></i> FINALIZADOS</h6>
              <p class="card-text">Quantidade: {{
                  metricas.pedidosPorStatus.FINALIZADO == null ? 0 : metricas.pedidosPorStatus.FINALIZADO.quantidade
                }}</p>
              <p class="card-text">{{
                  formattedAmount(metricas.pedidosPorStatus.FINALIZADO == null ? 0 : metricas.pedidosPorStatus.FINALIZADO.precoCustoTotal)
                }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="d-sm-flex justify-content-between">
          <div>
            <router-link
                :to="'/ecommerce/pedido'">
              <soft-button color="success" variant="gradient">Novo pedido</soft-button>
            </router-link>
          </div>
          <div class="col-4">
            <input v-model="search" class="form-control"
                   placeholder="Digite o modelo do aparelho OU imei"
                   @change.prevent="loadPedidosByFilters"/>
          </div>
          <div class="d-flex col-2">
            <div class="dropdown d-inline">
              <soft-button
                  id="navbarDropdownMenuLink2"
                  color="dark"
                  variant="outline"
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >Filtros
              </soft-button
              >
              <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                  aria-labelledby="navbarDropdownMenuLink2"
                  style
              >
                <li>
                  <a class="dropdown-item border-radius-md"
                     :class="{ selected: statusPedido === 'ABERTO' }"
                     @click.stop="statusPedido = 'ABERTO'"
                  >Status: Aberto</a
                  >
                </li>
                <li>
                  <a class="dropdown-item border-radius-md"
                     :class="{ selected: statusPedido === 'FINALIZADO' }"
                     @click.stop="statusPedido = 'FINALIZADO'"
                  >Status: Finalizado</a
                  >
                </li>
                <li>
                  <hr class="horizontal dark my-2"/>
                </li>
                <li class="px-2">
                  <label for="dataInicio">Data Início:</label>
                  <input v-model="startDate" type="date" class="form-control form-control-sm">
                </li>
                <li class="px-2 mt-2">
                  <label for="dataFim">Data Fim:</label>
                  <input v-model="endDate" type="date" class="form-control form-control-sm">
                </li>
                <li>
                  <hr class="horizontal dark my-2"/>
                </li>
                <li>
                  <span>Vendedor: </span>
                  <div v-for="funcionario in funcionarios" :key="funcionario.id" :value="funcionario.id">
                    <a class="dropdown-item border-radius-md"
                       :class="{ selected: vendedorId === funcionario.id }"
                       @click.stop="vendedorId = funcionario.id"
                    >{{ funcionario.name }}</a
                    >
                  </div>
                </li>
                <li>
                  <a class="dropdown-item border-radius-md"
                     :class="{ selected: statusPedido === 'FINALIZADO' }"
                     @click.stop="statusPedido = 'FINALIZADO'"
                  ></a
                  >
                </li>
                <li>
                  <hr class="horizontal dark my-2"/>
                </li>
                <li class="px-4 mt-2">
                  <button class="btn btn-sm btn-primary" @click.prevent="loadPedidos()">Filtrar</button>
                </li>
                <li>
                  <hr class="horizontal dark my-2"/>
                </li>
                <li>
                  <a
                      class="dropdown-item px-4 mt-2 border-radius-md text-danger"
                      @click.prevent="removeFilters();"
                  >Remove Filter</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="table-responsive">
        <table id="order-list" class="table table-flush">
          <tbody>
          <tr v-for="pedido in pedidos" :key="pedido.id">
            <td class="col-1">
              <div>
                <i class="fa fa-eye" @click.prevent="showModal = pedido"></i>
              </div>
              <!--     MODAL OVERVIEW           -->
              <PedidoOverviewModal v-if="showModal === pedido" :show-modal="(showModal === pedido)"
                                   :pedido="pedido"
                                   @update:show-modal="showModal = $event"></PedidoOverviewModal>
            </td>
            <td class="font-weight-bold text-center col-2">
              <router-link
                  :to="`/ecommerce/pedido/${pedido.id}`">
                <div>
                  <span>{{ pedido.vendedor.completedName }}</span>
                </div>
                <div>
                      <span
                          class="badge badge-sm rounded-pill"
                          :class='pedido.status === "ABERTO" ? "bg-info" : (pedido.status === "FINALIZADO" ? "bg-success" : "badge-danger")'
                      >{{ pedido.status }}</span
                      >
                </div>
              </router-link>
            </td>
            <td class="font-weight-bold col-2">
              <div>
                <span>Data da venda</span>
              </div>
              <div>
                <span>{{ longToDateFormatted(pedido.dataCriacao) }}</span>
              </div>
            </td>
            <td class="font-weight-bold col-2">
              <span v-if="pedido.cliente != null && nomeCompleto(pedido.cliente).length > 20"
                    v-tippy="`${nomeCompleto(pedido.cliente)}`">
                {{ labelCliente(pedido.cliente) }}
              </span>
              <span v-else>{{ labelCliente(pedido.cliente) }}</span>
            </td>
            <td class="font-weight-bold col-2">
              <div>
                <span v-tippy="`${formatValuesCompleted(pedido.itemPedido)}`">Produtos: {{ formatValues(pedido.itemPedido) }}</span>
              </div>
              <div>
                <span>Valor total: {{ formattedAmount(pedido?.financeiro?.valorTotal) }}</span>
              </div>
            </td>
            <td class="font-weight-bold col-2">
              <div v-if="role === 'admin'">
                <span>Lucro: {{ calcularLucroLiquidoPedido(pedido) }}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination v-model="page" :records="totalContents" :per-page="pageSize" @paginate="loadPedidos($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';

import Pagination from "v-pagination-3";
import PedidoOverviewModal from "@/PedidoOverviewModal.vue";
import {mapState} from "vuex";
import {currencyMixin} from "@/mixins/currencyMixin";

export default {
  name: "OrderList",
  components: {
    PedidoOverviewModal,
    Pagination,
    SoftButton,
  },
  data() {
    return {
      pedidos: {},
      funcionarios: [],
      startDate: null,
      endDate: null,
      vendedorId: null,
      statusPedido: null,

      dateSelected: 7,
      filterByMyVendas: false,
      page: 1,
      pageSize: 50,
      totalContents: 0,
      showModal: null,
      showMetricas: false,
      metricas: null,
      search: null
    };
  },
  computed: {
    ...mapState(['role', 'funcionarioId']),
  },
  mounted() {
    this.loadPedidosByFilters(7)
    this.getVendedores()
    this.fetchMetricas()
  },
  methods: {
    labelCliente(cliente) {
      if (cliente == null) {
        return 'Sem cliente';
      } else {
        const nomeCompleto = this.nomeCompleto(cliente)
        if (nomeCompleto.length > 20) {
          return nomeCompleto.substring(0, 17) + '...';
        } else {
          return nomeCompleto;
        }
      }
    },
    nomeCompleto(cliente) {
      return cliente.nome + " " + cliente.sobrenome
    },
    formatValues(itemProduto) {
      const fullString = itemProduto
          .map(item => item.produto)
          .filter(produto => produto?.modelo != null)
          .map(produto => produto.modelo)
          .join(', ');

      if (fullString.length > 23) {
        return fullString.substring(0, 20) + '...';
      }
      return fullString;
    },
    formatValuesCompleted(itemProduto) {
      return itemProduto
          .map(item => item.produto)
          .filter(produto => produto?.modelo != null)
          .map(produto => produto.modelo)
          .join(', ');
    },
    async getVendedores() {
      try {
        const response = await axios.get(`api/funcionario`);
        this.funcionarios = response.data
      } catch (error) {
        console.error("Houve um erro ao buscar os dados: ", error);
      }
    },
    longToDateFormatted(timestamp) {
     return currencyMixin.methods.longToDateBrasil(timestamp)
    },
    formattedAmount(valor) {
     return currencyMixin.methods.formattedAmount(valor)
    },
    async fetchMetricas() {
      this.loading = true
      // Obtém a data de hoje
      const today = new Date();

      // Cria uma nova data para o início do dia de hoje (meia-noite)
      const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      // Cria uma nova data para o final do dia de hoje (23:59:59)
      const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

      // Converte as datas para milissegundos
      const startOfDayMillis = startOfDay.getTime();
      const endOfDayMillis = endOfDay.getTime();
      const params = {
        dataInicial: startOfDayMillis,
        dataFinal: endOfDayMillis
      }
      const response = await axios.get(`api/overview`, {
        params: params
      });
      this.metricas = response.data
      console.log(this.metricas)
      this.loading = false
    },
    async removeFilters() {
      this.statusPedido = null
      await this.loadPedidos()
    },
    loadPedidosByFilters() {
      // let dataAtual = new Date();
      // let seteDiasAtras = new Date(dataAtual.getTime() - this.dateSelected * 24 * 60 * 60 * 1000);
      // this.startDate = seteDiasAtras;
      // this.endDate = dataAtual;

      if (this.filterByMyVendas) {
        this.vendedorId = Number(localStorage.getItem("funcionarioId"))
      }
      if (this.vendedor) {
        this.vendedorId = this.vendedor.id
      }

      this.loadPedidos();
    },
    calcularLucroLiquidoPedido(pedido) {
      let lucroTotal = 0;

      // Iterar sobre cada produto
      if (!pedido.itemPedido) {
        return this.formattedAmount(0)
      }
      pedido.itemPedido.forEach(produto => {
        // Calcular lucro líquido do produto
        const lucroProduto = produto.precoVenda - produto.precoCusto;
        lucroTotal += lucroProduto;
      });

      // Ajustar o lucro total com desconto e frete
      if (pedido.desconto) {
        lucroTotal -= pedido.desconto;
      }
      if (pedido.financeiro.frete) {
        lucroTotal -= pedido.frete;
      }

      return this.formattedAmount(lucroTotal);
    },
    async loadPedidos(page) {
      try {
        const queryParams = {
          statusPedido: this.statusPedido,
          dataInicial: currencyMixin.methods.converterDataParaLong(this.startDate),
          dataFinal: currencyMixin.methods.converterDataParaLong(this.endDate),
          vendedorId: this.vendedorId,
          search: this.search,
          page: page == null ? 0 : page - 1,
          size: this.pageSize
        }
        const response = await axios.get(`api/pedido`, {
          params: queryParams
        });
        const data = response.data;
        this.pedidos = data.content;
        this.totalContents = data.totalElements
      } catch (error) {
        console.error("Erro ao carregar PDFs:", error);
      }
    },
    getPdf(cliente) {
      if (cliente == null) {
        this.$swal({
          title: "Erro ao imprimir PDF!",
          text: `Nao existe cliente cadastrado no pedido.`,
        });
        return
      }
      axios.get(`api/pdf/pedido/${cliente.id}`, {
        responseType: 'blob'
      })
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${cliente.id}.pdf`; // Nome do arquivo a ser baixado.
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            console.error("Houve um erro ao baixar o PDF:", error);
          });
    },
  }
};
</script>

<style>
#order-list td {
  vertical-align: middle;
}

.selected {
  background-color: #f5f5f5 !important;
  font-weight: bold !important;
}
</style>