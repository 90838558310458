<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 no-border-card">
          <div>
            <table id="order-list" class="table table-flush">
              <thead class="thead-light">
              <tr>
                <th>Nome</th>
                <th>Celular</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="cliente in clientes" :key="cliente.id" class="dropdown-container" >
                <td class="font-weight-bold" @click.prevent="to(cliente.id)">
                  <span>{{ cliente.nome }} {{ cliente.sobrenome }} </span>
                </td>
                <td class="font-weight-bold">
                  <span>{{ cliente.cellphone }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: "Lista de clientes",
  data() {
    return {
      clientes: {},
      startDate: null,
      endDate: null,

      categoria: null
    };
  },
  watch: {
    '$route'(to, from) {
      if (to.params._id !== from.params._id) {
        this.categoria = this.$route.params._id

        if (this.categoria === 'cliente') {
          this.loadClientes()
        } else {
          this.loadFornecedor()
        }
      }
    }
  },
  mounted() {
    this.categoria = this.$route.params._id
    if (this.categoria === 'cliente') {
      this.loadClientes()
    } else {
      this.loadFornecedor()
    }
  },
  methods: {
    async to(id) {
      await this.$router.push(`/cadastro/${this.categoria}/${id}`);
    },
    async loadClientes() {
      try {
        const response = await axios.get(`api/cliente`)
        this.clientes = response.data;
      } catch (error) {
        console.error("Erro ao carregar clientes:", error);
      }
    },
    async loadFornecedor() {
      try {
        const response = await axios.get(`api/fornecedor`)
        this.clientes = response.data;
        console.log(this.clientes);
      } catch (error) {
        console.error("Erro ao carregar clientes:", error);
      }
    },
  }
};
</script>

<style>
#order-list td {
  vertical-align: middle;
}
</style>
